import React, { useEffect, useMemo, useState } from "react";
import { Button, Badge } from "reactstrap";
import {
  getApiData,
  putApiData,
  deleteApiData,
} from "../../../helpers/axiosHelper";
import TableContainer from "../../../components/Common/TableContainer";
import {
  toggleMode,
  toastError,
  toastSucess,
} from "../../../common/data/commonfunctions";
import BeneficiaryModal from "./BeneficiaryModal";
import {
  activate,
  deactivate,
  edit,
  view,
  approve,
} from "../../../common/data/icons";
import Swal from "sweetalert2";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Loader from "../../../components/Common/Loader";
import ConfirmationSwal from "../../../components/Common/ConfirmationSwal";
import { displayFlag, getCurrencyFlag } from "../../../common/data/currency";
import { useLatestTransactions } from "../../../common/data/latest-transaction-context";
import { beneficiaryStatus } from "../../../common/data/Beneficiary/data";

const AgentBeneficiary = ({ companyId, editMode }) => {
  const { allCurrencies } = useLatestTransactions();
  const [agentData, setAgentData] = useState([]);
  const [beneficiaryState, setBeneficiaryState] = useState({
    beneficiaries: [],
    modal: false,
    custData: null,
    address: null,
    bank: null,
    docData: [],
    mode: null,
  });
  const [isLoading, setIsLoading] = useState(false);
  const [companyBenId, setCompanyBenId] = useState(null);
  const [submitting, setSubmitting] = useState(false);

  const toggleModal = () => {
    setBeneficiaryState((prev) => ({
      ...prev,
      modal: !beneficiaryState.modal,
    }));
  };

  const handleAddBeneficiary = () => {
    setBeneficiaryState((prev) => ({
      ...prev,
      bank: null,
      custData: null,
      docData: [],
      address: null,
      mode: toggleMode.add,
    }));
    toggleModal();
  };

  const fetchData = async (companyId) => {
    try {
      const response = await getApiData(
        `api/AgentBeneficiary/GetAllCompanyBen?CompanyId=${companyId}`
      );
      const mappedResponse = response?.data?.map((item) => ({
        companyBenId: item.companyBenId,
        companyId: item.companyId,
        beneficiaryName: item.beneficiaryName,
        currencyCode: item.currencyCode,
        status: beneficiaryStatus[item.status],
        active: item.active,
        currencyFlag: getCurrencyFlag(allCurrencies, item.currencyCode),
      }));
      setAgentData(mappedResponse);
    } catch (error) {
      toastError("Failed to fetch agent data.");
    }
  };

  const getBeneficiaryDetails = async (companyBenId) => {
    setIsLoading(true);

    setBeneficiaryState((prev) => ({ ...prev, bank: companyBenId }));
    try {
      const response = await getApiData(
        `api/AgentBeneficiary/GetBen?CompanyBenId=${companyBenId}`
      );
      if (response) {
        setBeneficiaryState((prev) => ({
          ...prev,
          custData: response?.data,
          bank: response?.data,
          docData: response?.data,
        }));

        setCompanyBenId(companyBenId);
        const { addressId } = response.data;
        if (addressId) {
          const addressResponse = await getApiData(
            `api/Address/GetAddress?AddressID=${addressId}`
          );
          if (addressResponse) {
            setBeneficiaryState((prev) => ({
              ...prev,
              address: addressResponse,
            }));
          }
        }
        toggleModal();
      } else {
        toastError("No beneficiary details found.");
      }
    } catch (error) {
      toastError("Failed to fetch beneficiary details.");
    } finally {
      setIsLoading(false);
    }
  };

  const softDeleteBeneficiary = async (companyBenId, isActive) => {
    Swal.fire({
      title: `${isActive ? "Activate" : "Deactivate"} Beneficiary`,
      text: `Are you sure you want to ${
        isActive ? "activate" : "deactivate"
      } this beneficiary?`,
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: `Yes`,
      confirmButtonColor: "#d33",
    }).then(async (result) => {
      if (result.isConfirmed) {
        setIsLoading(true);

        const response = await putApiData(
          `api/AgentBeneficiary/SoftDeleteBeneficiary?CompanyBenId=${companyBenId}&isActive=${isActive}`
        );
        setIsLoading(false);
        fetchData(companyId);

        if (response?.success === true) {
          toastSucess("Beneficiary Deactivated");
        } else {
          toastError(response?.message);
        }
      }
    });
  };

  useEffect(() => {
    if (companyId) {
      fetchData(companyId);
    }
  }, [companyId]);

  const columns = useMemo(
    () => [
      {
        Header: "Beneficiary Name",
        accessor: "beneficiaryName",
        filterable: false,
        disableFilters: true,
      },
      {
        Header: "Currency",
        accessor: "currencyCode",
        filterable: false,
        disableFilters: true,
        Cell: (cellProps) => {
          const { currencyFlag } = cellProps.row.original;
          return (
            <>
              {displayFlag(currencyFlag)}
              {cellProps.value ?? ""}
            </>
          );
        },
      },

      {
        Header: "Status",
        accessor: "status",
        disableFilters: true,
        filterable: false,
        Cell: (cellProps) => {
          let badgeColor = "danger";
          if (cellProps.value === "Draft") {
            badgeColor = "warning";
          } else if (cellProps.value === "Incomplete") {
            badgeColor = "danger";
          } else if (cellProps.value === "Completed") {
            badgeColor = "success";
          }
          return (
            <Badge className={`font-size-11 badge-soft-${badgeColor}`}>
              {cellProps.value}
            </Badge>
          );
        },
      },
      {
        Header: "Actions",
        disableFilters: true,
        accessor: "view",
        Cell: (cellProps) => {
          const { active } = cellProps.row.original;

          const companyBenId = cellProps.row.original.companyBenId;
          return (
            <>
              {active === 1 && (
                <>
                  <Button
                    type="button"
                    color="primary"
                    title="View"
                    className="btn-sm btn-rounded ms-2"
                    onClick={() => {
                      getBeneficiaryDetails(companyBenId);
                      setBeneficiaryState((prev) => ({
                        ...prev,
                        mode: toggleMode.view,
                      }));
                    }}
                    aria-label="view"
                  >
                    {view()}
                  </Button>
                  {!editMode && (
                    <>
                      <Button
                        type="button"
                        color="success"
                        title="Edit"
                        className="btn-sm btn-rounded ms-2"
                        onClick={() => {
                          getBeneficiaryDetails(companyBenId);
                          setBeneficiaryState((prev) => ({
                            ...prev,
                            mode: toggleMode.edit,
                          }));
                        }}
                        aria-label="edit"
                      >
                        {edit()}
                      </Button>
                    </>
                  )}
                </>
              )}
              <Button
                type="button"
                color={active === 0 ? "success" : "danger"}
                title={active === 0 ? "Activate" : "Deactivate"}
                className="btn-sm btn-rounded ms-2"
                onClick={() => {
                  const newStatus = active === 0 ? true : false;
                  softDeleteBeneficiary(companyBenId, newStatus);
                }}
                aria-label="deactivate"
              >
                {active === 0 ? activate() : deactivate()}
              </Button>
            </>
          );
        },
      },
    ],
    []
  );

  return (
    <React.Fragment>
      {isLoading ? (
        <Loader />
      ) : (
        <>
          <Button
            type="button"
            color="primary"
            onClick={handleAddBeneficiary}
            className="btn mb-2 me-2"
          >
            + Add Beneficiary
          </Button>
          <div>
            <TableContainer
              columns={columns}
              data={agentData}
              isGlobalFilter={true}
              isAddOptions={false}
              customPageSize={10}
              isPageSelect={false}
            />
          </div>
          <BeneficiaryModal
            isOpen={beneficiaryState.modal}
            toggle={toggleModal}
            companyId={companyId}
            companyBenId={companyBenId}
            setCompanyBenId={setCompanyBenId}
            submitting={submitting}
            setBeneficiaryState={setBeneficiaryState}
            mode={beneficiaryState.mode}
            beneficiaryState={beneficiaryState}
            bank={beneficiaryState.bank}
            custData={beneficiaryState.custData}
            address={beneficiaryState.address}
            fetchData={fetchData}
          />
        </>
      )}
    </React.Fragment>
  );
};

export default AgentBeneficiary;
