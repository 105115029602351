import React, { useEffect, useState } from "react";
import {
  Button,
  Col,
  Form,
  FormGroup,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
} from "reactstrap";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import * as Yup from "yup";
import { useFormik } from "formik";
import RequiredAsterisk from "../../../components/Common/RequiredAsterisk";
import SelectStyle from "../../../common/data/SelectStyle";
import { postApiData } from "../../../helpers/axiosHelper";
import {
  toastError,
  useCurrencyList,
} from "../../../common/data/commonfunctions";
import { useLatestTransactions } from "../../../common/data/latest-transaction-context";
import ButtonSpinner from "../../../components/Common/ButtonSpinner";

const CreateCurrency = (props) => {
  const { isOpen, toggle } = props;
  const [submitting, setSubmitting] = useState(false);
  const [flagFile, setFlagFile] = useState(null);
  const { setAllCurrencies } = useLatestTransactions();
  const { getCurrencyList } = useCurrencyList();

  const validation = useFormik({
    enableReinitialize: true,
    initialValues: {
      currencyCode: "",
      currencyName: "",
      currencyFlag: "",
    },
    validationSchema: Yup.object({
      currencyCode: Yup.string().required("Please enter a Currency Code"),
      currencyName: Yup.string().required("Please enter a Currency Name"),
      currencyFlag: Yup.mixed().required("Please Upload a Flag"),
    }),

    onSubmit: async (values) => {
      setSubmitting(true);
      try {
        const requestBody = {
          currencyCode: values.currencyCode.toUpperCase(),
          currencyName: `${
            values.currencyName
          } (${values.currencyCode.toUpperCase()})`,
          currencyFlag: values.currencyFlag,
        };

        const response = await postApiData(
          `api/Currency/AddCurrency`,
          JSON.stringify(requestBody)
        );
        if (response?.success) {
          toast.success("Currency Added Successfully", {
            position: "top-right",
            autoClose: 3000,
            closeButton: false,
          });
          getCurrencyList().then(setAllCurrencies);
          toggle();
        } else {
          toast.error(response?.message || "Failed to add currency", {
            position: "top-right",
            autoClose: 3000,
            closeButton: false,
          });
        }
      } catch (error) {
        toast.error(error.message || "An error occurred", {
          position: "top-right",
          autoClose: 3000,
          closeButton: false,
        });
      } finally {
        setSubmitting(false);
      }
    },
  });

  const handleFileUpload = async (event) => {
    if (!event.target.files || event.target.files.length === 0) {
      return;
    }
    const file = event.target.files[0];
    const allowedExtensions = /(\.jpg|\.png|\.jpeg)$/i;
    if (!allowedExtensions.exec(file.name)) {
      toastError("Please upload a JPG or PNG image.");
      event.target.value = null;
      return;
    }
    const reader = new FileReader();
    reader.onloadend = () => {
      const base64String = reader.result.split(",")[1];
      setFlagFile(reader.result);
      validation.setFieldValue("currencyFlag", base64String);
    };
    reader.readAsDataURL(file);
  };

  return (
    <Modal
      isOpen={isOpen}
      role="dialog"
      autoFocus={true}
      centered={true}
      className="exampleModal"
      tabIndex="-1"
      toggle={toggle}
      onClosed={() => {
        validation.resetForm();
        setFlagFile(null);
      }}
    >
      <div className="modal-content">
        <Form
          className="add-rate"
          id="addRateForm"
          onSubmit={(e) => {
            e.preventDefault();
          }}
        >
          <ModalHeader
            toggle={() => {
              toggle();
              validation.resetForm();
              setFlagFile(null);
            }}
          >
            Create New Currency
          </ModalHeader>
          <ModalBody>
            <Row>
              <Col md="12">
                <FormGroup className="mb-3">
                  <Label htmlFor="currencyName">Currency Name</Label>
                  <RequiredAsterisk />
                  <Input
                    type="text"
                    id="currencyName"
                    placeholder="Enter Currency Name"
                    value={validation.values.currencyName}
                    onChange={validation.handleChange}
                    onBlur={validation.handleBlur}
                    styles={SelectStyle}
                    invalid={
                      validation.touched.currencyName &&
                      validation.errors.currencyName
                    }
                  />
                  {validation.touched.currencyName &&
                    validation.errors.currencyName && (
                      <span className="text-danger" style={{ fontSize: "80%" }}>
                        {validation.errors.currencyName}
                      </span>
                    )}
                </FormGroup>
              </Col>
              <Col md="12">
                <FormGroup className="mb-3">
                  <Label htmlFor="currencyCode">Currency Code</Label>
                  <RequiredAsterisk />
                  <Input
                    type="text"
                    id="currencyCode"
                    placeholder="Enter Currency Code"
                    value={validation.values.currencyCode}
                    onChange={validation.handleChange}
                    onBlur={validation.handleBlur}
                    invalid={
                      validation.touched.currencyCode &&
                      validation.errors.currencyCode
                    }
                  />
                  {validation.touched.currencyCode &&
                    validation.errors.currencyCode && (
                      <span className="text-danger" style={{ fontSize: "80%" }}>
                        {validation.errors.currencyCode}
                      </span>
                    )}
                </FormGroup>
              </Col>
              <Col md="12">
                <FormGroup className="mb-3">
                  <Label htmlFor="flag">Currency Flag</Label>
                  <RequiredAsterisk />
                  <div>
                    {flagFile && (
                      <img
                        src={flagFile}
                        alt="Flag Preview"
                        style={{ width: "100px", marginBottom: "5px" }}
                      />
                    )}
                    <Input
                      type="file"
                      accept="image/*"
                      onChange={handleFileUpload}
                    />
                  </div>
                  {validation.touched.currencyFlag &&
                    validation.errors.currencyFlag && (
                      <span className="text-danger" style={{ fontSize: "80%" }}>
                        {validation.errors.currencyFlag}
                      </span>
                    )}
                </FormGroup>
              </Col>
            </Row>
          </ModalBody>
          <ModalFooter>
            <Button
              color="success"
              type="submit"
              onClick={validation.handleSubmit}
              disabled={submitting}
            >
              {submitting ? <ButtonSpinner /> : "Submit"}
            </Button>
          </ModalFooter>
        </Form>
      </div>
    </Modal>
  );
};

export default CreateCurrency;
