import React from "react";
import styled, { keyframes } from "styled-components";

const spin = keyframes`
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
`;

const LoaderBackground = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.1);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 9999;
`;

const LoaderContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const Spinner = styled.div`
  border: 16px solid #f3f3f3;
  border-top: 16px solid ${(props) => props.spinnerColor};
  border-radius: 50%;
  width: 120px;
  height: 120px;
  animation: ${spin} 2s linear infinite;
`;
const DotsLoader = styled.div`
  display: flex;
  justify-content: space-between;
  width: 60px;

  & div {
    width: 10px;
    height: 10px;
    background-color: ${(props) => props.color};
    border-radius: 50%;
    animation: bounce 1.5s infinite ease-in-out;
  }

  & div:nth-child(2) {
    animation-delay: 0.2s;
  }

  & div:nth-child(3) {
    animation-delay: 0.4s;
  }

  @keyframes bounce {
    0%,
    100% {
      transform: translateY(0);
    }
    50% {
      transform: translateY(-10px);
    }
  }
`;
const LoaderText = styled.span`
  margin-top: 10px;
  font-size: 1.2rem;
  color: ${(props) => props.textColor};
  text-align: center;
`;

const CustomLoader = ({
  text = "Loading...",
  textColor = "blue",
  color = "blue",
  spinnerColor = "blue",
}) => {
  return (
    <LoaderBackground>
      <LoaderContainer>
        {/* <Spinner spinnerColor={spinnerColor} /> */}
        <DotsLoader color={color}>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
        </DotsLoader>
        {text && <LoaderText textColor={textColor}>{text}</LoaderText>}
      </LoaderContainer>
    </LoaderBackground>
  );
};

export default CustomLoader;
