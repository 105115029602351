import React from "react";
import {
  Button,
  Card,
  CardBody,
  Col,
  Form,
  FormFeedback,
  Input,
  Label,
  Row,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
} from "reactstrap";
import RequiredAsterisk from "../../components/Common/RequiredAsterisk";
import OperationsData from "./OperationsData";
import classnames from "classnames";
import "react-toastify/dist/ReactToastify.css";
import ButtonSpinner from "../../components/Common/ButtonSpinner";

const AddNewBranch = (props) => {
  const {
    activeTab,
    setactiveTab,
    createvalidation,
    isMainBranch,
    viewflag,
    submitting,
    handleTabChange,
    viewopData,
    toggleTab,
    passedSteps,
    onDataChange,
    setIsMainBranch,
  } = props;
  return (
    <div className="wizard clearfix">
      <div className="steps clearfix">
        <ul>
          <NavItem className={classnames({ current: activeTab === 1 })}>
            <NavLink
              className={classnames({ current: activeTab === 1 })}
              onClick={() => {
                setactiveTab(1);
              }}
              disabled={viewflag ? false : !(passedSteps || []).includes(2)}
            >
              <span className="number">1.</span> Branch Details
            </NavLink>
          </NavItem>

          <NavItem className={classnames({ current: activeTab === 2 })}>
            <NavLink
              className={classnames({ active: activeTab === 2 })}
              onClick={() => {
                setactiveTab(2);
              }}
              disabled={viewflag ? false : !(passedSteps || []).includes(2)}
            >
              <span className="number">2.</span> Operational Time
            </NavLink>
          </NavItem>
        </ul>
      </div>
      <div className="content clearfix">
        <TabContent activeTab={activeTab} className="body">
          <TabPane tabId={1}>
            <Form
              className="needs-validation"
              onSubmit={(e) => {
                e.preventDefault();
                createvalidation.handleSubmit(e);
                return false;
              }}
            >
              <Row>
                <Col lg="6">
                  <div className="mb-3">
                    <Label for="branchName">Branch Name</Label>
                    <RequiredAsterisk />
                    <Input
                      type="text"
                      className="form-control"
                      id="branchName"
                      placeholder="Enter Branch Name"
                      disabled={viewflag}
                      onChange={createvalidation.handleChange}
                      onBlur={createvalidation.handleBlur}
                      value={createvalidation.values.branchName || ""}
                      invalid={
                        createvalidation.touched.branchName &&
                        createvalidation.errors.branchName
                          ? true
                          : false
                      }
                    />
                    {createvalidation.touched.branchName &&
                    createvalidation.errors.branchName ? (
                      <FormFeedback type="invalid">
                        {createvalidation.errors.branchName}
                      </FormFeedback>
                    ) : null}
                  </div>
                </Col>
                <Col lg="6">
                  <div className="mb-3">
                    {/* <FormGroup check style={{ display: 'flex', alignItems: 'left' }}> */}
                    <Label
                      check
                      style={{ marginLeft: "10px", marginTop: "30px" }}
                    >
                      {" "}
                      {/* Move label to right */}
                      <Input
                        type="checkbox"
                        id="isMainBranch"
                        name="isMainBranch"
                        className="me-1"
                        disabled={viewflag}
                        onChange={(e) => {
                          setIsMainBranch(!isMainBranch);
                        }}
                        value={createvalidation.values.isMainBranch}
                        defaultChecked={isMainBranch}
                      />
                      Main Branch
                    </Label>
                    {/* </FormGroup> */}
                    {createvalidation.touched.isMainBranch &&
                    createvalidation.errors.isMainBranch ? (
                      <FormFeedback type="invalid">
                        {createvalidation.errors.isMainBranch}
                      </FormFeedback>
                    ) : null}
                  </div>
                </Col>
                <Col lg="6">
                  <div className="mb-3">
                    <Label for="street">Street</Label>
                    <RequiredAsterisk />
                    <Input
                      type="text"
                      className="form-control"
                      id="street"
                      placeholder="Enter street"
                      disabled={viewflag}
                      onChange={createvalidation.handleChange}
                      onBlur={createvalidation.handleBlur}
                      value={createvalidation.values.street || ""}
                      invalid={
                        createvalidation.touched.street &&
                        createvalidation.errors.street
                          ? true
                          : false
                      }
                    />
                    {createvalidation.touched.street &&
                    createvalidation.errors.street ? (
                      <FormFeedback type="invalid">
                        {createvalidation.errors.street}
                      </FormFeedback>
                    ) : null}
                  </div>
                </Col>
                <Col lg="6">
                  <div className="mb-3">
                    <Label for="city">City</Label>
                    <RequiredAsterisk />
                    <Input
                      type="text"
                      className="form-control"
                      id="city"
                      placeholder="Enter City"
                      disabled={viewflag}
                      onChange={createvalidation.handleChange}
                      onBlur={createvalidation.handleBlur}
                      value={createvalidation.values.city || ""}
                      invalid={
                        createvalidation.touched.city &&
                        createvalidation.errors.city
                          ? true
                          : false
                      }
                    />
                    {createvalidation.touched.city &&
                    createvalidation.errors.city ? (
                      <FormFeedback type="invalid">
                        {createvalidation.errors.city}
                      </FormFeedback>
                    ) : null}
                  </div>
                </Col>
                <Col lg="6">
                  <div className="mb-3">
                    <Label for="state">State</Label>
                    <RequiredAsterisk />
                    <Input
                      type="text"
                      className="form-control"
                      id="state"
                      placeholder="Enter State"
                      disabled={viewflag}
                      onChange={createvalidation.handleChange}
                      onBlur={createvalidation.handleBlur}
                      value={createvalidation.values.state || ""}
                      invalid={
                        createvalidation.touched.state &&
                        createvalidation.errors.state
                          ? true
                          : false
                      }
                    />
                    {createvalidation.touched.state &&
                    createvalidation.errors.state ? (
                      <FormFeedback type="invalid">
                        {createvalidation.errors.state}
                      </FormFeedback>
                    ) : null}
                  </div>
                </Col>
                <Col lg="6">
                  <div className="mb-3">
                    <Label for="country">Country</Label>
                    <RequiredAsterisk />
                    <Input
                      type="text"
                      className="form-control"
                      id="country"
                      placeholder="Enter Country"
                      disabled={viewflag}
                      onChange={createvalidation.handleChange}
                      onBlur={createvalidation.handleBlur}
                      value={createvalidation.values.country || ""}
                      invalid={
                        createvalidation.touched.country &&
                        createvalidation.errors.country
                          ? true
                          : false
                      }
                    />
                    {createvalidation.touched.country &&
                    createvalidation.errors.country ? (
                      <FormFeedback type="invalid">
                        {createvalidation.errors.country}
                      </FormFeedback>
                    ) : null}
                  </div>
                </Col>
                <Col lg="6">
                  <div className="mb-3">
                    <Label for="postalCode">Postal Code</Label>
                    <RequiredAsterisk />
                    <Input
                      type="text"
                      className="form-control"
                      id="postalCode"
                      disabled={viewflag}
                      placeholder="Enter Postal Code"
                      onChange={createvalidation.handleChange}
                      onBlur={createvalidation.handleBlur}
                      value={createvalidation.values.postalCode || ""}
                      invalid={
                        createvalidation.touched.postalCode &&
                        createvalidation.errors.postalCode
                          ? true
                          : false
                      }
                    />
                    {createvalidation.touched.postalCode &&
                    createvalidation.errors.postalCode ? (
                      <FormFeedback type="invalid">
                        {createvalidation.errors.postalCode}
                      </FormFeedback>
                    ) : null}
                  </div>
                </Col>
              </Row>
            </Form>
          </TabPane>
          <TabPane tabId={2}>
            <Form
              className="needs-validation"
              onSubmit={(e) => {
                e.preventDefault();
                createvalidation.handleSubmit(e);
                return false;
              }}
            />
            <div>
              <OperationsData
                onChange={createvalidation.handleChange}
                onBlur={createvalidation.handleBlur}
                value={createvalidation.values.operationalTime || ""}
                viewflag={viewflag}
                viewopData={viewopData}
                onDataChange={onDataChange}
                // ref={childRef}
              ></OperationsData>
            </div>
            {/* </Form> */}
          </TabPane>
        </TabContent>
      </div>
      <div className="actions clearfix">
        <ul>
          {/* Previous button */}
          <li className={activeTab === 1 ? "previous disabled" : "previous"}>
            <Button
              color="primary"
              onClick={() => toggleTab(activeTab - 1)}
              disabled={activeTab === 1}
              hidden={viewflag}
            >
              Previous
            </Button>
          </li>

          {/* Next/Save button (conditional rendering) */}
          {activeTab < 2 && (
            <li className="next">
              <Button
                color="primary"
                onClick={handleTabChange}
                hidden={viewflag}
              >
                Save & Next
              </Button>
            </li>
          )}
          {activeTab === 2 && (
            <li className="next">
              <Button
                color="primary"
                onClick={handleTabChange}
                hidden={viewflag}
                disabled={submitting}
              >
                {submitting ? <ButtonSpinner /> : "Save"}
              </Button>
            </li>
          )}
        </ul>
      </div>
    </div>
  );
};

export default AddNewBranch;
