import React, { useEffect, useState } from "react";
import {
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
} from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faBell,
  faBellSlash,
  faSyncAlt,
  faCheckCircle,
  faEllipsisV,
  faRotate,
} from "@fortawesome/free-solid-svg-icons";
import { getApiData, postApiData } from "./../../../helpers/axiosHelper";

import { NotificationType } from "../../../common/data/Notification/NotificationTypes";
import { formatDateToUserTimeZone } from "../../../common/data/date";

const getNotificationTitle = (id) =>
  ({
    1: "Transaction",
    2: "Profile",
  }[id] || `Title ${id}`);

const Notifications = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [notification, setNotification] = useState({
    bellMenu: false,
    activeTab: 1,
    totalCount: 0,
    actionCount: 0,
    infoCount: 0,
    actionNotifications: { today: [], yesterday: [], older: [] },
    infoNotifications: { today: [], yesterday: [], older: [] },
  });
  const [dropdownOpen, setDropdownOpen] = useState(false);

  const toggleDropdown = () => setDropdownOpen(!dropdownOpen);
  const navTabs = [
    {
      name: `Actions`,
      count: notification.actionCount,
    },
    { name: `Info`, count: notification.infoCount },
  ];

  const toggleBellMenu = () =>
    setNotification((prev) => ({ ...prev, bellMenu: !prev.bellMenu }));

  const toggleTab = (tab) =>
    setNotification((prev) => ({ ...prev, activeTab: tab }));

  const categorizeNotifications = (notifications) => {
    const today = new Date();
    const startOfToday = new Date(today.setHours(0, 0, 0, 0));
    const startOfYesterday = new Date(today.setDate(today.getDate() - 1));

    return notifications?.reduce(
      (acc, notification) => {
        const date = new Date(notification.date);
        if (date >= startOfToday) acc.today.push(notification);
        else if (date >= startOfYesterday) acc.yesterday.push(notification);
        else acc.older.push(notification);
        return acc;
      },
      { today: [], yesterday: [], older: [] }
    );
  };

  const getNotifications = async () => {
    const resp = await getApiData("api/SystemNotification/GetAll");

    if (resp?.length) {
      const notifications = resp?.map((x) => ({
        title: getNotificationTitle(x.title),
        desc: x.description,
        value: x.notificationID,
        date: x.createdAt,
        type: x.type,
      }));

      const actionNotifications = notifications?.filter(
        (x) => x.type === NotificationType.Action
      );
      const infoNotifications = notifications?.filter(
        (x) => x.type === NotificationType.Info
      );

      setNotification((prev) => ({
        ...prev,
        totalCount: notifications?.length,
        actionCount: actionNotifications?.length,
        infoCount: infoNotifications?.length,
        actionNotifications: categorizeNotifications(actionNotifications),
        infoNotifications: categorizeNotifications(infoNotifications),
      }));
    }
  };

  useEffect(() => {
    getNotifications();
  }, []);

  const handleMarkAllAsRead = async () => {
    try {
      const allUnreadNotifications = [
        ...notification.actionNotifications.today,
        ...notification.actionNotifications.yesterday,
        ...notification.actionNotifications.older,
        ...notification.infoNotifications.today,
        ...notification.infoNotifications.yesterday,
        ...notification.infoNotifications.older,
      ];

      for (let notif of allUnreadNotifications) {
        await handleNotificationClick(notif.value, notif.type);
      }
    } catch (error) {
      console.error("Error marking all notifications as read:", error);
    }
  };

  const handleNotificationClick = async (notificationID, type) => {
    try {
      await postApiData(
        `api/SystemNotification/MarkAsRead?NotificationId=${notificationID}`
      );

      setNotification((prev) => {
        const updatedActionNotifications = removeNotification(
          prev.actionNotifications,
          notificationID
        );
        const updatedInfoNotifications = removeNotification(
          prev.infoNotifications,
          notificationID
        );

        return {
          ...prev,
          actionNotifications: updatedActionNotifications,
          infoNotifications: updatedInfoNotifications,
          actionCount:
            type === NotificationType.Action
              ? prev.actionCount - 1
              : prev.actionCount,
          infoCount:
            type === NotificationType.Info
              ? prev.infoCount - 1
              : prev.infoCount,
        };
      });
    } catch (error) {
      console.error("Error marking notification as read:", error);
    }
  };

  const removeNotification = (notifications, notificationID) => {
    return Object.fromEntries(
      Object.entries(notifications).map(([key, items]) => [
        key,
        items?.filter((item) => item.value !== notificationID),
      ])
    );
  };

  const renderNotificationItems = (items) =>
    items.map((x) => (
      <DropdownItem
        key={x.value}
        className="d-flex align-items-center justify-content-between"
      >
        <div>
          <strong>{x.title}</strong>
          <small className="ms-2 text-muted">
            {formatDateToUserTimeZone(x.date)}
          </small>
          <p className="mb-0 mt-1">{x.desc}</p>
        </div>
        <div className="position-relative">
          <FontAwesomeIcon
            icon={faCheckCircle}
            size="sm"
            color="#28a745"
            className="ms-2"
            onClick={(e) => {
              e.stopPropagation();
              handleNotificationClick(x.value, x.type);
            }}
            title="Mark as read"
          />
        </div>
      </DropdownItem>
    ));

  const renderCategory = (category, items) => {
    if (items?.length === 0) return null;
    return (
      <div key={category}>
        <h5 className="mt-3 d-flex align-items-center">
          {category.charAt(0).toUpperCase() + category.slice(1)}
        </h5>
        {renderNotificationItems(items)}
      </div>
    );
  };

  const renderTabContent = (notifications) =>
    Object.entries(notifications).map(([category, items]) =>
      renderCategory(category, items)
    );

  const renderEmpty = () => (
    <div className="d-flex flex-column gap-3 align-items-center justify-content-center">
      <FontAwesomeIcon
        icon={faBellSlash}
        size="2xl"
        className="mt-4"
        color="#FF5252"
      />
      <p>No unread notifications</p>
    </div>
  );

  const handleRefresh = async () => {
    setIsLoading(true);
    await getNotifications();
    setIsLoading(false);
  };
  const { bellMenu, activeTab, actionNotifications, infoNotifications } =
    notification;

  return (
    <Dropdown isOpen={bellMenu} toggle={toggleBellMenu} className="ms-3">
      <DropdownToggle
        tag="button"
        className="btn header-item position-relative"
      >
        <FontAwesomeIcon icon={faBell} size="lg" />
        {notification.actionCount + notification.infoCount > 0 && (
          <span className="badge bg-danger rounded-circle position-absolute top-0 start-100 translate-middle mt-4 ms-n2">
            {notification.actionCount + notification.infoCount}
          </span>
        )}
      </DropdownToggle>

      <DropdownMenu
        className="dropdown-menu-end p-3"
        style={{ minWidth: "500px", maxHeight: "700px", overflowY: "scroll" }}
      >
        <div className="d-flex justify-content-between align-items-center mb-3">
          <h5>Notifications</h5>
          <div className="ms-auto d-flex align-items-center">
            {isLoading ? (
              <FontAwesomeIcon
                icon={faRotate}
                className="cursor-pointer me-3"
                spin
              />
            ) : (
              <FontAwesomeIcon
                icon={faSyncAlt}
                className="cursor-pointer me-3"
                onClick={handleRefresh}
              />
            )}
            <Dropdown isOpen={dropdownOpen} toggle={toggleDropdown}>
              <DropdownToggle tag="button" className="btn">
                <FontAwesomeIcon icon={faEllipsisV} size="lg" />
              </DropdownToggle>
              <DropdownMenu className="dropdown-menu-end">
                <DropdownItem onClick={handleMarkAllAsRead}>
                  <FontAwesomeIcon
                    icon={faCheckCircle}
                    size="sm"
                    className="me-2"
                  />
                  Mark All as Read
                </DropdownItem>
              </DropdownMenu>
            </Dropdown>
          </div>
        </div>
        <Nav tabs className="mb-3">
          {navTabs.map((tab, idx) => (
            <NavItem key={idx}>
              <NavLink
                className={`d-flex align-items-center ${
                  activeTab === idx + 1 ? "active" : ""
                }`}
                style={{
                  backgroundColor:
                    activeTab === idx + 1 ? "#eff2f7" : "transparent",
                }}
                onClick={() => toggleTab(idx + 1)}
              >
                {tab.name}
                {tab.count > 0 && (
                  <span className="badge bg-danger ms-2">{tab.count}</span>
                )}
              </NavLink>
            </NavItem>
          ))}
        </Nav>

        <TabContent activeTab={activeTab}>
          <TabPane tabId={1}>
            {Object.values(actionNotifications).some((arr) => arr?.length)
              ? renderTabContent(actionNotifications)
              : renderEmpty()}
          </TabPane>
          <TabPane tabId={2}>
            {Object.values(infoNotifications).some((arr) => arr?.length)
              ? renderTabContent(infoNotifications)
              : renderEmpty()}
          </TabPane>
        </TabContent>
      </DropdownMenu>
    </Dropdown>
  );
};

export default Notifications;
