import React from "react";
import {
  Container,
  Row,
  Col,
} from "reactstrap";

const Section = () => {

  return (
    <React.Fragment>
      <section className="section hero-section bg-ico-hero" id="home">
        <div className="bg-overlay bg-primary"></div>
        <Container>
          <Row className="align-items-center">
            <Col lg={5}>
              <div className="text-white-50 mx-3">
                <h1 className="text-white fw-semibold mb-3 hero-title">Welcome to Crescent Exchange</h1>
                <p className="font-size-14">Your gateway to secure, efficient, and cost-effective solutions for individuals and businesses alike. With our user-friendly platform, customers can easily exchange currencies, transfer funds internationally, and stay updated with the latest exchange rates.</p>

                <div className="d-flex flex-wrap gap-2 mt-4">
                  <a href="#features" className="btn btn-success">Explore Services</a>
                </div>
              </div>
            </Col>
            <Col lg={5} md={8} sm={10} className="ms-lg-auto">
              {/* <Card className="overflow-hidden mb-0 mt-5 mt-lg-0">
                <CardHeader className="text-center">
                  <h5 className="mb-0">Cresent Exchange</h5>
                </CardHeader>
                <CardBody>
                    <div className="d-flex">
                        <div>
                            <h4 className="card-title mb-3 text-primary">Welcome Back !</h4>
                            <p className="text-muted">We make your currency conversion easy and convenient! Get the best rates here!</p>
                        </div>
                    </div>

                    <div className="mt-3">
                      <Row>
                        <Col md={6}>
                          <div className="mb-3">
                              <Label htmlFor="formrow-email-Input">From Currency</Label>
                              <Select
                                value={selectedMulti1}
                                onChange={() => {
                                  handleMulti1();
                                }}
                                options={optionGroup1}
                                className="select2-selection"
                                isLoading={true}
                              />
                              
                          </div>
                        </Col>
                        <Col md={6}>
                          <div className="mb-3">
                            <Label htmlFor="formrow-password-Input">To Currency</Label>
                            <Select
                              value={selectedMulti2}
                              onChange={() => {
                                handleMulti2();
                              }}
                              options={optionGroup2}
                              className="select2-selection"
                              isLoading={true}
                            />
                          </div>
                        </Col>
                      </Row>
                    </div>

                    <div className="mt-3">
                        <Row>
                          <Col md={6}>
                            <div className="mb-3">
                              <InputGroup>
                                <div className="input-group-text">USD</div>
                                <input type="text" className="form-control" id="inlineFormInputGroupUsername" placeholder="Amount" />
                              </InputGroup>
                            </div>
                          </Col>
                          <Col md={6}>
                          <div className="mb-3">
                              <InputGroup>
                                <div className="input-group-text">EUR</div>
                                <input type="text" className="form-control" id="inlineFormInputGroupUsername" placeholder="Amount" />
                              </InputGroup>
                            </div>
                          </Col>
                        </Row>
                    </div>

                    <div className="mt-3">
                        <Row>
                          <Col md={6}>
                            <div className="mt-3">
                                <span>1 AUD = 0.5765 EUR</span>
                            </div>
                          </Col>
                          <Col md={6}>
                          <div className="mb-3">
                              <button type="button" className="btn btn-success w-md" onClick={() => navigate(`/login`)}>Book Now</button>
                            </div>
                          </Col>
                          <p className="fst-italic mt-3">*Rates are subject to change throughout the day. In-store rates vary compared to online.</p>
                        </Row>
                    </div>
                </CardBody>
              </Card> */}
            </Col>
          </Row>
        </Container>
      </section>
    </React.Fragment>
  );
};

export default Section;
