export const NotificationType = {
  Action: 1,
  Info: 2,
};

export const mapNotificationType = {
  1: "Action",
  2: "Info",
};

export const mapNotificationTitle = {
  1: "Transaction",
  2: "Profile",
};
