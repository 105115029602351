// ManageAgent.js
import React, { useState } from "react";
import { Row, Col, Container, Nav, NavItem, NavLink } from "reactstrap";
import AgentDetails from "./AgentDetails";
import AgentBeneficiary from "./CompanyBeneficiary/AgentBeneficiary";

const ManageAgent = ({ companyId }) => {
  const [activeTab, setActiveTab] = useState(1);

  const toggle = (tab) => {
    if (activeTab !== tab) setActiveTab(tab);
  };

  const navTabs = [
    { label: "Agents", value: 1 },
    { label: "Beneficiaries", value: 2 },
  ];

  return (
    <div>
      <Nav fill tabs className="tabs-no-margin mb-4 " style={{ width: "50%" }}>
        {navTabs.map((x) => (
          <NavItem key={x.value}>
            <NavLink
              className={activeTab === x.value ? "active" : ""}
              style={
                activeTab === x.value
                  ? { backgroundColor: "#556ee6", color: "white" }
                  : { borderStyle: "solid", borderColor: "gray" }
              }
              onClick={() => toggle(x.value)}
            >
              {x.label}
            </NavLink>
          </NavItem>
        ))}
      </Nav>
      <Row>
        <Col>
          {activeTab === 1 && <AgentDetails companyId={companyId} />}
          {activeTab === 2 && (
            <AgentBeneficiary
              companyId={companyId}
              // companyBenId={companyBenId}
            />
          )}
        </Col>
      </Row>
    </div>
  );
};

export default ManageAgent;
