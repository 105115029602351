import React, { useEffect, useState } from "react";
import {
  Button,
  Col,
  Form,
  FormFeedback,
  FormGroup,
  Input,
  Label,
  Row,
} from "reactstrap";
import RequiredAsterisk from "../../../../components/Common/RequiredAsterisk";
import { useFormik } from "formik";
import ReactSelect from "react-select";
import * as Yup from "yup";
import {
  getApiData,
  postApiData,
  putApiData,
} from "../../../../helpers/axiosHelper";
import SelectStyle from "../../../../common/data/SelectStyle";
import {
  toastInfo,
  toastSucess,
} from "../../../../common/data/commonfunctions";
import { ToastContainer } from "react-toastify";

const AddressTab = (props) => {
  const {
    disabled,
    addressData,
    setState,
    activeTab,
    custBenId,
    getBeneficiaryByCustomerId,
    customerId,
  } = props;

  const [addressState, setAddressState] = useState({
    nationalityOptions: [],
  });
  const addressValidation = useFormik({
    enableReinitialize: true,

    initialValues: {
      street: addressData?.street || "",
      city: addressData?.city || "",
      state: addressData?.state || "",
      country: addressData?.country || "",
      postalcode: addressData?.postalCode || "",
      nationality:
        addressState.nationalityOptions.find(
          (opt) => opt.value === parseInt(addressData?.nationality)
        ) || null,
    },

    validationSchema: Yup.object({
      street: Yup.string().required("Please Enter Street"),
      city: Yup.string()
        .matches(/^[A-Za-z\s]+$/, "City should contain only letters")
        .required("Please Enter City"),
      state: Yup.string()
        .matches(/^[A-Za-z\s]+$/, "State should contain only letters")
        .required("Please Enter State"),
      country: Yup.string()
        .matches(/^[A-Za-z\s]+$/, "Country should contain only letters")
        .required("Please Enter Country"),
      postalcode: Yup.string()
        .matches(/^[0-9]*$/, "Please enter only numbers")
        .required("Please Enter Postal Code"),
      nationality: Yup.object().shape({
        label: Yup.string().required("Please Select Nationality"),
        value: Yup.string().required("Please Select Nationality"),
      }),
    }),

    onSubmit: async (values) => {
      const nationalityValue = values.nationality && values.nationality.value;
      const combinedValues = {
        ...values,
        addressId: addressData?.addressId,
        nationality: nationalityValue,
      };
      if (addressData?.addressId) {
        const hasChanges = Object.keys(values).some(
          (key) => values[key] !== addressValidation.initialValues[key]
        );
        if (hasChanges) {
          const updatedResponse = await putApiData(
            `api/Address/UpdateAddress?AddressID=${addressData?.addressId}`,
            combinedValues
          );
          if (updatedResponse?.success) {
            toastSucess("Address Updated Successfully");
            setState((prev) => ({ ...prev, activeTab: activeTab + 1 }));
            getBeneficiaryByCustomerId(customerId);
          }
        } else {
          toastInfo("No Changes to Update");
        }
      } else {
        const combinedValues = {
          ...values,
          nationality: `${nationalityValue}`,
        };
        const response = await postApiData(
          `api/Address/CreateAddressForCustBen?CustBenId=${custBenId}`,
          JSON.stringify(combinedValues)
        );
        if (response?.success) {
          toastSucess("Address Added Successfully");
          setState((prev) => ({ ...prev, activeTab: activeTab + 1 }));
          getBeneficiaryByCustomerId(customerId);
        }
      }
    },
  });
  const mapNationality = (nationality) => {
    if (!nationality) return [];
    return nationality?.map((e) => ({
      label: e.nation,
      value: e.id,
    }));
  };

  const fetchNationality = async () => {
    const response = await getApiData("api/Address/GetAllNationality");
    setAddressState((prev) => ({
      ...prev,
      nationalityOptions: mapNationality(response),
    }));
  };

  useEffect(() => {
    fetchNationality();
  }, []);
  return (
    <React.Fragment>
      <Form
        className="needs-validation"
        onSubmit={(e) => {
          e.preventDefault();
          addressValidation.handleSubmit();
          return false;
        }}
      >
        <Row>
          <Col lg="6">
            <div className="mb-3">
              <Label for="street">Street</Label>
              <RequiredAsterisk />
              <Input
                type="text"
                className="form-control"
                id="street"
                placeholder="Enter street"
                value={addressValidation.values.street}
                disabled={disabled}
                onChange={addressValidation.handleChange}
                onBlur={addressValidation.handleBlur}
                invalid={
                  addressValidation.touched.street &&
                  addressValidation.errors.street
                    ? true
                    : false
                }
              />
              {addressValidation.touched.street &&
              addressValidation.errors.street ? (
                <FormFeedback type="invalid">
                  {addressValidation.errors.street}
                </FormFeedback>
              ) : null}
            </div>
          </Col>
          <Col lg="6">
            <div className="mb-3">
              <Label for="city">City</Label>
              <RequiredAsterisk />
              <Input
                type="text"
                id="city"
                placeholder="Enter City"
                value={addressValidation.values.city}
                disabled={disabled}
                onChange={addressValidation.handleChange}
                onBlur={addressValidation.handleBlur}
                invalid={
                  addressValidation.touched.city &&
                  addressValidation.errors.city
                    ? true
                    : false
                }
              />
              {addressValidation.touched.city &&
              addressValidation.errors.city ? (
                <FormFeedback type="invalid">
                  {addressValidation.errors.city}
                </FormFeedback>
              ) : null}
            </div>
          </Col>
          <Col lg="6">
            <div className="mb-3">
              <Label for="state">State</Label>
              <RequiredAsterisk />
              <Input
                type="text"
                id="state"
                placeholder="Enter State"
                value={addressValidation.values.state}
                disabled={disabled}
                onChange={addressValidation.handleChange}
                onBlur={addressValidation.handleBlur}
                invalid={
                  addressValidation.touched.state &&
                  addressValidation.errors.state
                    ? true
                    : false
                }
              />
              {addressValidation.touched.state &&
              addressValidation.errors.state ? (
                <FormFeedback type="invalid">
                  {addressValidation.errors.state}
                </FormFeedback>
              ) : null}
            </div>
          </Col>
          <Col lg="6">
            <div className="mb-3">
              <Label for="country">Country</Label>
              <RequiredAsterisk />
              <Input
                type="text"
                className="form-control"
                id="country"
                placeholder="Enter Country"
                value={addressValidation.values.country}
                disabled={disabled}
                onChange={addressValidation.handleChange}
                onBlur={addressValidation.handleBlur}
                invalid={
                  addressValidation.touched.country &&
                  addressValidation.errors.country
                    ? true
                    : false
                }
              />
              {addressValidation.touched.country &&
              addressValidation.errors.country ? (
                <FormFeedback type="invalid">
                  {addressValidation.errors.country}
                </FormFeedback>
              ) : null}
            </div>
          </Col>
          <Col lg="6">
            <div className="mb-3">
              <Label for="postalcode">Postal Code</Label>
              <RequiredAsterisk />
              <Input
                type="text"
                id="postalcode"
                placeholder="Enter Postal Code"
                value={addressValidation.values.postalcode}
                disabled={disabled}
                onChange={addressValidation.handleChange}
                onBlur={addressValidation.handleBlur}
                invalid={
                  addressValidation.touched.postalcode &&
                  addressValidation.errors.postalcode
                    ? true
                    : false
                }
              />
              {addressValidation.touched.postalcode &&
              addressValidation.errors.postalcode ? (
                <FormFeedback type="invalid">
                  {addressValidation.errors.postalcode}
                </FormFeedback>
              ) : null}
            </div>
          </Col>
          <Col lg="6">
            <FormGroup className="mb-3">
              <Label for="nationality">Nationality</Label>
              <RequiredAsterisk />
              <ReactSelect
                name="nationality"
                placeholder="Select Nationality"
                id="nationality"
                styles={SelectStyle}
                value={addressValidation.values.nationality}
                isDisabled={disabled}
                options={addressState.nationalityOptions}
                onChange={(selectedOption) =>
                  addressValidation.setFieldValue("nationality", selectedOption)
                }
                onBlur={addressValidation.handleBlur}
                invalid={
                  addressValidation.touched.nationality &&
                  addressValidation.errors.nationality
                    ? true
                    : false
                }
              />
              {addressValidation.errors.nationality &&
              addressValidation.touched.nationality &&
              addressValidation.values.nationality === null ? (
                <span className="text-danger" style={{ "font-size": "80%" }}>
                  Please Select a Nationality
                </span>
              ) : null}
            </FormGroup>
          </Col>
        </Row>
        {disabled !== true && (
          <Button
            color="primary"
            onClick={addressValidation.handleSubmit}
            disabled={addressValidation.isSubmitting}
          >
            {addressData === null ? "Add Address" : "Update"}
          </Button>
        )}
      </Form>
      {/* <ToastContainer limit={1} /> */}
    </React.Fragment>
  );
};

export default React.memo(AddressTab);
