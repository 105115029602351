import React, { useEffect, useState } from "react";
import {
  Modal,
  ModalBody,
  ModalHeader,
  FormGroup,
  Label,
  Form,
  Row,
  Col,
  Input,
  ModalFooter,
  Button,
  FormFeedback,
} from "reactstrap";
import ReactSelect from "react-select";
import { useFormik } from "formik";
import RequiredAsterisk from "../../components/Common/RequiredAsterisk";
import * as Yup from "yup";
import SelectStyle from "../../common/data/SelectStyle";
import { useCurrency } from "../../common/data/commonfunctions";
import { postApiData } from "../../helpers/axiosHelper";
import { ToastContainer, toast } from "react-toastify";
import { useLatestTransactions } from "../../common/data/latest-transaction-context";
import ButtonSpinner from "../../components/Common/ButtonSpinner";

const AddStock = (props) => {
  const { isOpen, toggle, paymentType, stockDetails } = props;
  const { currency, getCurrency } = useCurrency();
  const [state, setState] = useState({
    submitting: false,
    selectedStock: null,
  });
  const { branchId } = useLatestTransactions();

  const validation = useFormik({
    enableReinitialize: true,

    initialValues: {
      branchId: branchId,
      currencyCode: null,
      amount: "",
      paymentType: paymentType,
      comment: "",
      movingAverage: "",
    },

    validationSchema: Yup.object({
      branchId: Yup.string().required("Please Select a Branch"),
      currencyCode: Yup.object().shape({
        label: Yup.string().required("Please Select a Currency"),
        value: Yup.string().required("Please Select a Currency"),
      }),

      amount: Yup.string()
        .matches(/^[0-9]*$/, "Please enter only numbers")
        .test(
          "is-non-zero",
          "Amount must be greater than 0",
          (value) => parseFloat(value) > 0
        )
        .test(
          "is-within-limit",
          `You can't withdraw more than ${state.selectedStock?.availableAmount}`,
          (value) =>
            paymentType === 2
              ? parseFloat(value) <= state.selectedStock?.availableAmount
              : true
        )
        .required("Please Enter the Amount"),
      movingAverage: Yup.string()
        .test(
          "validate-moving-average",
          "Please Enter the Moving Average",
          (value) => (state.selectedStock ? true : value > 0 ? true : false)
        )
        .nullable(),
      comment: Yup.string().required("Please Enter Comment"),
    }),

    onSubmit: async (values, { resetForm }) => {
      const currencyCodeValue =
        values.currencyCode && values.currencyCode?.label;
      const combinedValues = {
        ...values,
        currencyCode: currencyCodeValue,
        movingAverage: currencyCodeValue === "SGD" ? 1 : values.movingAverage,
      };

      try {
        setState((prevState) => ({ ...prevState, submitting: true }));
        const response = await postApiData(
          "api/Stock/CreateStock",
          JSON.stringify(combinedValues)
        );
        if (response?.success === true) {
          toast.success(
            `Amount ${
              paymentType === 1 ? "Deposited" : "Withdrawn"
            } Successfully`,
            {
              position: "top-right",
              autoClose: 3000,
            }
          );
          resetForm();
          toggle();
        } else if (response?.success === false) {
          toast.error(`${response?.message}`, {
            position: "top-right",
            autoClose: 3000,
          });
          setState((prevState) => ({ ...prevState, submitting: false }));
        }
      } catch (error) {
        console.error(error);
      } finally {
        setState((prevState) => ({ ...prevState, submitting: false }));
      }
    },
  });

  useEffect(() => {
    const match = stockDetails?.find(
      (stock) =>
        stock.branchId === validation.values.branchId &&
        stock.currencyCode === validation.values.currencyCode?.label
    );
    setState((prevState) => ({
      ...prevState,
      selectedStock: match === undefined ? null : match,
    }));
  }, [validation.values.branchId, validation.values.currencyCode]);

  useEffect(() => {
    getCurrency();
  }, []);

  return (
    <React.Fragment>
      <ToastContainer closeButton={false} limit={1} />
      <Modal
        isOpen={isOpen}
        role="dialog"
        toggle={toggle}
        centered={true}
        autoFocus={true}
        className="exampleModal"
        tabIndex="-1"
        onClosed={() => {
          validation.resetForm();
        }}
      >
        <ModalHeader toggle={toggle}>
          {paymentType === 1 ? "Deposit Stock" : "Withdraw Stock"}
        </ModalHeader>
        <ModalBody>
          <Form
            className="needs-validation"
            onSubmit={(e) => {
              e.preventDefault();
              validation.handleSubmit();
            }}
          >
            <Row>
              <Col md="6">
                <FormGroup>
                  <Label htmlFor="currencyCode">Currency</Label>
                  <RequiredAsterisk />
                  <ReactSelect
                    placeholder="Select a Currency"
                    id="currencyCode"
                    styles={SelectStyle}
                    options={currency || []}
                    value={
                      validation.values.currencyCode
                        ? validation.values.currencyCode
                        : null
                    }
                    onBlur={validation.handleBlur}
                    onChange={(selectedOption) => {
                      validation.setFieldValue("currencyCode", selectedOption);
                    }}
                    invalid={
                      validation.touched.currencyCode &&
                      validation.errors.currencyCode
                        ? true
                        : false
                    }
                  />
                  {validation.errors.currencyCode &&
                  validation.touched.currencyCode ? (
                    <span className="text-danger" style={{ fontSize: "80%" }}>
                      {validation.errors.currencyCode}
                    </span>
                  ) : null}
                </FormGroup>
              </Col>
              <Col md="6">
                <FormGroup>
                  <Label htmlFor="movingAverage">Moving Average</Label>
                  {state.selectedStock === null && paymentType === 1 && (
                    <RequiredAsterisk />
                  )}
                  <Input
                    type="number"
                    id="movingAverage"
                    placeholder="Enter Moving Average"
                    value={validation.values.movingAverage}
                    onBlur={validation.handleBlur}
                    onChange={validation.handleChange}
                    invalid={
                      validation.touched.movingAverage &&
                      validation.errors.movingAverage
                    }
                  />
                  {validation.touched.movingAverage &&
                  validation.errors.movingAverage ? (
                    <FormFeedback type="invalid">
                      {validation.errors.movingAverage}
                    </FormFeedback>
                  ) : null}
                </FormGroup>
              </Col>

              <Col md="12">
                <FormGroup>
                  <Label htmlFor="amount">Amount</Label>
                  <RequiredAsterisk />
                  <Input
                    type="text"
                    placeholder="Enter Amount"
                    id="amount"
                    value={validation.values.amount}
                    onChange={validation.handleChange}
                    onBlur={validation.handleBlur}
                    invalid={
                      validation.touched.amount && validation.errors.amount
                    }
                  />
                  {validation.touched.amount && validation.errors.amount ? (
                    <FormFeedback type="invalid">
                      {validation.errors.amount}
                    </FormFeedback>
                  ) : null}
                </FormGroup>
              </Col>
              <Col md="12">
                <FormGroup>
                  <Label htmlFor="comment">Comment</Label>
                  <RequiredAsterisk />
                  <Input
                    type="textarea"
                    placeholder="Enter Comments..."
                    id="comment"
                    value={validation.values.comment}
                    onChange={validation.handleChange}
                    onBlur={validation.handleBlur}
                    invalid={
                      validation.touched.comment && validation.errors.comment
                    }
                  />
                  {validation.touched.comment && validation.errors.comment ? (
                    <FormFeedback type="invalid">
                      {validation.errors.comment}
                    </FormFeedback>
                  ) : null}
                </FormGroup>
              </Col>
            </Row>
          </Form>
        </ModalBody>
        <ModalFooter>
          <Button
            color="primary"
            onClick={validation.handleSubmit}
            disabled={state.submitting}
          >
            {state.submitting ? <ButtonSpinner /> : "Submit"}
          </Button>
        </ModalFooter>
      </Modal>
    </React.Fragment>
  );
};

export default AddStock;
