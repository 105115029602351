import React, { useState } from "react";
import { Button, FormFeedback, Input } from "reactstrap";
import { postApiData } from "../../helpers/axiosHelper";
import { authUser, toastSucess } from "../../common/data/commonfunctions";
import { useFormik } from "formik";
import * as Yup from "yup";
import { FormGroup } from "reactstrap";
import { ToastContainer } from "react-toastify";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUser } from "@fortawesome/free-solid-svg-icons";
import { formatDateToUserTimeZone } from "../../common/data/date";

const ViewProfileComments = (props) => {
  const { commentData, editMode, uId, getComments } = props;
  const [state, setState] = useState({
    submitting: false,
  });

  const commentValidation = useFormik({
    enableReinitialize: true,

    initialValues: {
      comment: "",
    },

    validationSchema: Yup.object({
      comment: Yup.string().required("Please Enter Your Comments"),
    }),

    onSubmit: async (values, { resetForm }) => {
      setState((prevState) => ({ ...prevState, submitting: true }));
      const combinedValues = { ...values, profileUid: uId };
      const response = await postApiData("api/ProfileComments", combinedValues);
      setState((prevState) => ({ ...prevState, submitting: false }));
      if (response?.success === true) {
        toastSucess("Comment Sent Successfully");
        resetForm();
        getComments();
      }
    },
  });

  return (
    <React.Fragment>
      <ToastContainer limit={1} closeButton={false} />
      <div className="bg-light mb-4 p-3">
        <div className="d-flex align-items-center mb-3">
          {editMode ? null : (
            <>
              <FormGroup className="me-2 w-100">
                <Input
                  type="textarea"
                  className="me-2"
                  id="comment"
                  name="comment"
                  value={commentValidation.values.comment}
                  onChange={commentValidation.handleChange}
                  onBlur={commentValidation.handleBlur}
                  placeholder="Write your comments here..."
                  invalid={
                    commentValidation.touched.comment &&
                    commentValidation.errors.comment
                      ? true
                      : false
                  }
                />
                {commentValidation.touched.comment &&
                commentValidation.errors.comment ? (
                  <FormFeedback type="invalid">
                    {commentValidation.errors.comment}
                  </FormFeedback>
                ) : null}
              </FormGroup>
              <Button
                color="success"
                onClick={commentValidation.handleSubmit}
                disabled={state.submitting}
              >
                Send
              </Button>
            </>
          )}
        </div>

        <div className="overflow-y-auto" style={{ maxHeight: "350px" }}>
          {commentData?.length > 0 ? (
            commentData.slice().map((comment, index) => (
              <div key={index} className="mb-3">
                <div className="d-flex align-items-center">
                  <FontAwesomeIcon
                    style={{
                      width: "25px",
                      height: "25px",
                    }}
                    icon={faUser}
                  />
                  <h5 className="ms-3 mb-0">{comment.fromName}</h5>
                </div>
                <div className="ms-5">
                  <p className="mt-1 mb-0">
                    <b>Comment:</b> {comment.comment}
                  </p>
                  <small className="text-muted">
                    {formatDateToUserTimeZone(comment?.createdAt)}
                  </small>
                  <br />
                </div>
              </div>
            ))
          ) : (
            <div
              className="text-muted d-flex justify-content-center align-items-center"
              style={{ minHeight: "100px" }}
            >
              <h3>No Comments</h3>
            </div>
          )}
        </div>
      </div>
    </React.Fragment>
  );
};

export default ViewProfileComments;
