import React, { useMemo, useState } from "react";

import {
  Row,
  Col,
  Card,
  CardBody,
  FormGroup,
  Button,
  CardTitle,
  CardSubtitle,
  Label,
  Input,
  Container,
  FormFeedback,
  Form,
  Table,
} from "reactstrap";

const AccountsFeature = (props) => {
  //meta title
  document.title = "Crescent Exchange | Admin Dashboard";

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <h2> In Development</h2>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default AccountsFeature;
