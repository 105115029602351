const useScrollPosition = () => {
  const saveScrollPosition = () => {
    localStorage.setItem("scrollPosition", window.scrollY);
  };

  const restoreScrollPosition = () => {
    const scrollPosition = localStorage.getItem("scrollPosition");
    if (scrollPosition) {
      window.scrollTo(0, parseInt(scrollPosition, 10));
      localStorage.removeItem("scrollPosition");
    }
  };

  return { saveScrollPosition, restoreScrollPosition };
};

export default useScrollPosition;
