import React, { useEffect, useState } from "react";
import {
  Button,
  Col,
  Form,
  FormFeedback,
  FormGroup,
  Input,
  Label,
  Row,
} from "reactstrap";
import * as Yup from "yup";
import RequiredAsterisk from "../../../../components/Common/RequiredAsterisk";
import { useFormik } from "formik";
import ReactSelect from "react-select";
import SelectStyle from "../../../../common/data/SelectStyle";
import { putApiData } from "../../../../helpers/axiosHelper";
import {
  toastError,
  toastInfo,
  toastSucess,
} from "../../../../common/data/commonfunctions";
import { useLatestTransactions } from "../../../../common/data/latest-transaction-context";

const BankDetailsTab = (props) => {
  const {
    disabled,
    bankData,
    customerId,
    UId,
    activeTab,
    getBeneficiaryByCustomerId,
    setState,
    custBenId,
  } = props;

  const [isLoading, setIsLoading] = useState(false);
  const { allCurrencies } = useLatestTransactions();
  const accType = [
    { label: "Personal", value: 1 },
    { label: "Business", value: 2 },
  ];

  const bankValidation = useFormik({
    enableReinitialize: true,

    initialValues: {
      bankName: bankData?.bankName || "",
      bankAddress: bankData?.bankAddress || "",
      swift: bankData?.swift || "",
      accountNumber: bankData?.accountNumber || "",
      currencyId:
        allCurrencies.find((opt) => opt.value === bankData?.currencyId) || null,
      other: bankData?.other || "",
      isMyAccount: bankData?.isMyAccount || null,
      accountType: bankData?.accountType || null,
    },
    validationSchema: Yup.object({
      bankName: Yup.string().required("Please Enter the Bank Name"),
      bankAddress: Yup.string().required("Please Enter the Bank Address"),
      swift: Yup.string().required("Please Enter the SWIFT Code"),
      accountType: Yup.string().required("Please Select the Account Type"),
      accountNumber: Yup.string().required("Please Enter the Account Number"),
      currencyId: Yup.object().shape({
        label: Yup.string().required("Please Select a Currency"),
        value: Yup.string().required("Please Select a Currency"),
      }),
    }),
    onSubmit: async (values) => {
      setIsLoading(true);

      const currencyValue = values.currencyId?.value;
      const combinedValues = {
        ...values,
        currencyId: currencyValue,
        active: 0,
        isMyAccount: values.isMyAccount === null ? false : values.isMyAccount,
      };
      if (custBenId || bankData?.custBenId) {
        const hasChanges = Object.keys(values).some(
          (key) => values[key] !== bankValidation.initialValues[key]
        );
        if (hasChanges) {
          const updateResponse = await putApiData(
            `api/CustomerBenMap/UpdateBank?CustomerId=${customerId}&UId=${UId}&CustBenId=${
              custBenId || bankData?.custBenId
            }`,
            JSON.stringify(combinedValues)
          );
          if (updateResponse?.success) {
            toastSucess("Bank Details Updated");
            getBeneficiaryByCustomerId(customerId);
            setState((prev) => ({ ...prev, activeTab: activeTab + 1 }));
          } else {
            toastError(updateResponse?.message);
          }
        } else {
          toastInfo("No Changes to Update");
        }
        setIsLoading(false);
      }
    },
  });

  return (
    <div>
      <Form>
        <Row>
          <Col lg={6}>
            <FormGroup>
              <Label htmlFor="currencyId">Currency</Label>
              <RequiredAsterisk />
              <ReactSelect
                name="currencyId"
                id="currencyId"
                placeholder="Select a Currency"
                value={bankValidation.values.currencyId}
                options={allCurrencies}
                styles={SelectStyle}
                isDisabled={disabled}
                onChange={(selectedOption) => {
                  bankValidation.setFieldValue("currencyId", selectedOption);
                }}
                invalid={
                  bankValidation.errors.currencyId &&
                  bankValidation.touched.currencyId
                    ? true
                    : false
                }
              />
              {bankValidation.errors.currencyId &&
                bankValidation.touched.currencyId &&
                bankValidation.values.currencyId === null && (
                  <span className="text-danger" style={{ "font-size": "80%" }}>
                    Please Select a Currency
                  </span>
                )}
            </FormGroup>
          </Col>
          <Col lg="6">
            <FormGroup>
              <Label htmlFor="accountType">Account Type</Label>
              <RequiredAsterisk />
              <Input
                id="accountType"
                name="accountType"
                type="select"
                value={bankValidation.values.accountType}
                disabled={disabled}
                onChange={bankValidation.handleChange}
                onBlur={bankValidation.handleBlur}
                invalid={
                  bankValidation.errors.accountType &&
                  bankValidation.touched.accountType
                    ? true
                    : false
                }
              >
                <option hidden={true} value="">
                  Select Account Type
                </option>
                {accType.map((index) => (
                  <option key={index.value} value={index.value}>
                    {index.label}
                  </option>
                ))}
              </Input>
              {bankValidation.errors.accountType &&
                bankValidation.touched.accountType && (
                  <FormFeedback>
                    {bankValidation.errors.accountType}
                  </FormFeedback>
                )}
            </FormGroup>

            <FormGroup className="mb-2" check>
              <Label check className="mb-2">
                <Input
                  type="checkbox"
                  id="isMyAccount"
                  checked={bankValidation.values.isMyAccount}
                  disabled={disabled}
                  {...bankValidation.getFieldProps("isMyAccount")}
                />
                This is my account
              </Label>
            </FormGroup>
          </Col>
          <Col lg="6">
            <FormGroup className="mb-3">
              <Label for="bankName">Bank Name</Label>
              <RequiredAsterisk />
              <Input
                type="text"
                id="bankName"
                placeholder="Bank Name "
                disabled={disabled}
                {...bankValidation.getFieldProps("bankName")}
                invalid={
                  bankValidation.errors.bankName &&
                  bankValidation.touched.bankName
                    ? true
                    : false
                }
              />
              <FormFeedback>{bankValidation.errors.bankName}</FormFeedback>
            </FormGroup>
          </Col>
          <Col lg={6}>
            <FormGroup>
              <Label for="accountNumber">Account Number</Label>
              <RequiredAsterisk />
              <Input
                id="accountNumber"
                name="accountNumber"
                placeholder="Bank Account Number"
                type="text"
                disabled={disabled}
                {...bankValidation.getFieldProps("accountNumber")}
                invalid={
                  bankValidation.errors.accountNumber &&
                  bankValidation.touched.accountNumber
                    ? true
                    : false
                }
              />
              <FormFeedback>{bankValidation.errors.accountNumber}</FormFeedback>
            </FormGroup>
          </Col>
          <Col lg={6}>
            <FormGroup>
              <Label for="swift">SWIFT Code</Label>
              <Input
                id="swift"
                placeholder="Enter the Swift Number"
                type="text"
                disabled={disabled}
                {...bankValidation.getFieldProps("swift")}
                invalid={
                  bankValidation.errors.swift && bankValidation.touched.swift
                    ? true
                    : false
                }
              />
              <FormFeedback>{bankValidation.errors.swift}</FormFeedback>
            </FormGroup>
          </Col>
          <Col lg="6">
            <FormGroup className="mb-2">
              <Label for="Other">Other</Label>
              <Input
                type="text"
                id="Other"
                placeholder="Any Other Info's"
                {...bankValidation.getFieldProps("other")}
                disabled={disabled}
                invalid={
                  bankValidation.errors.other && bankValidation.touched.other
                    ? true
                    : false
                }
              />
              <FormFeedback>{bankValidation.errors.other}</FormFeedback>
            </FormGroup>
          </Col>
          <Col lg="12">
            <FormGroup className="mb-3">
              <Label for="bankAddress">Bank Address</Label>
              <RequiredAsterisk />
              <Input
                type="textarea"
                id="bankAddress"
                placeholder="Enter the Bank Address"
                disabled={disabled}
                {...bankValidation.getFieldProps("bankAddress")}
                invalid={
                  bankValidation.errors.bankAddress &&
                  bankValidation.touched.bankAddress
                    ? true
                    : false
                }
              />
              <FormFeedback>{bankValidation.errors.bankAddress}</FormFeedback>
            </FormGroup>
          </Col>
        </Row>
      </Form>
      <div>
        {disabled !== true && (
          <div>
            <Button
              color="secondary"
              className="me-1"
              onClick={() => {
                bankValidation.setFieldValue("status", 0);
                bankValidation.handleSubmit();
              }}
            >
              Draft
            </Button>
            <Button
              color="primary"
              onClick={() => {
                if (bankData === null) {
                  bankValidation.setFieldValue("status", 1);
                }
                bankValidation.handleSubmit();
              }}
              disabled={bankValidation.isSubmitting}
            >
              {bankData === null ? "Save" : "Update"}
            </Button>
          </div>
        )}
      </div>
    </div>
  );
};

export default React.memo(BankDetailsTab);
