import React, { useState } from "react";
import {
  Button,
  Col,
  Form,
  FormFeedback,
  FormGroup,
  Input,
  Label,
  Row,
} from "reactstrap";
import RequiredAsterisk from "../../../../components/Common/RequiredAsterisk";

//const CompanyDetails = ({ disabled, companyData, companyValidation }) => {
const CompanyDetails = (props) => {
  const { disabled, companyData, companyValidation } = props;
  const [isSubmitting, setIsSubmitting] = useState(false);

  return (
    <React.Fragment>
      <Form
        className="needs-validation"
        onSubmit={(e) => {
          e.preventDefault();
          companyValidation.handleSubmit();
          return false;
        }}
      >
        <Row>
          <Col lg="6">
            <FormGroup className="mb-2">
              <Label for="beneficiaryName">Account Holder's Full Name</Label>
              <RequiredAsterisk />
              <Input
                type="text"
                id="beneficiaryName"
                placeholder="Name Registered with Bank"
                disabled={disabled}
                {...companyValidation.getFieldProps("beneficiaryName")}
                invalid={
                  companyValidation.errors.beneficiaryName &&
                  companyValidation.touched.beneficiaryName
                    ? true
                    : false
                }
              />
              <FormFeedback>
                {companyValidation.errors.beneficiaryName}
              </FormFeedback>
            </FormGroup>
          </Col>
          <Col lg={6}>
            <FormGroup className="mb-3">
              <Label for="nationalID">National ID</Label>
              <RequiredAsterisk />
              <Input
                id="nationalID"
                placeholder="Enter the National ID"
                {...companyValidation.getFieldProps("nationalID")}
                disabled={disabled}
                invalid={
                  companyValidation.errors.nationalID &&
                  companyValidation.touched.nationalID
                    ? true
                    : false
                }
              />
              <FormFeedback>{companyValidation.errors.nationalID}</FormFeedback>
            </FormGroup>
          </Col>

          <Col lg="6">
            <FormGroup className="mb-2">
              <Label for="email">Email</Label>
              <RequiredAsterisk />
              <Input
                type="text"
                id="email"
                placeholder="Enter the Email"
                {...companyValidation.getFieldProps("email")}
                disabled={disabled}
                invalid={
                  companyValidation.errors.email &&
                  companyValidation.touched.email
                    ? true
                    : false
                }
              />
              <FormFeedback>{companyValidation.errors.email}</FormFeedback>
            </FormGroup>
          </Col>
          <Col lg="6">
            <FormGroup className="mb-2">
              <Label for="mobile">Mobile Number</Label>
              <RequiredAsterisk />
              <Input
                type="text"
                id="mobile"
                placeholder="Enter the Mobile Number"
                {...companyValidation.getFieldProps("mobile")}
                disabled={disabled}
                invalid={
                  companyValidation.errors.mobile &&
                  companyValidation.touched.mobile
                    ? true
                    : false
                }
              />
              <FormFeedback>{companyValidation.errors.mobile}</FormFeedback>
            </FormGroup>
          </Col>
        </Row>
        {disabled !== true && (
          <Button
            color="primary"
            onClick={companyValidation.handleSubmit}
            disabled={companyValidation.isSubmitting}
          >
            {companyData === null ? "Save" : "Update"}
          </Button>
        )}
      </Form>
    </React.Fragment>
  );
};

export default React.memo(CompanyDetails);
