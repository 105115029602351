import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import {
  Row,
  Col,
  CardBody,
  Card,
  Alert,
  Container,
  Form,
  Input,
  FormFeedback,
  Label,
  Button,
} from "reactstrap";
import { json, Link, useNavigate } from "react-router-dom";
import withRouter from "../../components/Common/withRouter";
import * as Yup from "yup";
import { useFormik } from "formik";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import profile from "../../../images/profile-img.png";
import logo from "../../../images/logo.svg";
import { postApiData } from "../../helpers/axiosHelper";
import { useLatestTransactions } from "../../common/data/latest-transaction-context";
import { mobileNumberValidator } from "../../common/data/commonfunctions";
import ButtonSpinner from "../../components/Common/ButtonSpinner";

const SystemUserLogin = (props) => {
  document.title = "Admin Login | Crescent Exchange";
  const navigate = useNavigate();
  const { setBranchId } = useLatestTransactions();
  const [submitting, setSubmitting] = useState(false);
  const [rememberMe, setRememberMe] = useState(false);

  const validation = useFormik({
    enableReinitialize: true,

    initialValues: {
      username: "",
      password: "",
    },

    validationSchema: Yup.object({
      username: Yup.string()
        .required("Please enter an Email or Mobile Number")
        .test(
          "is-email-or-mobile",
          "Enter a valid Email or Mobile Number",
          function (value) {
            const isEmail = /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(value);
            const isMobile = new RegExp(
              `^[0-9]{${mobileNumberValidator.min},${mobileNumberValidator.max}}$`
            ).test(value);

            return isEmail || isMobile;
          }
        ),
      password: Yup.string()
        .required("Please Enter Password")
        .matches(
          /^(?=.*\d)(?=.*[A-Z])(?=.*\W).{8,}$/,
          "Password must be at least 8 characters and must contain at least one digit, one uppercase letter, and one special character"
        ),
    }),

    onSubmit: async (values) => {
      setSubmitting(true);
      const response = await postApiData(
        "api/Login/Login",
        JSON.stringify(values)
      );

      if (response?.success === true) {
        if (response?.data?.token) {
          const logged_user = {
            login: true,
            username: response?.data?.userName,
            token: response?.data.token,
            userType: response?.data?.userType,
            role: response?.data?.roles,
            branchId: response?.data?.branchId,
            expiration: response?.data?.expiration,
          };

          const login = {
            username: values.username,
            password: values.password,
            rememberMe: rememberMe,
          };

          localStorage.setItem("authUser", JSON.stringify(logged_user));
          if (response?.data?.userType === "System User") {
            const isSystemAdmin = response?.data?.roles === "System Admin";
            toast.success("Admin Login Successful", {
              position: "top-right",
              autoClose: 1000,
              closeButton: false,
              onClose: () => {
                if (!isSystemAdmin) {
                  setBranchId(response?.data?.branchId);
                }
                navigate("/admindashboard");
              },
            });
            if (rememberMe) {
              localStorage.setItem("rememberMe", JSON.stringify(login));
            } else {
              localStorage.removeItem("rememberMe");
            }
            setSubmitting(false);
          }
        }
      } else if (response?.success === false) {
        toast.error(`${response?.message}`, {
          position: "top-right",
          autoClose: 3000,
          closeButton: false,
        });
        setSubmitting(false);
      } else {
        toast.error(`${response?.error?.password}`, {
          position: "top-right",
          autoClose: 3000,
          closeButton: false,
        });
        setSubmitting(false);
      }
    },
  });

  useEffect(() => {
    const login = JSON.parse(localStorage.getItem("rememberMe"));
    if (login) {
      validation.setFieldValue("username", login?.username);
      validation.setFieldValue("password", login?.password);
      setRememberMe(login?.rememberMe);
    }
  }, []);

  return (
    <React.Fragment>
      <div className="home-btn d-none d-sm-block">
        <Link to="/home" className="text-dark">
          <i className="bx bx-home h2" />
        </Link>
      </div>
      <div className="account-pages my-2 pt-sm-5">
        <Container>
          <Row className="justify-content-center">
            <Col md={8} lg={6} xl={5}>
              <Card className="overflow-hidden">
                <div className="bg-primary-subtle">
                  <Row>
                    <Col xs={7}>
                      <div className="text-primary p-4">
                        <h5 className="text-primary">Welcome System User !</h5>
                        <p>Sign in to Crescent Exchange Admin Portal</p>
                      </div>
                    </Col>
                    <Col className="col-5 align-self-end">
                      <img src={profile} alt="" className="img-fluid" />
                    </Col>
                  </Row>
                </div>
                <CardBody className="pt-0">
                  <div>
                    <Link to="/" className="logo-light-element">
                      <div className="avatar-md profile-user-wid mb-4">
                        <span className="avatar-title rounded-circle bg-light">
                          <img
                            src={logo}
                            alt=""
                            className="rounded-circle"
                            height="34"
                          />
                        </span>
                      </div>
                    </Link>
                  </div>
                  <div className="p-2">
                    <Form
                      className="form-horizontal"
                      onSubmit={(e) => {
                        e.preventDefault();
                        return false;
                      }}
                    >
                      <ToastContainer closeButton={false} limit={1} />

                      <div className="mb-3">
                        <Label className="form-label">
                          Email / Mobile Number
                        </Label>
                        <Input
                          name="username"
                          className="form-control"
                          placeholder="Enter Email / Mobile Number"
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          value={validation.values.username || ""}
                          invalid={
                            validation.touched.username &&
                            validation.errors.username
                              ? true
                              : false
                          }
                        />
                        {validation.touched.username &&
                        validation.errors.username ? (
                          <FormFeedback type="invalid">
                            {validation.errors.username}
                          </FormFeedback>
                        ) : null}
                      </div>

                      <div className="mb-3">
                        <Label className="form-label">Password</Label>
                        <Input
                          name="password"
                          value={validation.values.password || ""}
                          type="password"
                          placeholder="Enter Password"
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          invalid={
                            validation.touched.password &&
                            validation.errors.password
                              ? true
                              : false
                          }
                        />
                        {validation.touched.password &&
                        validation.errors.password ? (
                          <FormFeedback type="invalid">
                            {validation.errors.password}
                          </FormFeedback>
                        ) : null}
                      </div>

                      <div className="form-check">
                        <input
                          type="checkbox"
                          className="form-check-input"
                          id="customControlInline"
                          checked={rememberMe}
                          onChange={(e) => setRememberMe(e.target.checked)}
                        />
                        <label
                          className="form-check-label"
                          htmlFor="customControlInline"
                        >
                          Remember me
                        </label>
                      </div>

                      <div className="mt-3 d-grid">
                        <Button
                          color="primary"
                          type="submit"
                          disabled={submitting}
                          onClick={validation.handleSubmit}
                        >
                          {submitting ? <ButtonSpinner /> : "Login"}
                        </Button>
                      </div>
                      <div className="mt-4 text-center">
                        <Link to="/forgot-password" className="text-muted">
                          <i className="mdi mdi-lock me-1" />
                          Forgot your password?
                        </Link>
                      </div>
                    </Form>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default withRouter(SystemUserLogin);

SystemUserLogin.propTypes = {
  history: PropTypes.object,
};
