import React, { useEffect, useState, useRef } from "react";
import {
  Button,
  Col,
  Form,
  FormFeedback,
  FormGroup,
  Input,
  Label,
  Row,
} from "reactstrap";
import RequiredAsterisk from "../../../../components/Common/RequiredAsterisk";
import { useFormik } from "formik";
import ReactSelect from "react-select";
import * as Yup from "yup";
import {
  getApiData,
  postApiData,
  putApiData,
} from "../../../../helpers/axiosHelper";
import SelectStyle from "../../../../common/data/SelectStyle";
import {
  toastInfo,
  toastSucess,
  toastError,
} from "../../../../common/data/commonfunctions";
import { ToastContainer, toast } from "react-toastify";

const AddressTab = (props) => {
  const {
    disabled,
    address,
    companyId,
    activeTab,
    setActiveTab,
    companyBenId,
    submitting,
    fetchData,
    setCompanyBenId,
  } = props;
  const [addressState, setAddressState] = useState({
    nationalityOptions: [],
  });
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const formik = useFormik({
    enableReinitialize: true,

    initialValues: {
      street: address?.street || "",
      city: address?.city || "",
      state: address?.state || "",
      country: address?.country || "",
      postalcode: address?.postalCode || "",
      nationality:
        addressState.nationalityOptions.find(
          (opt) => opt.value === parseInt(address?.nationality)
        ) || null,
    },

    validationSchema: Yup.object({
      street: Yup.string().required("Please Enter Street"),
      city: Yup.string()
        .matches(/^[A-Za-z\s]+$/, "City should contain only letters")
        .required("Please Enter City"),
      state: Yup.string()
        .matches(/^[A-Za-z\s]+$/, "State should contain only letters")
        .required("Please Enter State"),
      country: Yup.string()
        .matches(/^[A-Za-z\s]+$/, "Country should contain only letters")
        .required("Please Enter Country"),
      postalcode: Yup.string()
        .matches(/^[0-9]*$/, "Please enter only numbers")
        .required("Please Enter Postal Code"),
      nationality: Yup.object().shape({
        label: Yup.string().required("Please Select Nationality"),
        value: Yup.string().required("Please Select Nationality"),
      }),
    }),

    onSubmit: async (values) => {
      toast.dismiss();

      const nationalityValue = values.nationality && values.nationality.value;

      const combinedValues = {
        ...values,
        companyBenId: companyBenId,
        addressId: address?.addressId,
        nationality: nationalityValue,
      };
      try {
        if (address?.addressId) {
          const hasChanges = Object.keys(values).some(
            (key) => values[key] !== formik.initialValues[key]
          );
          if (hasChanges) {
            const updatedResponse = await putApiData(
              `api/Address/UpdateAddress?AddressID=${address?.addressId}`,
              combinedValues
            );
            if (updatedResponse?.success) {
              toast.dismiss();
              if (!toast.isActive()) {
                toast.success("Address Updated Successfully", {
                  position: "top-right",
                  autoClose: 2000,
                });
              }
              setActiveTab(activeTab + 1);
            } else {
              toastError(updatedResponse?.message);
            }
          } else {
            toastInfo("No Changes to Update");
          }
        } else {
          const combinedValues = {
            ...values,
            companyBenId: companyBenId,
            nationality: `${nationalityValue}`,
          };
          setIsLoading(true);
          const response = await postApiData(
            `api/Address/CreateAddressForAgentBen?CompanyBenId=${companyBenId}`,
            JSON.stringify(combinedValues)
          );
          setIsLoading(false);

          if (response?.success) {
            if (activeTab === 3) {
              toast.dismiss();
              if (!toast.isActive()) {
                toast.success("Address Added Successfully", {
                  position: "top-right",
                  autoClose: 2000,
                });
              }
            }
            setCompanyBenId(combinedValues.companyBenId);
            setActiveTab(activeTab + 1);
          } else {
            toastError(response?.message);
          }
        }
      } finally {
        setIsSubmitting(false);
      }
    },
  });

  const mapNationality = (nationality) => {
    if (!nationality) return [];
    return nationality?.map((e) => ({
      label: e.nation,
      value: e.id,
    }));
  };

  const fetchNationality = async () => {
    const response = await getApiData("api/Address/GetAllNationality");
    setAddressState((prev) => ({
      ...prev,
      nationalityOptions: mapNationality(response),
    }));
  };

  useEffect(() => {
    fetchNationality();
  }, []);

  // useEffect(() => {
  //   if (activeTab !== 3) {
  //     //setToastShown(false);
  //     toastRef.current = false;
  //   }
  // }, [activeTab]);

  return (
    <React.Fragment>
      <Form onSubmit={formik.handleSubmit}>
        <Row>
          <Col lg="6">
            <div className="mb-3">
              <Label for="street">Street</Label>
              <RequiredAsterisk />
              <Input
                type="text"
                className="form-control"
                id="street"
                placeholder="Enter street"
                value={formik.values.street}
                disabled={disabled}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                invalid={
                  formik.touched.street && formik.errors.street ? true : false
                }
              />
              {formik.touched.street && formik.errors.street ? (
                <FormFeedback type="invalid">
                  {formik.errors.street}
                </FormFeedback>
              ) : null}
            </div>
          </Col>
          <Col lg="6">
            <div className="mb-3">
              <Label for="city">City</Label>
              <RequiredAsterisk />
              <Input
                type="text"
                id="city"
                placeholder="Enter City"
                value={formik.values.city}
                disabled={disabled}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                invalid={
                  formik.touched.city && formik.errors.city ? true : false
                }
              />
              {formik.touched.city && formik.errors.city ? (
                <FormFeedback type="invalid">{formik.errors.city}</FormFeedback>
              ) : null}
            </div>
          </Col>
          <Col lg="6">
            <div className="mb-3">
              <Label for="state">State</Label>
              <RequiredAsterisk />
              <Input
                type="text"
                id="state"
                placeholder="Enter State"
                value={formik.values.state}
                disabled={disabled}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                invalid={
                  formik.touched.state && formik.errors.state ? true : false
                }
              />
              {formik.touched.state && formik.errors.state ? (
                <FormFeedback type="invalid">
                  {formik.errors.state}
                </FormFeedback>
              ) : null}
            </div>
          </Col>
          <Col lg="6">
            <div className="mb-3">
              <Label for="country">Country</Label>
              <RequiredAsterisk />
              <Input
                type="text"
                className="form-control"
                id="country"
                placeholder="Enter Country"
                value={formik.values.country}
                disabled={disabled}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                invalid={
                  formik.touched.country && formik.errors.country ? true : false
                }
              />
              {formik.touched.country && formik.errors.country ? (
                <FormFeedback type="invalid">
                  {formik.errors.country}
                </FormFeedback>
              ) : null}
            </div>
          </Col>
          <Col lg="6">
            <div className="mb-3">
              <Label for="postalcode">Postal Code</Label>
              <RequiredAsterisk />
              <Input
                type="text"
                id="postalcode"
                placeholder="Enter Postal Code"
                value={formik.values.postalcode}
                disabled={disabled}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                invalid={
                  formik.touched.postalcode && formik.errors.postalcode
                    ? true
                    : false
                }
              />
              {formik.touched.postalcode && formik.errors.postalcode ? (
                <FormFeedback type="invalid">
                  {formik.errors.postalcode}
                </FormFeedback>
              ) : null}
            </div>
          </Col>
          <Col lg="6">
            <FormGroup className="mb-3">
              <Label for="nationality">Nationality</Label>
              <RequiredAsterisk />
              <ReactSelect
                name="nationality"
                placeholder="Select Nationality"
                id="nationality"
                styles={SelectStyle}
                value={formik.values.nationality}
                isDisabled={disabled}
                options={addressState.nationalityOptions}
                onChange={(selectedOption) =>
                  formik.setFieldValue("nationality", selectedOption)
                }
                onBlur={formik.handleBlur}
                invalid={
                  formik.touched.nationality && formik.errors.nationality
                    ? true
                    : false
                }
              />
              {formik.errors.nationality &&
              formik.touched.nationality &&
              formik.values.nationality === null ? (
                <span className="text-danger" style={{ "font-size": "80%" }}>
                  Please Select a Nationality
                </span>
              ) : null}
            </FormGroup>
          </Col>
        </Row>
        {disabled !== true && (
          <Button
            color="primary"
            onClick={formik.handleSubmit}
            disabled={formik.isSubmitting}
          >
            {address === null ? "Add Address" : "Update"}
          </Button>
        )}
      </Form>
      {/* <ToastContainer limit={1} /> */}
    </React.Fragment>
  );
};

export default React.memo(AddressTab);
