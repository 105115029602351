import React, { useState, useEffect } from "react";
import { Container } from "reactstrap";
import LiveFxRates from "./LiveFxRates";
import * as signalR from "@microsoft/signalr";
import config from "../../../config";

const LiveFxRatesIndex = () => {
  document.title = "Live Rates | Crescent Exchange";
  const [rates, setRates] = useState([]);
  const [avg, setAvg] = useState(0);

  useEffect(() => {
    const connection = new signalR.HubConnectionBuilder()
      .withUrl(config.livefxUrl)
      .withAutomaticReconnect()
      .build();

    connection.start().catch((err) => console.error(err.toString()));

    connection.on("ReceiveRates", (data) => {
      const parse = JSON.parse(data);
      const ratesArray = Array.isArray(parse) ? parse : [parse];

      setRates((prevRates) =>
        ratesArray.reduce(
          (acc, newRate) => {
            const existingRateIndex = acc.findIndex(
              (rate) => rate.currency === newRate.currency
            );

            if (existingRateIndex !== -1) {
              acc[existingRateIndex] = newRate;
            } else {
              acc.push(newRate);
            }

            return acc;
          },
          [...prevRates]
        )
      );

      const firstThreeBids = ratesArray.slice(0, 3).map((rate) => rate?.bid);
      const sumOfBids = firstThreeBids.reduce((acc, bid) => acc + bid, 0);
      setAvg(sumOfBids / firstThreeBids.length || 0);
    });

    return () => connection.stop();
  }, []);

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <LiveFxRates rates={rates} avg={avg} />
        </Container>
      </div>
    </React.Fragment>
  );
};

export default LiveFxRatesIndex;
