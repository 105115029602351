export const roleList = [
  { label: "System Admin", value: 1 },
  { label: "System Branch Admin", value: 2 },
  { label: "System User", value: 3 },
  // { label: "Agent Super Admin", value: "4" },
  // { label: "Agent Branch Admin", value: "5" },
  // { label: "Agent User", value: "6" },
  // { label: "Customer", value: "7" },
  // { label: "Beneficiary", value: "8" },
];

export const agentList = [
  { label: "Agent Super Admin", value: 4 },
  { label: "Agent Branch Admin", value: 5 },
  { label: "Agent User", value: 6 },
];
