import React, { useEffect, useState } from "react";
import { Col, Input, Label, FormFeedback,Row } from "reactstrap";
import { useFormik} from "formik";
import * as Yup from "yup";

const operationTimes = [
  { day: 0, startTime: "00:00", endTime: "00:00", name: "All Days", selected: false, disabled: false },
  { day: 1, startTime: "00:00", endTime: "00:00", name: "Sunday", selected: false, disabled: false },
  { day: 2, startTime: "00:00", endTime: "00:00", name: "Monday", selected: false, disabled: false },
  { day: 3, startTime: "00:00", endTime: "00:00", name: "Tuesday", selected: false, disabled: false },
  { day: 4, startTime: "00:00", endTime: "00:00", name: "Wednesday", selected: false, disabled: false },
  { day: 5, startTime: "00:00", endTime: "00:00", name: "Thursday", selected: false, disabled: false },
  { day: 6, startTime: "00:00", endTime: "00:00", name: "Friday", selected: false, disabled: false },
  { day: 7, startTime: "00:00", endTime: "00:00", name: "Saturday", selected: false, disabled: false }
]
//customize receiving props to our need
const OperationsData = props => {

  const [operationTimeData, setOperationTimeData] = useState([]); // to receive data from main during fetch

  const [viewflag] = useState(props.viewflag); // to handle View logic
  const [viewopData] = useState(props.viewopData); // to receive data from main during fetch
  const onDataChange = props.onDataChange; //to send data back to parent component

  const timeValidation = useFormik({

    enableReinitialize: true,

    initialValues: {
      operationalTimes: operationTimes,
    },
    validationSchema: Yup.object().shape({
      operationalTimes: Yup.array().of(
        Yup.object().shape({
          day:Yup.number(),
          name:Yup.string(),
          disabled:Yup.boolean(),
          selected: Yup.boolean(),
          startTime: Yup.string()
          .when('selected', {
            is: (value) => value === true, // Condition: selected value is true
            then: () => Yup.string()
                        .required('Start Time is required')
                        .test('min-time', 'Start Time must be after 00:00', (value) => {
                          // Replace '00:01' with your desired minimum time (e.g., '09:00')
                          return  value !== "00:00";
                        }),   
            otherwise:() => Yup.string(), // No validation otherwise
          }),
          endTime: Yup.string().when('selected', {
            is: (value) => value === true, // Condition: selected value is true
            then: () => Yup.string()
                        .required('endTime is required')
                        .test('not-time', 'End Time must be after Start Time and 00:00', (value, context) => {
                          // Ensure End Time is after Start Time and 00:00
                          const startTime = context.parent.startTime;
                          return value > startTime && value !== "00:00";
                        }),
            otherwise: () => Yup.string(), // No validation otherwise
          })
        })
      )
    }),
    onSubmit: async (values, { resetForm }) => {

    }
  });

  // handle checkbox changes - old code
  const handleCheckboxChange = (event, index, value) => {
    const updatedData = [];
    timeValidation.values.operationalTimes.forEach(t => {
      updatedData.push({ ...t });
    })
    updatedData[index].selected = value;

    // logic if all days is selected (other days should be unchecked and disabled)
    if (index === 0) {
      updatedData.forEach((d, index) => {
        if (index !== 0) {
          d.selected = false;
          d.disabled = viewflag || updatedData[0].selected;
          d.startTime =  "00:00";
          d.endTime =  "00:00";
        }
      })
    }
    //set the value to 00:00 if value is not set
    if (!value) {
      updatedData[index].startTime = "00:00";
      updatedData[index].endTime =  "00:00";
    }
    timeValidation.setValues({
      operationalTimes:updatedData
    })
    setOperationTimeData(updatedData);
    onDataChange(updatedData)

  };
  // handle changes made to the component
  const handleDataChange = (event, index, source) => {
    timeValidation.validateField(event.target.name); // revalidating the control after change to show errors
    const updatedData = [...operationTimeData];

    //Validate Input and set errors
    updatedData[index][source] = event.target.value;
    setOperationTimeData(updatedData);
    onDataChange(updatedData);
  };
  useEffect(() => {
    const opTimes = operationTimes.map(ot => {
      let incomingOT = viewopData.find(v => v.day === ot.day);
      let isAllDaySelected = viewopData.find(v => v.day === 0) !== undefined;
      if (incomingOT) {
        return {
          ...ot,
          startTime: incomingOT.startTime,
          endTime: incomingOT.endTime,
          selected: true,
          disabled: viewflag || (ot.day !== 0 ? isAllDaySelected : false)
        }
      } else {
        return {
          ...ot,
          disabled: viewflag || isAllDaySelected
        }
      }
    })
    timeValidation.setValues({
      operationalTimes : opTimes
    })
    setOperationTimeData(opTimes);
    onDataChange(opTimes);

  }, []);

  return (
    <div>
      <Row className="d-flex align-items-center">
        <Col sm="4">
        </Col>
        <Col sm="4">
          <Label for="starttime" style={{ paddingLeft: "6px" }}>Start Time</Label>
        </Col>
        <Col sm="4">
          <Label for="endtime" style={{ paddingLeft: "6px" }}>End Time</Label>
        </Col>
      </Row>
      {
        timeValidation.values.operationalTimes.map((item, index) => (
          <Row className="d-flex align-items-center" key={index}>
            <Col sm="4"  >
              <div  >
                 <Label htmlFor={item.name} style={{ paddingRight: "6px" }}></Label>
                  <Input type="checkbox" name={item.name}
                  className="me-1"
                  onBlur={timeValidation.handleBlur}
                    onClick={event => {
                      handleCheckboxChange(event, index, !timeValidation.values.operationalTimes[index].selected)
                    }}
                    checked={timeValidation.values.operationalTimes[index].selected}
                    disabled={timeValidation.values.operationalTimes[index].disabled}
                  />
                  {item.name}
              </div>

            </Col>
            <Col sm="4">
              <div >
                <Input
                  className=" mb-3 form-control"
                  type="time"
                  id={`${item.name}.startTime`}
                  name={`operationalTimes[${index}].startTime`}
                  disabled={item.disabled || !item.selected}
                  value={timeValidation.values.operationalTimes[index].startTime}
                  onChange={(event) => handleDataChange(event, index, 'startTime')}
                  onBlur={timeValidation.handleBlur}
                  invalid={
                    (timeValidation.errors.operationalTimes && timeValidation.errors.operationalTimes[index] && timeValidation.errors.operationalTimes[index].startTime) ? true : false
                  }
                />
                {(timeValidation.errors.operationalTimes && timeValidation.errors.operationalTimes[index] && timeValidation.errors.operationalTimes[index].startTime) ? (
                    <FormFeedback>{timeValidation.errors.operationalTimes[index].startTime}</FormFeedback>
                  ):null}

              </div>
            </Col>
            <Col sm="4">
              <div >
                <Input
                  className=" mb-3 form-control"
                  type="time"
                  name={`operationalTimes[${index}].endTime`}
                  id={`endTime-${index}`}
                  disabled={item.disabled || !item.selected}
                  value={timeValidation.values.operationalTimes[index].endTime}
                  onChange={(event) => handleDataChange(event, index, 'endTime')}
                  onBlur={timeValidation.handleBlur}
                  invalid={
                    (timeValidation.errors.operationalTimes && timeValidation.errors.operationalTimes[index] && timeValidation.errors.operationalTimes[index].endTime) ? true : false
                  } />
                  {(timeValidation.errors.operationalTimes && timeValidation.errors.operationalTimes[index] && timeValidation.errors.operationalTimes[index].endTime) ? (
                    <FormFeedback>{timeValidation.errors.operationalTimes[index].endTime}</FormFeedback>
                  ):null}
                {/* {endTimeErrors[index] && <span className="text-danger mb-3" style={{ fontSize: "80%" }}>{endTimeErrors[index]}</span>} */}
              </div>
            </Col>
          </Row>
        ))
      }
      {/* <Row className="d-flex align-items-center">
      {opTimeErrors && <span className="text-danger mb-3" style={{fontSize: "80%"}}>{opTimeErrors</span>}
      </Row> */}

    </div>
  )
}
export default OperationsData