import React, { useEffect, useState } from "react";
import { Col, Container, Row } from "reactstrap";
import CardUserAdmin from "./CardUserAdmin";
import TransactionTableDashboard from "./TransactionDashboard";
import { useLocation } from "react-router-dom";
import Booking from "../Transaction/AddTransaction/Booking";
import { mapStatus, mapTransactionType } from "../../common/data/StatusLabels";
import { getApiData } from "../../helpers/axiosHelper";
import { useLatestTransactions } from "../../common/data/latest-transaction-context";
import { formatDateToUserTimeZone } from "../../common/data/date";
import { isSystemAdmin, rateToFixed } from "../../common/data/commonfunctions";
import { path } from "../../routes/path";
import ManageUserTab from "../ManageUser/ManageUserTab";
import Loader from "../../components/Common/Loader";
import {
  mapTransactionData,
  TransactionType,
} from "./../../common/data/transactionType";

const AdminDashboard = () => {
  const [isLoading, setIsLoading] = useState(false);
  const { pathname } = useLocation();
  const { branchId } = useLatestTransactions();
  const [activeTab, setActiveTab] = useState(1);
  const [transactionData, setTransactionData] = useState([]);

  const getTransaction = async () => {
    setIsLoading(true);
    const response = await getApiData(
      `api/Transaction/GetAllOpenTransactionByBranch?branchId=${branchId}`
    );
    setIsLoading(false);
    setTransactionData(
      mapTransactionData(response?.data)?.filter(
        (x) => x.fromCustomerId !== null
      )
    );
  };

  const getAgentTransaction = async () => {
    setIsLoading(true);
    const response = await getApiData(
      `api/Transaction/GetAllAgentOpenTransactionByBranch?branchId=${branchId}`
    );
    setIsLoading(false);
    setTransactionData(mapTransactionData(response?.data));
  };

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          {pathname === path.adminDashboard && <CardUserAdmin />}
          <TransactionTableDashboard
            isLoading={isLoading}
            setIsLoading={setIsLoading}
            transactionData={transactionData}
            activeTab={activeTab}
            setActiveTab={setActiveTab}
            getAgentTransaction={getAgentTransaction}
            getTransaction={getTransaction}
            branchId={branchId}
          />
          {/* {pathname === path.adminDashboard && (
            <Booking
              title={null}
              isLoading={isLoading}
              setIsLoading={setIsLoading}
              activeTab={activeTab}
              getAgentTransaction={getAgentTransaction}
              getTransaction={getTransaction}
            />
          )} */}
          {pathname === path.adminDashboard && isSystemAdmin && (
            // <Booking
            //   title={null}
            //   isLoading={isLoading}
            //   setIsLoading={setIsLoading}
            //   activeTab={activeTab}
            //   getAgentTransaction={getAgentTransaction}
            //   getTransaction={getTransaction}
            // />
            <ManageUserTab load={isLoading} />
          )}
          {isLoading && <Loader />}
        </Container>
      </div>
    </React.Fragment>
  );
};

export default AdminDashboard;
