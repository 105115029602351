import React, { useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import * as Yup from "yup";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useFormik } from "formik";
import {
  Card,
  CardBody,
  Col,
  Container,
  Form,
  FormFeedback,
  Input,
  Label,
  Row,
} from "reactstrap";
import profile from "../../../images/profile-img.png";
import logoImg from "../../../images/logo.svg";
import lightlogo from "../../../images/logo-light.svg";
import withRouter from "../../components/Common/withRouter";
import { getApiData, putApiData } from "../../helpers/axiosHelper";
import { path } from "../../routes/path";
import {
  toastError,
  toastSucess,
  toastWarn,
} from "../../common/data/commonfunctions";

const ResetPassword = (props) => {
  const navigate = useNavigate();
  const [isTokenValid, setIsTokenValid] = useState(false);

  let token = "";

  function Search() {
    const location = useLocation();
    const queryString = new URLSearchParams(location.search);
    const searchTerm = queryString.get("token");
    return searchTerm;
  }

  token = Search();

  const verifyToken = async () => {
    const resp = await getApiData(
      `api/ForgotPassword/VerifyForgotPasswordToken?token=${token}`
    );
    setIsTokenValid(resp);
  };

  useEffect(() => {
    verifyToken();
  }, []);

  document.title = "Reset Password | Crescent Exchange";

  const validation = useFormik({
    enableReinitialize: true,

    initialValues: {
      password: "",
      token: props.router.params.token,
    },
    validationSchema: Yup.object({
      password: Yup.string().required("Please Enter Your Password"),
      password_confirmation: Yup.string()
        .oneOf([Yup.ref("password"), null], "Passwords must match")
        .required("Confirm password is required"),
    }),
    onSubmit: async (values) => {
      if (isTokenValid) {
        const response = await putApiData(
          "api/ForgotPassword/UpdateForgotPassword",
          JSON.stringify(values)
        );
        if (response?.success) {
          toastSucess("Password Changed Successfully");
          navigate(path.login);
        } else {
          toastError("Error in changing Password");
        }
      } else {
        toastWarn("Invalid User, Contact Admin");
      }
    },
  });

  return (
    <React.Fragment>
      <div className="account-pages my-5 pt-sm-5">
        <Container>
          <Row className="justify-content-center">
            <Col md={8} lg={6} xl={5}>
              <Card className="overflow-hidden">
                <div className="bg-primary-subtle">
                  <Row>
                    <Col xs={7}>
                      <div className="text-primary p-4">
                        <h5 className="text-primary"> Reset Password</h5>
                        <p>Reset your Crescent Exchange account password</p>
                      </div>
                    </Col>
                    <Col xs={5} className="align-self-end">
                      <img src={profile} alt="" className="img-fluid" />
                    </Col>
                  </Row>
                </div>
                <CardBody className="pt-0">
                  <div>
                    <div className="auth-logo">
                      <Link to="/" className="auth-logo-light">
                        <div className="avatar-md profile-user-wid mb-4">
                          <span className="avatar-title rounded-circle bg-light">
                            <img
                              src={lightlogo}
                              alt=""
                              className="rounded-circle"
                              height="34"
                            />
                          </span>
                        </div>
                      </Link>
                      <Link to="/" className="auth-logo-dark">
                        <div className="avatar-md profile-user-wid mb-4">
                          <span className="avatar-title rounded-circle bg-light">
                            <img
                              src={logoImg}
                              alt=""
                              className="rounded-circle"
                              height="34"
                            />
                          </span>
                        </div>
                      </Link>
                    </div>
                  </div>

                  <div className="p-2">
                    <ToastContainer closeButton={false} limit={1} />
                    <Form
                      className="form-horizontal"
                      onSubmit={(e) => {
                        e.preventDefault();
                        validation.handleSubmit();
                        return false;
                      }}
                    >
                      <div className="mb-3">
                        <Label className="form-label">New Password</Label>
                        <span className="text-danger">*</span>
                        <Input
                          name="password"
                          type="password"
                          placeholder="Enter New Password"
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          value={validation.values.password || ""}
                          invalid={
                            validation.touched.password &&
                            validation.errors.password
                              ? true
                              : false
                          }
                        />
                        {validation.touched.password &&
                        validation.errors.password ? (
                          <FormFeedback type="invalid">
                            {validation.errors.password}
                          </FormFeedback>
                        ) : null}
                      </div>

                      <div className="mb-2">
                        <Label htmlFor="confirmPassword" className="form-label">
                          Confirm Password{" "}
                          <span className="text-danger">*</span>
                        </Label>
                        <Input
                          name="password_confirmation"
                          type="password"
                          placeholder="Confirm Password"
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          value={validation.values.password_confirmation || ""}
                          invalid={
                            validation.touched.password_confirmation &&
                            validation.errors.password_confirmation
                              ? true
                              : false
                          }
                        />
                        {validation.touched.password_confirmation &&
                        validation.errors.password_confirmation ? (
                          <FormFeedback type="invalid">
                            <div>{validation.errors.password_confirmation}</div>
                          </FormFeedback>
                        ) : null}
                      </div>
                      <div className="text-end">
                        <button className="btn btn-primary w-md " type="submit">
                          Reset
                        </button>
                      </div>
                    </Form>
                  </div>
                </CardBody>
              </Card>
              <div className="mt-5 text-center">
                <p>
                  Remember It ?{" "}
                  <Link to="/login" className="fw-medium text-primary">
                    {" "}
                    Sign In here
                  </Link>{" "}
                </p>
                <p>© {new Date().getFullYear()} Crescent Exchange</p>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default withRouter(ResetPassword);
