export const getCurrencyFlag = (allCurrencies, code) => {
  const flag = allCurrencies?.find(
    (x) => x.currencyCode === code
  )?.currencyFlag;
  return flag;
};

export const displayFlag = (flag) => {
  const flagUrl = `data:image/png;base64,${flag}`;

  return (
    <img
      src={flagUrl}
      alt=""
      onError={(e) => (e.target.src = "")}
      style={{ width: "20px", marginRight: "8px" }}
    />
  );
};

export const baseCurrency = "SGD";
export const rateDigits = 8;
export const displayDigits = 2;
