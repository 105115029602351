import React, { useEffect, useMemo, useState } from "react";
import { Button, Badge } from "reactstrap";
import TableContainer from "../../components/Common/TableContainer";
import Loader from "../../components/Common/Loader";
import { getApiData, putApiData } from "../../helpers/axiosHelper";
import AddManageAgent from "./AddManageAgent";
import ViewAgentDetails from "./ViewAgentDetails";
import { toast } from "react-toastify";
import DeleteModal from "../../components/Common/DeleteModal";
import ConfirmationSwal from "../../components/Common/ConfirmationSwal";
import {
  activate,
  approve,
  deactivate,
  edit,
  view,
} from "../../common/data/icons";

const AgentDetails = ({ companyId }) => {
  const [addModal, setAddModal] = useState(false);
  const [viewModal, setViewModal] = useState(false);
  const [agentData, setAgentData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [selectedUid, setSelectedUid] = useState("");
  const [deleteModal, setDeleteModal] = useState(false);
  const [deleteAgentId, setDeleteAgentId] = useState(null);
  const [editMode, setEditMode] = useState(false);
  const toggleAddAgent = () => setAddModal(!addModal);
  const toggleViewDetails = () => setViewModal(!viewModal);

  const approveByAgentUId = async (agentUid) => {
    const result = await ConfirmationSwal("Are you sure?", "Yes", "Cancel");
    if (result.isConfirmed) {
      const response = await putApiData(
        `api/AgentUser/ApproveOrReject?AgentID=${agentUid}&Approved=true`
      );
      if (response?.success === true) {
        toast.success("Agent Approved", {
          position: "top-right",
          autoClose: 2000,
        });
        fetchData();
      } else {
        toast.error(response?.message, {
          position: "top-right",
          autoClose: 2000,
        });
      }
    }
  };

  const fetchData = async () => {
    setIsLoading(true);
    const response = await getApiData(
      `api/AgentUser/GetAgentsViewByCompany?companyId=${companyId}`
    );

    const UserStatus = {
      0: "Unknown",
      1: "Verification",
      2: "Document Pending",
      3: "Approval Pending",
      4: "Approved",
      5: "Deactivated",
    };

    const roleLabel = {
      4: "Agent Super Admin",
      5: "Agent Branch Admin",
      6: "Agent User",
    };

    const mappedResponse = response?.data?.map((item, index) => ({
      index: index + 1,
      uid: item.uid,
      agentUid: item.agentUid,
      companyId: item.companyId,
      firstName: item.firstName,
      lastName: item.lastName,
      middleName: item.middleName,
      phone: item.phone,
      email: item.email,
      active: item.active,
      status: item.userStatus,
      userStatus: UserStatus[item.userStatus],
      role: roleLabel[item.roleId],
    }));
    setAgentData(mappedResponse);
    setIsLoading(false);
  };

  const DeleteData = async (agentUid, isActive) => {
    const result = await ConfirmationSwal("Are you sure?", "Yes", "Cancel");

    if (result.isConfirmed) {
      const response = await putApiData(
        `api/AgentUser/ApproveOrReject?AgentID=${agentUid}&Approved=${isActive}`
      );
      if (response?.success) {
        toast.success(
          `Agent ${isActive ? "Activated" : "Deactivated"} Successfully`,
          {
            position: "top-right",
            autoClose: 3000,
          }
        );
        fetchData();
        setDeleteModal(false);
      }
    }
  };

  useEffect(() => {
    if (companyId) {
      fetchData();
    }
  }, [companyId]);

  const viewAgentDetails = (agentUid) => {
    setSelectedUid(agentUid);
    toggleViewDetails();
  };

  const addNewAgent = () => {
    return (
      <Button
        type="button"
        color="primary"
        onClick={toggleAddAgent}
        className="btn mb-2 me-2"
      >
        + Create New Agent
      </Button>
    );
  };

  const columns = useMemo(
    () => [
      {
        Header: "Email",
        accessor: "email",
        filterable: false,
        disableFilters: true,
      },
      {
        Header: "Role",
        accessor: "role",
        filterable: false,
        disableFilters: true,
      },
      {
        Header: "Status",
        accessor: "userStatus",
        disableFilters: true,
        filterable: false,
        Cell: (cellProps) => {
          return (
            <Badge
              className={
                "font-size-11 badge-soft-" +
                (cellProps.value === "Approved"
                  ? "success"
                  : "danger" && cellProps.value === "Verification"
                  ? "warning"
                  : "danger" && cellProps.value === "Document Pending"
                  ? "primary"
                  : "danger" && cellProps.value === "Approval Pending"
                  ? "danger"
                  : "danger")
              }
            >
              {cellProps.value}
            </Badge>
          );
        },
      },
      {
        Header: "Actions",
        Cell: ({ row }) => (
          <div>
            {row.original.active ? (
              <>
                <Button
                  color="primary"
                  title="View"
                  className="btn-sm btn-rounded me-1"
                  onClick={() => {
                    setEditMode(true);
                    viewAgentDetails(row.original.agentUid);
                  }}
                >
                  {view()}
                </Button>

                <Button
                  color="success"
                  title="Edit"
                  className="btn-sm btn-rounded me-1"
                  onClick={() => {
                    setEditMode(false);
                    viewAgentDetails(row.original.agentUid);
                  }}
                >
                  {edit()}
                </Button>

                <Button
                  type="button"
                  color="danger"
                  className="btn-sm btn-rounded me-1"
                  title="Deactivate"
                  onClick={() => {
                    setDeleteAgentId(row.original.agentUid);
                    setDeleteModal(true);
                  }}
                >
                  {deactivate()}
                </Button>
              </>
            ) : (
              <Button
                type="button"
                color="success"
                className="btn-sm btn-rounded me-1"
                title="Activate"
                onClick={() => DeleteData(row.original.agentUid, true)}
              >
                {activate()}
              </Button>
            )}

            {row.original.status === 3 && (
              <Button
                type="button"
                color="success"
                className="btn-sm btn-rounded me-1"
                title="Approve"
                onClick={() => approveByAgentUId(row.original.agentUid)}
              >
                {approve()}
              </Button>
            )}
          </div>
        ),
      },
    ],
    []
  );

  return (
    <React.Fragment>
      <AddManageAgent
        isOpen={addModal}
        companyId={companyId}
        toggle={toggleAddAgent}
        getAgents={fetchData}
      />
      <ViewAgentDetails
        isOpen={viewModal}
        toggle={toggleViewDetails}
        agentUid={selectedUid}
        getAgents={fetchData}
        editMode={editMode}
      />
      <DeleteModal
        show={deleteModal}
        onDeleteClick={() => DeleteData(deleteAgentId, false)}
        onCloseClick={() => setDeleteModal(false)}
        deactivateTitle={true}
      />
      {isLoading ? (
        <Loader />
      ) : (
        <div>
          {agentData?.length === 0 ? (
            <div
              className="d-flex flex-column alert alert-info"
              style={{ textAlign: "center" }}
            >
              <div>
                No Agents found for this company &nbsp;&nbsp;&nbsp;
                {addNewAgent()}
              </div>
              <div className="text-danger">
                Note : Please, Create Agent Super Admin, before creating the
                agent user
              </div>
            </div>
          ) : (
            <React.Fragment>
              {addNewAgent()}
              <TableContainer
                columns={columns}
                data={agentData}
                isGlobalFilter={true}
                isAddOptions={false}
                customPageSize={10}
                isPageSelect={false}
              />
            </React.Fragment>
          )}
        </div>
      )}
    </React.Fragment>
  );
};

export default AgentDetails;
