// Authentication related pages
import Logout from "../pages/Authentication/Logout";
import Register from "../pages/Authentication/Register";
import ForgetPwd from "../pages/Authentication/ForgetPassword";
import ResetPassword from "../pages/Authentication/ResetPassword";
import Landing from "../pages/Authentication/Landing/index";
import Home from "../pages/Authentication/Home";
import FAQs from "../pages/faqs";
import SystemUserLogin from "../pages/Authentication/SystemUserLogin";
import EmailVerification from "../pages/Authentication/EmailVerification";
import ResetSuccess from "../pages/Authentication/ResetSuccess";
import AdminDashboard from "../pages/Dashboard_Admin/index";
import Profile from "../pages/Profile/Profile";
import ManageRatesFeature from "../pages/ManageRate/ManageRates";
import ManageUser from "../pages/ManageUser/ManageUser";
import TransactionHistory from "../pages/Transaction/TransactionHistory";
import AccountsFeature from "../pages/Accounts/accounts";
import Stock from "../pages/Stock/Stock";
import StockLog from "../pages/Stock/StockLog";
import Branch from "../pages/Branch/Branch";
import ChangePassword from "../pages/ChangePassword/ChangePassword";
import ManageCompanyTab from "../pages/ManageAgent/ManageCompanyTab";
import BranchReport from "../pages/Reports/BranchReport";
import ProfitAndLossReport from "../pages/Reports/ProfitAndLossReport";
import CurrencyReport from "../pages/Reports/CurrencyReport";
import TransactionReport from "../pages/Reports/TransactionReport";
import StockSoldReport from "../pages/Reports/StockSoldReport";
import LiveFxRatesIndex from "./../pages/LiveFxRates/LiveFxRatesIndex";
import AddTransaction from "../pages/Transaction/AddTransaction/AddTransaction";

const authProtectedRoutes = [
  { path: "/admindashboard", component: <AdminDashboard /> },
  { path: "/manage-user", component: <ManageUser /> },
  { path: "/manage-company", component: <ManageCompanyTab /> },
  { path: "/branch-report", component: <BranchReport /> },
  { path: "/profit-and-loss-report", component: <ProfitAndLossReport /> },
  { path: "/currency-report", component: <CurrencyReport /> },
  { path: "/transaction-report", component: <TransactionReport /> },
  { path: "/stocksold-report", component: <StockSoldReport /> },
  { path: "/manage-rates", component: <ManageRatesFeature /> },
  { path: "/branch", component: <Branch /> },
  { path: "/transaction/manage-transaction", component: <AdminDashboard /> },
  {
    path: "/transaction/transaction-history",
    component: <TransactionHistory />,
  },
  { path: "/transaction/add-transaction", component: <AddTransaction /> },
  { path: "/stock/manage-stock", component: <Stock /> },
  { path: "/stock/stock-log", component: <StockLog /> },
  { path: "/home", component: <Home /> },
  { path: "/faqs", component: <FAQs /> },
  { path: "/profile", component: <Profile /> },
  { path: "/changepassword", component: <ChangePassword /> },
  { path: "/accounts", component: <AccountsFeature /> },
  { path: "/live-rates", component: <LiveFxRatesIndex /> },
];

const publicRoutes = [
  // { path: "/landing", component: <Landing /> },
  { path: "/", exact: true, component: <SystemUserLogin /> },
  { path: "/logout", component: <Logout /> },
  { path: "/forgot-password", component: <ForgetPwd /> },
  { path: "/register", component: <Register /> },
  { path: "/emailverification/:uid", component: <EmailVerification /> },
  { path: "/reset-password", component: <ResetPassword /> },
  { path: "/reset-success", component: <ResetSuccess /> },
  { path: "/home", component: <Landing /> },
];

export { publicRoutes, authProtectedRoutes };
