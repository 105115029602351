import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";

const RiskSwal = withReactContent(Swal);

const ConfirmationSwal = async (
  title,
  confirmButtonText,
  cancelButtonText,
  text
) => {
  return RiskSwal.fire({
    title: title,
    icon: "warning",
    width: "300px",
    text: text ?? "",
    showConfirmButton: true,
    showCancelButton: true,
    confirmButtonText: confirmButtonText,
    confirmButtonColor: "#3085d6",
    cancelButtonText: cancelButtonText,
  });
};

export default ConfirmationSwal;
