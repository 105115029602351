import React, { useEffect, useState } from "react";
import { Button, Col, NavItem, Row, TabContent, TabPane } from "reactstrap";
import classnames from "classnames";
import { postApiData, putApiData } from "./../../../helpers/axiosHelper";
import ViewCustomerDocuments from "./../ViewCustomerDocuments";
import AddressTab from "./NavTabs/AddressTab";
import PersonalDetailsTab from "./NavTabs/PersonalDetailsTab";
import BankDetailsTab from "./NavTabs/BankDetailsTab";
import {
  mobileNumberValidator,
  toastError,
  toastInfo,
  toastSucess,
  toggleMode,
} from "../../../common/data/commonfunctions";
import { left, right } from "../../../common/data/icons";
import { useFormik } from "formik";
import * as Yup from "yup";

const CustomerBeneficiaryForm = (props) => {
  const {
    mode,
    bankData,
    custData,
    addressData,
    customerId,
    UId,
    getBeneficiaryByCustomerId,
  } = props;
  const disabled = mode === toggleMode.view ? true : false;
  let custBenId = bankData?.custBenId !== null ? bankData?.custBenId : null;

  const [state, setState] = useState({
    nationalityOptions: [],
    modal: false,
    activeTab: 1,
    custBenId: null,
    customerRefId: custData?.customerId,
  });

  const navItem = [
    { title: "Personal Details", value: 1 },
    { title: "Bank Details", value: 2 },
    { title: "Address", value: 3 },
    { title: "Documents", value: 4 },
  ];

  const personalValidation = useFormik({
    enableReinitialize: true,

    initialValues: {
      beneficiaryName: bankData?.beneficiaryName || "",
      nationalID: bankData?.nationalID || "",
      dob: bankData?.dob?.split("T")[0] || "",
      email: bankData?.email || "",
      mobile: bankData?.mobile || "",
    },

    validationSchema: Yup.object({
      beneficiaryName: Yup.string().required(
        "Please Enter the Beneficiary Name"
      ),
      nationalID: Yup.string().required("Please Enter the National ID"),
      mobile: Yup.string()
        .required("Please Enter Phone Number")
        .matches(/^[0-9]*$/, "Please enter only numbers")
        .matches(
          new RegExp(
            `^\\+?\\d{${mobileNumberValidator.min},${mobileNumberValidator.max}}$`
          ),
          `Invalid Mobile Number`
        ),
      dob: Yup.date().required("Please Select the Date of Birth"),
      email: Yup.string().email("Invalid Email").required("Please Enter Email"),
    }),
    onSubmit: async (values) => {
      if (custBenId) {
        const hasChanges = Object.keys(values).some(
          (key) => values[key] !== personalValidation.initialValues[key]
        );
        if (values.email === personalValidation.initialValues.email) {
          delete values.email;
        }
        if (values.phone === personalValidation.initialValues.phone) {
          delete values.phone;
        }
        if (!hasChanges) {
          toastInfo("No Changes to Update");
        } else {
          const response = await putApiData(
            `api/CustomerBenMap/UpdateBeneficiary?CustomerId=${custData?.customerId}&CustBenId=${custBenId}&UId=${UId}`,
            JSON.stringify(values)
          );
          if (response?.success) {
            toastSucess("Profile Updated");
            getBeneficiaryByCustomerId(customerId);
            setState((prev) => ({ ...prev, activeTab: state.activeTab + 1 }));
          } else {
            toastError(response?.message);
          }
        }
      } else {
        const response = await postApiData(
          `api/CustomerBenMap/CreateBeneficiary?CustomerId=${customerId}&UId=${UId}`,
          JSON.stringify(values)
        );
        if (response?.success) {
          setState((prev) => ({
            ...prev,
            custBenId: (custBenId = response?.data?.custBenId),
            customerRefId: response?.data?.customerId,
          }));
          toastSucess("Profile Created");
          getBeneficiaryByCustomerId(customerId);
          setState((prev) => ({ ...prev, activeTab: state.activeTab + 1 }));
        } else {
          toastError(response?.message);
        }
      }
    },
  });

  return (
    <Row>
      <Col lg="12">
        <div className="wizard clearfix">
          <div className="steps clearfix">
            <ul>
              {navItem.map((item) => (
                <NavItem
                  key={item.value}
                  className={classnames("p-2 me-1 ", {
                    active:
                      state.activeTab === item.value && mode !== toggleMode.add,
                    //&&  mode !== toggleMode.edit,
                    "bg-light": state.activeTab === item.value,
                  })}
                  style={{ cursor: "pointer" }}
                  onClick={() => {
                    if (mode !== toggleMode.add)
                      // if (mode !== toggleMode.edit)
                      setState((prev) => ({
                        ...prev,
                        activeTab: item.value,
                      }));
                  }}
                >
                  <span className="number">{item.value}</span>
                  {item.title}
                </NavItem>
              ))}
            </ul>
          </div>
          <div className="content clearfix">
            <TabContent activeTab={state.activeTab} className="body">
              <TabPane tabId={1}>
                <PersonalDetailsTab
                  activeTab={state.activeTab}
                  setState={setState}
                  disabled={disabled}
                  personalData={custData}
                  personalValidation={personalValidation}
                />
              </TabPane>
              <TabPane tabId={2}>
                <BankDetailsTab
                  activeTab={state.activeTab}
                  setState={setState}
                  disabled={disabled}
                  bankData={bankData}
                  customerId={customerId}
                  getBeneficiaryByCustomerId={getBeneficiaryByCustomerId}
                  UId={UId}
                  custBenId={state.custBenId ?? custBenId}
                />
              </TabPane>
              <TabPane tabId={3}>
                <AddressTab
                  activeTab={state.activeTab}
                  setState={setState}
                  disabled={disabled}
                  addressData={addressData}
                  customerId={customerId}
                  getBeneficiaryByCustomerId={getBeneficiaryByCustomerId}
                  custBenId={state.custBenId ?? custBenId}
                />
              </TabPane>
              <TabPane tabId={4}>
                <ViewCustomerDocuments
                  custBenId={custBenId ?? state.custBenId}
                />
              </TabPane>
            </TabContent>
            {/* <div className="text-end">
              <Button
                color="secondary"
                className="me-1"
                disabled={state.activeTab === 1 ? true : false}
                onClick={() => {
                  setState((prev) => ({
                    ...prev,
                    activeTab: state.activeTab - 1,
                  }));
                }}
              >
                {left()}
              </Button>

              <Button
                color="secondary"
                className="me-1"
                disabled={state.activeTab === 4 ? true : false}
                onClick={() => {
                  setState((prev) => ({
                    ...prev,
                    activeTab: state.activeTab + 1,
                  }));
                }}
              >
                {right()}
              </Button>
            </div> */}
          </div>
        </div>
      </Col>
    </Row>
  );
};

export default React.memo(CustomerBeneficiaryForm);
