const SelectStyle = {
    menu: (provided, state) => ({
      ...provided,
      backgroundColor: "white",
      maxHeight: 'auto',
    }),
    control: (provided, state) => ({
      ...provided,
      borderColor: state.selectProps.invalid ? 'red' : provided.borderColor, 
    }),
  };

export default SelectStyle