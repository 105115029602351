import React from "react";
import {
  Button,
  Col,
  Form,
  FormGroup,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
  FormFeedback,
} from "reactstrap";
import { useFormik } from "formik";
import * as Yup from "yup";
import { postApiData } from "../../../../helpers/axiosHelper";
import { ToastContainer } from "react-toastify";
import RequiredAsterisk from "../../../../components/Common/RequiredAsterisk";
import ReactSelect from "react-select";
import SelectStyle from "../../../../common/data/SelectStyle";
import { roleList } from "../../../../common/data/roles";
import {
  mobileNumberValidator,
  toastError,
  toastSucess,
} from "../../../../common/data/commonfunctions";
import ButtonSpinner from "../../../../components/Common/ButtonSpinner";

const AddSystemUser = (props) => {
  const { isOpen, toggle, branch, getAllSystemUser } = props;

  const addNewSystemUserValidation = useFormik({
    enableReinitialize: true,

    initialValues: {
      firstName: "",
      middleName: "",
      lastName: "",
      userName: "",
      mobile: "",
      role: null,
      branchId: null,
      password: "",
      confirmpassword: "",
    },

    validationSchema: Yup.object({
      firstName: Yup.string()
        .matches(/^[A-Za-z\s]+$/, "First Name should contain only letters")
        .required("Please Enter First name"),
      middleName: Yup.string().matches(
        /^[A-Za-z\s]+$/,
        "Middle Name should contain only letters"
      ),
      lastName: Yup.string()
        .matches(/^[A-Za-z\s]+$/, "Last Name should contain only letters")
        .required("Please Enter Last name"),
      userName: Yup.string()
        .email("Invalid Email")
        .required("Please Enter Email"),
      mobile: Yup.string()
        .required("Please Enter Mobile Number")
        .matches(/^[0-9]*$/, "Please enter only numbers")
        .matches(
          new RegExp(
            `^\\+?\\d{${mobileNumberValidator.min},${mobileNumberValidator.max}}$`
          ),
          `Invalid Mobile Number`
        ),
      branchId: Yup.object().shape({
        label: Yup.string().required("Please Select a Branch"),
        value: Yup.string().required("Please Select a Branch"),
      }),
      role: Yup.object().shape({
        label: Yup.string().required("Please Select a Role"),
        value: Yup.string().required("Please Select a Role"),
      }),
      password: Yup.string()
        .required("Please Enter Password")
        .matches(
          /^(?=.*\d)(?=.*[A-Z])(?=.*\W).{8,}$/,
          "Password must be at least 8 characters and must contain at least one digit, one uppercase letter, and one special character"
        ),
      confirmpassword: Yup.string()
        .required("Please Enter Password to Confirm")
        .oneOf([Yup.ref("password"), null], "Passwords must match")
        .required("Confirm password is required"),
    }),

    onSubmit: async (values, { resetForm }) => {
      const branchValue = values.branchId && values.branchId.value;
      const roleValue = values.role && values.role.value;
      const combinedValues = {
        ...values,
        email: values.userName,
        branchId: branchValue,
        role: roleValue,
      };
      const response = await postApiData(
        "api/User/CreateSystemUser",
        JSON.stringify(combinedValues)
      );
      if (response?.success) {
        toastSucess("System User Created Successfully");
        toggle();
        resetForm();
        getAllSystemUser();
      } else {
        toastError(response?.message);
      }
    },
  });

  return (
    <React.Fragment>
      <Modal
        isOpen={isOpen}
        role="dialog"
        autoFocus={true}
        centered={true}
        className="exampleModal"
        tabIndex="-1"
        toggle={toggle}
        onClosed={() => {
          addNewSystemUserValidation.resetForm();
        }}
      >
        <div className="modal-content">
          <ModalHeader
            toggle={() => {
              toggle();
              addNewSystemUserValidation.resetForm();
            }}
          >
            Add System User
          </ModalHeader>
          <ModalBody>
            <Form
              className="needs-validation"
              onSubmit={(e) => {
                e.preventDefault();
                addNewSystemUserValidation.handleSubmit();
                return false;
              }}
            >
              <Row>
                <Col md="6">
                  <FormGroup className="mb-3">
                    <Label htmlFor="firstName">First name</Label>
                    <RequiredAsterisk />
                    <Input
                      name="firstName"
                      placeholder="First name"
                      type="text"
                      id="firstName"
                      value={addNewSystemUserValidation.values.firstName}
                      onChange={addNewSystemUserValidation.handleChange}
                      onBlur={addNewSystemUserValidation.handleBlur}
                      invalid={
                        addNewSystemUserValidation.touched.firstName &&
                        addNewSystemUserValidation.errors.firstName
                          ? true
                          : false
                      }
                    />
                    {addNewSystemUserValidation.touched.firstName &&
                    addNewSystemUserValidation.errors.firstName ? (
                      <FormFeedback type="invalid">
                        {addNewSystemUserValidation.errors.firstName}
                      </FormFeedback>
                    ) : null}
                  </FormGroup>
                </Col>
                <Col md="6">
                  <FormGroup className="mb-3">
                    <Label htmlFor="middleName">Middle Name</Label>
                    <Input
                      name="middleName"
                      placeholder="Enter Middle Name"
                      id="middleName"
                      value={addNewSystemUserValidation.values.middleName}
                      onChange={addNewSystemUserValidation.handleChange}
                    />
                  </FormGroup>
                </Col>
                <Col md="6">
                  <FormGroup className="mb-3">
                    <Label htmlFor="lastName">Last name</Label>
                    <RequiredAsterisk />
                    <Input
                      name="lastName"
                      placeholder="Enter Last name"
                      type="text"
                      id="lastName"
                      value={addNewSystemUserValidation.values.lastName}
                      onChange={addNewSystemUserValidation.handleChange}
                      onBlur={addNewSystemUserValidation.handleBlur}
                      invalid={
                        addNewSystemUserValidation.touched.lastName &&
                        addNewSystemUserValidation.errors.lastName
                          ? true
                          : false
                      }
                    />
                    {addNewSystemUserValidation.touched.lastName &&
                    addNewSystemUserValidation.errors.lastName ? (
                      <FormFeedback type="invalid">
                        {addNewSystemUserValidation.errors.lastName}
                      </FormFeedback>
                    ) : null}
                  </FormGroup>
                </Col>
                <Col md="6">
                  <FormGroup className="mb-3">
                    <Label htmlFor="userName">Email</Label>
                    <RequiredAsterisk />
                    <Input
                      name="userName"
                      placeholder="Enter Email"
                      type="email"
                      id="userName"
                      value={addNewSystemUserValidation.values.userName}
                      onChange={addNewSystemUserValidation.handleChange}
                      onBlur={addNewSystemUserValidation.handleBlur}
                      invalid={
                        addNewSystemUserValidation.touched.userName &&
                        addNewSystemUserValidation.errors.userName
                          ? true
                          : false
                      }
                    />
                    {addNewSystemUserValidation.touched.userName &&
                    addNewSystemUserValidation.errors.userName ? (
                      <FormFeedback type="invalid">
                        {addNewSystemUserValidation.errors.userName}
                      </FormFeedback>
                    ) : null}
                  </FormGroup>
                </Col>
                <Col md="6">
                  <FormGroup className="mb-3">
                    <Label htmlFor="mobile">Mobile</Label>
                    <RequiredAsterisk />
                    <Input
                      name="mobile"
                      placeholder="Enter Mobile"
                      type="text"
                      id="mobile"
                      value={addNewSystemUserValidation.values.mobile}
                      onChange={addNewSystemUserValidation.handleChange}
                      onBlur={addNewSystemUserValidation.handleBlur}
                      invalid={
                        addNewSystemUserValidation.touched.mobile &&
                        addNewSystemUserValidation.errors.mobile
                          ? true
                          : false
                      }
                    />
                    {addNewSystemUserValidation.touched.mobile &&
                    addNewSystemUserValidation.errors.mobile ? (
                      <FormFeedback type="invalid">
                        {addNewSystemUserValidation.errors.mobile}
                      </FormFeedback>
                    ) : null}
                  </FormGroup>
                </Col>
                <Col md="6">
                  <FormGroup className="mb-3">
                    <Label htmlFor="role">Role</Label>
                    <RequiredAsterisk />
                    <ReactSelect
                      name="role"
                      placeholder="Select Role"
                      id="role"
                      options={roleList}
                      styles={SelectStyle}
                      value={addNewSystemUserValidation.values.role}
                      onChange={(selectedOption) => {
                        addNewSystemUserValidation.setFieldValue(
                          "role",
                          selectedOption
                        );
                      }}
                      invalid={
                        addNewSystemUserValidation.touched.role &&
                        addNewSystemUserValidation.errors.role
                          ? true
                          : false
                      }
                    />
                    {addNewSystemUserValidation.values.role === null &&
                      addNewSystemUserValidation.touched.role &&
                      addNewSystemUserValidation.errors.role && (
                        <span
                          className="text-danger"
                          style={{ fontSize: "80%" }}
                        >
                          Please Select a Role
                        </span>
                      )}
                  </FormGroup>
                </Col>
                <Col md="6">
                  <FormGroup className="mb-3">
                    <Label htmlFor="branchId">Branch</Label>
                    <RequiredAsterisk />
                    <ReactSelect
                      name="branchId"
                      placeholder="Select Branch"
                      options={branch}
                      styles={SelectStyle}
                      id="branchId"
                      value={addNewSystemUserValidation.values.branchId}
                      onChange={(selectedOption) => {
                        addNewSystemUserValidation.setFieldValue(
                          "branchId",
                          selectedOption
                        );
                      }}
                      invalid={
                        addNewSystemUserValidation.touched.branchId &&
                        addNewSystemUserValidation.errors.branchId
                          ? true
                          : false
                      }
                    />
                    {addNewSystemUserValidation.values.branchId === null &&
                      addNewSystemUserValidation.touched.branchId &&
                      addNewSystemUserValidation.errors.branchId && (
                        <span
                          className="text-danger"
                          style={{ fontSize: "80%" }}
                        >
                          Please Select a Branch
                        </span>
                      )}
                  </FormGroup>
                </Col>
                <Col md="6">
                  <FormGroup className="mb-3">
                    <Label htmlFor="password">Password</Label>
                    <RequiredAsterisk />
                    <Input
                      name="password"
                      placeholder="Enter Password"
                      type="password"
                      id="password"
                      value={addNewSystemUserValidation.values.password}
                      onChange={addNewSystemUserValidation.handleChange}
                      onBlur={addNewSystemUserValidation.handleBlur}
                      invalid={
                        addNewSystemUserValidation.touched.password &&
                        addNewSystemUserValidation.errors.password
                          ? true
                          : false
                      }
                    />
                    {addNewSystemUserValidation.touched.password &&
                    addNewSystemUserValidation.errors.password ? (
                      <FormFeedback type="invalid">
                        {addNewSystemUserValidation.errors.password}
                      </FormFeedback>
                    ) : null}
                  </FormGroup>
                </Col>
                <Col md="6">
                  <FormGroup className="mb-3">
                    <Label htmlFor="confirmpassword">Confirm Password</Label>
                    <RequiredAsterisk />
                    <Input
                      name="confirmpassword"
                      placeholder="Confirm Password"
                      type="password"
                      id="confirmpassword"
                      value={addNewSystemUserValidation.values.confirmpassword}
                      onChange={addNewSystemUserValidation.handleChange}
                      onBlur={addNewSystemUserValidation.handleBlur}
                      invalid={
                        addNewSystemUserValidation.touched.confirmpassword &&
                        addNewSystemUserValidation.errors.confirmpassword
                          ? true
                          : false
                      }
                    />
                    {addNewSystemUserValidation.touched.confirmpassword &&
                    addNewSystemUserValidation.errors.confirmpassword ? (
                      <FormFeedback type="invalid">
                        {addNewSystemUserValidation.errors.confirmpassword}
                      </FormFeedback>
                    ) : null}
                  </FormGroup>
                </Col>
              </Row>
            </Form>
          </ModalBody>
          <ModalFooter>
            <Button
              color="primary"
              type="submit"
              onClick={addNewSystemUserValidation.handleSubmit}
              disabled={addNewSystemUserValidation.isSubmitting}
            >
              {addNewSystemUserValidation.isSubmitting ? (
                <ButtonSpinner />
              ) : (
                "Submit"
              )}
            </Button>
          </ModalFooter>
        </div>
      </Modal>
      <ToastContainer closeButton={false} limit={1} />
    </React.Fragment>
  );
};

export default AddSystemUser;
