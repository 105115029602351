import React, { useEffect, useMemo, useState } from "react";
import {
  Card,
  CardBody,
  CardTitle,
  NavLink,
  NavItem,
  Nav,
  TabContent,
  TabPane,
} from "reactstrap";
import classnames from "classnames";
import Loader from "../../../components/Common/Loader";
import { useFormik } from "formik";
import * as Yup from "yup";
import { getApiData, postApiData } from "../../../helpers/axiosHelper";
import { toastError, useCompany } from "../../../common/data/commonfunctions";
import { ToastContainer, toast } from "react-toastify";
import { useLatestTransactions } from "../../../common/data/latest-transaction-context";
import Swal from "sweetalert2";
import BookingForm from "./BookingForm";
import { TransactionType } from "../../../common/data/transactionType";
import CustomLoader from "../../../components/Common/CustomLoader";
import TransactionHistory from "../TransactionHistory";
import ReceiptPDF from "../Receipt";
import ViewLatestTransactionModel from "../../Dashboard_Admin/ViewTransactionDetailsDashboard";
import { debounce, values } from "lodash";
import {
  baseCurrency,
  getCurrencyFlag,
  rateDigits,
} from "../../../common/data/currency";
import { helpDocUrl } from "../../HelpDocs/HelpDocUrl";
import { HelpDocButton } from "../../HelpDocs/HelpDocItems";
import RefreshButton from "../../../common/data/RefreshButton";

const Booking = (props) => {
  const {
    isLoading,
    setIsLoading,
    getAgentTransaction,
    getTransaction,
    activeTab,
    title,
  } = props;

  if (title) {
    document.title = title;
  }
  const { allCurrencies } = useLatestTransactions();
  const { companyOptions, getAllCompany } = useCompany();
  const { branchId } = useLatestTransactions();
  const [customActiveTab, setcustomActiveTab] = useState(2);
  const [currencyValue, setCurrencyValue] = useState("");

  const [showModal, setShowModal] = useState(false);
  const [receiptData, setReceiptData] = useState({});
  const [triggerDownload, setTriggerDownload] = useState(false);
  const [commentData, setCommentData] = useState([]);
  const [modal1, setModal1] = useState(false);
  const [sendOpTimeTransactionDetails, setSendOpTimeTransactiondetails] =
    useState(null);
  const [sendTransactionDetails, setSendTransactiondetails] = useState(null);
  const [sendBranchDetails, setSendBranchDetails] = useState(null);
  const toggleViewModal = () => setModal1(!modal1);
  const [isConverting, setIsConverting] = useState(false);
  const [transactionID, setTransactionID] = useState(null);

  const handleShow = (data, download) => {
    setTransactionID(data);
    setTriggerDownload(download);
    setShowModal(true);
  };

  const handleClose = () => {
    setShowModal(false);
    setTriggerDownload(false);
  };

  const [state, setState] = useState({
    agentOptions: [],
    currencyListBuy: [],
    currencyListSell: [],
    currencyListTransfer: [],
    fromCurrTypeStock: "",
    toCurrTypeStock: "",
    submitting: false,
    beneficiaryOptions: [],
    currencies: [],
    agentBeneficiaryOptions: [],
    amtErr: null,
    negativeError: null,
  });

  const navs = [
    { label: "Sell", value: 1 },
    { label: "Buy", value: 2 },
    { label: "Transfer", value: 3 },
  ];

  const handleLimitExceeds = (values) => {
    Swal.fire({
      text: `Transaction amount exceeds the limit of 5000 and cannot be processed ${
        values.userType === "On Counter"
          ? ""
          : `, since the ${values.userType} is not Approved`
      }`,
      icon: "info",
      showCancelButton: true,
      showConfirmButton: false,
      cancelButtonText: "Close",
    });
    return;
  };

  const getAgentsByCompany = async (companyId) => {
    const response = await getApiData(
      `api/AgentUser/GetAgentsViewByCompany?companyId=${companyId}`
    );

    if (response?.data?.length === 0) {
      toast.error("No Agents Found for the Selected Company", {
        position: "top-right",
        autoClose: 2000,
        closeButton: false,
      });
      setState((prevState) => ({ ...prevState, agentOptions: [] }));
      return;
    }

    const filteredAgents = response?.data?.filter((e) => e.active === 1);
    const mappedResponse = filteredAgents?.map((e) => ({
      label: `${e.firstName} ${e.middleName !== null ? e.middleName : ""} ${
        e.lastName
      }`,
      value: e.agentUid,
      userStatus: e.userStatus,
      companyId: e.agentCompanyId,
    }));

    setState((prevState) => ({ ...prevState, agentOptions: mappedResponse }));
  };

  const getAllActiveCurrency = async () => {
    const response = await getApiData(
      `api/RateMaster/GetActiveRatesByBranch?BranchID=${branchId}`
    );
    if (response?.success === true) {
      const mappedResponse = response?.data.map((item, index) => ({
        currencyName: item.currencyName,
        label: item.currencyCode,
        value: index + 1,
        currencyValue: item.value,
        currencyId: item.currencyId,
        rateType: item.rateType,
        wireTransferFee: item.wireTransferFee,
        agentCharges: item.agentCharges,
        commissionCharges: item.commissionCharges,
        worldRate: item.worldRate,
        totalFees: item.totalFeesAdmin,
        totalFeesAgent: item.totalFeesAgent,
        transferRate: item.transferRate,
        rateHistoryId: item.rateHistoryId,
        currencyFlag: getCurrencyFlag(allCurrencies, item.currencyCode),
        buyRateMainBranch: item.buyRateMainBranch,
        buyRateHistoryIdMainBranch: item.buyRateHistoryIdMainBranch,
      }));

      const filteredBuy = mappedResponse.filter((item) => item.rateType === 1);
      const filteredSell = mappedResponse.filter((item) => item.rateType === 2);
      const filteredTransfer = mappedResponse.filter(
        (item) => item.rateType === 3
      );
      setState((prevState) => ({
        ...prevState,
        currencyListBuy: filteredBuy,
        currencyListSell: filteredSell,
        currencyListTransfer: filteredTransfer,
        currencies: mappedResponse,
      }));
      setCurrencyValue(mappedResponse[0]?.currencyValue);
    } else if (response?.success === false) {
      toast.error(`${response?.message}`, {
        position: "top-right",
        autoClose: 2000,
        closeButton: false,
      });
    } else {
      toast.error(`No Active Currencies Found`, {
        position: "top-right",
        autoClose: 2000,
        closeButton: false,
      });
    }
  };

  const getStockByBranch = async () => {
    if (
      !addTransactionValidation.values.fromCurrType ||
      !addTransactionValidation.values.toCurrType
    ) {
      return;
    }

    const response = await getApiData(
      `api/Stock/GetStockByBranchId?branchId=${branchId}`
    );

    if (response?.success === true) {
      const fromCurr =
        addTransactionValidation.values.fromCurrType.label.split(" - ")[0];
      const toCurr =
        addTransactionValidation.values.toCurrType.label.split(" - ")[0];

      const fromStock = response?.data?.find(
        (item) => item.currencyCode === fromCurr
      );
      const toStock = response?.data?.find(
        (item) => item.currencyCode === toCurr
      );
      const fromCurrFlag = getCurrencyFlag(allCurrencies, fromCurr);
      const toCurrFlag = getCurrencyFlag(allCurrencies, toCurr);
      setState((prevState) => ({
        ...prevState,
        fromCurrTypeFlag: fromCurrFlag || null,
        toCurrTypeFlag: toCurrFlag || null,
      }));
      setState((prevState) => ({
        ...prevState,
        fromCurrTypeStock: fromStock ? fromStock.availableAmount : "",
        toCurrTypeStock: toStock ? toStock.availableAmount : "",
      }));
    } else {
      toast.error(`${response?.message}`, {
        position: "top-right",
        autoClose: 2000,
        closeButton: false,
      });
    }
  };

  const findCurrValue = (currency = String, rateType = Number) => {
    if (customActiveTab === 2) {
      return parseFloat(
        state.currencies?.find(
          (x) => x.label === currency && x.rateType === rateType
        )?.currencyValue
      );
    } else if (customActiveTab === 1) {
      return parseFloat(
        state.currencies?.find(
          (x) => x.currencyCode === currency && x.rateType === rateType
        )?.currencyValue
      );
    } else if (customActiveTab === TransactionType.Transfer) {
      return parseFloat(
        state.currencies?.find(
          (x) => x.currencyCode === currency && x.rateType === rateType
        )?.currencyValue
      );
    }
  };

  const SGD = {
    label: "SGD",
    value: "SGD",
    currencyValue: findCurrValue("SGD", customActiveTab),
  };

  const findBuyRateHistoryIdMainBranch = (currency) => {
    return state.currencyListBuy?.find((c) => c.label === currency)
      ?.buyRateHistoryIdMainBranch;
  };

  const addTransactionValidation = useFormik({
    enableReinitialize: true,

    initialValues: {
      transactionType:
        customActiveTab === 1
          ? 2
          : customActiveTab === 2
          ? 1
          : customActiveTab === 3
          ? 3
          : null,
      fromCurrType: null,
      toCurrType: customActiveTab === 3 ? null : SGD,
      fromCurrValue: "",
      toCurrValue: "",
      userType: customActiveTab === 3 ? "Customer" : "Customer",
      fromCustomerId: null,
      toCustBenId: null,
      agentCompany: null,
      agentId: null,
      toCompanyBenId: null,
      discountedRate: "",
      profitLossSelling: null,
      agentCharges: null,
      exchangeRate: null,
      fromRateHistoryId: null,
      toRateHistoryId: null,
      buyRateHistoryIdMainBranch: null,
    },

    validationSchema: Yup.object({
      fromCurrType: Yup.object().shape({
        label: Yup.string().required("Please Select a Currency"),
        value: Yup.string().required("Please Select a Currency"),
      }),
      toCurrType: Yup.object().shape({
        label: Yup.string().required("Please Select a Currency"),
        value: Yup.string().required("Please Select a Currency"),
      }),
      fromCurrValue: Yup.number()
        .typeError("Please Enter Only Numbers")
        .min(0, "Should be greater than Zero")
        .required("Amount is Required"),
      userType: Yup.string().required("User Type is Required"),
    }),

    onSubmit: async (values, { resetForm }) => {
      const selectedFromCurr = values.fromCurrType?.label;
      const selectedToCurr = values.toCurrType?.label;
      const selectedCustomerId = values.fromCustomerId?.value;
      const selectedtoCustomerId = values.toCustBenId?.custBenId;
      const selectedAgentId = values.agentId?.value;
      const selectedToCompanyBenId = values.toCompanyBenId?.value;

      if (selectedFromCurr === selectedToCurr) {
        toast.error("Cannot exchange same currency.", {
          position: "top-right",
          autoClose: 3000,
        });
        return;
      }

      let currencyData;
      let profit = 0;
      let totalCharges = null;

      const combinedValues = {
        ...values,
        fromCurrType: selectedFromCurr,
        toCurrType: selectedToCurr,
        fromCustomerId: selectedCustomerId,
        agentId: selectedAgentId,
        toCompanyBenId: selectedToCompanyBenId,
        fromBranchId: branchId,
        toCustBenId: selectedtoCustomerId,
        discountedRate: parseFloat(values.discountedRate),
        charges: totalCharges,
        profitLossSelling: profit,
        exchangeRate: currencyRate,
      };

      if (
        combinedValues.transactionType === TransactionType.Transfer &&
        combinedValues.fromCurrType != baseCurrency &&
        combinedValues.toCurrType != baseCurrency
      ) {
        combinedValues.buyRateHistoryIdMainBranch =
          findBuyRateHistoryIdMainBranch(combinedValues.fromCurrType);
      }

      if (customActiveTab === 1) {
        combinedValues.toCurrType = selectedFromCurr;
        combinedValues.fromCurrType = selectedToCurr;
      }

      if (customActiveTab === TransactionType.Transfer) {
        currencyData = state.currencyListTransfer.find(
          (x) => x.label === combinedValues.fromCurrType
        );

        const mul =
          parseFloat(currencyData?.transferRate) *
          parseFloat(combinedValues.fromCurrValue);

        profit = mul + parseFloat(currencyData?.commissionCharges);
      } else if (customActiveTab === 2) {
        currencyData = state.currencyListBuy.find(
          (x) => x.label === combinedValues.fromCurrType
        );
      } else if (customActiveTab === 1) {
        currencyData = state.currencyListSell.find(
          (x) => x.label === combinedValues.fromCurrType
        );
      }

      totalCharges =
        (!isNaN(parseFloat(currencyData?.commissionCharges))
          ? parseFloat(currencyData?.commissionCharges)
          : 0) +
        (!isNaN(parseFloat(currencyData?.agentCharges))
          ? parseFloat(currencyData?.agentCharges)
          : 0) +
        (!isNaN(parseFloat(currencyData?.wireTransferFee))
          ? parseFloat(currencyData?.wireTransferFee)
          : 0);

      combinedValues.profitLossSelling = profit;
      combinedValues.charges = totalCharges;

      if (values.userType === "Customer") {
        delete combinedValues.agentId;
        delete combinedValues.agentCompany;
        delete combinedValues.userType;
        if (
          combinedValues.fromCustomerId === 0 ||
          combinedValues.fromCustomerId === null ||
          combinedValues.fromCustomerId === undefined
        ) {
          toast.error("Invalid Customer", {
            position: "top-right",
            autoClose: 2000,
            closeButton: false,
          });
          return;
        }
      } else if (values.userType === "Agent") {
        delete combinedValues.fromCustomerId;
        delete combinedValues.agentCompany;
        delete combinedValues.userType;
        if (
          combinedValues.agentId === 0 ||
          combinedValues.agentId === null ||
          combinedValues.agentId === undefined
        ) {
          toast.error("Invalid Agent", {
            position: "top-right",
            autoClose: 2000,
            closeButton: false,
          });
          return;
        }
      }

      if (
        values.fromCustomerId?.userStatus === 5 ||
        values.agentId?.userStatus === 5
      ) {
        toast.info("User is inactive", {
          position: "top-right",
          autoClose: 2000,
          closeButton: false,
        });
        return;
      }

      if (
        combinedValues.transactionType === TransactionType.Buy
          ? combinedValues.toCurrType === "SGD" &&
            parseFloat(combinedValues.toCurrValue) > 5000
          : combinedValues.transactionType === TransactionType.Sell
          ? combinedValues.fromCurrType === "SGD" &&
            parseFloat(combinedValues.fromCurrValue) > 5000
          : false
      ) {
        if (values.userType === "Customer") {
          if (![4, 5].includes(values.fromCustomerId?.userStatus)) {
            handleLimitExceeds(values);
            return;
          }
        } else if (values.userType === "Agent") {
          if (![4, 5].includes(values.agentId?.userStatus)) {
            handleLimitExceeds(values);
            return;
          }
        } else if (values.userType === "On Counter") {
          handleLimitExceeds(values);
          return;
        }
      }

      if (
        customActiveTab === 1 &&
        parseFloat(combinedValues.toCurrValue) > state.fromCurrTypeStock
      ) {
        toastError("Insufficient Stock");
        return;
      }

      try {
        setState((prevState) => ({ ...prevState, submitting: true }));
        const response = await postApiData(
          `api/Transaction/InsertTransaction`,
          combinedValues
        );

        if (response?.success === true) {
          toast.success(`${response?.message}` || "Transaction Successful", {
            position: "top-right",
            autoClose: 2000,
            closeButton: false,
          });
          if (values.userType === "Customer") {
            ViewTransactionDetails(response?.data?.transactionId);
          }
          if (values.userType === "Agent") {
            ViewTransactionDetails(response?.data?.transactionId);
          }
          if (values.userType === "On Counter") {
            handleShow(response?.data?.transactionId, true);
          }

          if (activeTab === 1) {
            getTransaction();
          } else if (activeTab === 2) {
            getAgentTransaction();
          }
          resetForm();
          setState((prevState) => ({
            ...prevState,
            fromCurrTypeStock: "",
            toCurrTypeStock: "",
            agentOptions: [],
            customerList: [],
          }));
        } else {
          toast.error(`${response?.message}`, {
            position: "top-right",
            autoClose: 2000,
            closeButton: false,
          });
        }
      } catch (err) {
        toast.error("Error" + err, {
          position: "top-right",
          autoClose: 2000,
          closeButton: false,
        });
      } finally {
        setState((prevState) => ({ ...prevState, submitting: false }));
      }
      setState((prevState) => ({ ...prevState, submitting: false }));
    },
  });

  const toggleCustom = (tab) => {
    if (customActiveTab !== tab) {
      setcustomActiveTab(tab);
      addTransactionValidation.setValues(
        addTransactionValidation.initialValues
      );
    }
  };

  const ViewTransactionDetails = (transactionId) => {
    const getTransactionbyId = async () => {
      try {
        setIsLoading(true);
        const response = await getApiData(
          `api/Transaction/GetTransaction?TransactionID=${transactionId}`
        );
        const opdataResponse = await getApiData(
          `api/BranchMaster/GetBranchWithOperation?branchId=${branchId}`
        );
        setIsLoading(false);
        const commentData = response?.comments;
        setSendTransactiondetails(response);
        setCommentData(commentData);
        setSendOpTimeTransactiondetails(opdataResponse?.data?.operationalTime);
        setSendBranchDetails(opdataResponse?.data?.branch);
      } catch (error) {
        toast.error(error, {
          position: "top-right",
          autoClose: 3000,
        });
      }
    };
    getTransactionbyId();
    toggleViewModal();
  };
  const handleUserType = (e) => {
    addTransactionValidation.setValues((prevState) => ({
      ...prevState,
      agentCompany: null,
      agentId: null,
      fromCustomerId: null,
    }));
    setState((prevState) => ({ ...prevState, agentOptions: [] }));
    addTransactionValidation.setFieldValue(
      "userType",
      customActiveTab !== 3 ? e.target.value : "Customer"
    );
    if (customActiveTab === 1 || customActiveTab === 2) {
      addTransactionValidation.setFieldValue("fromCurrValue", null);
      addTransactionValidation.setFieldValue("toCurrValue", null);
      addTransactionValidation.setFieldValue("fromCurrType", null);
    }
  };

  useEffect(() => {
    getAllCompany();
  }, []);

  useEffect(() => {
    if (branchId !== null && branchId !== undefined) {
      getStockByBranch();
    }
  }, [
    addTransactionValidation.values.fromCurrType,
    addTransactionValidation.values.toCurrType,
  ]);

  useEffect(() => {
    if (branchId !== null && branchId !== undefined) {
      getAllActiveCurrency();
      getStockByBranch();
    }
    addTransactionValidation.resetForm();
    setState((prevState) => ({
      ...prevState,
      agentOptions: [],
      fromCurrTypeStock: "",
      toCurrTypeStock: "",
    }));
  }, [branchId]);

  const findCurrRate = (currency, rateType) => {
    switch (rateType) {
      case 1: {
        return parseFloat(
          state.currencyListBuy?.find((c) => c.label === currency)
            ?.currencyValue
        );
      }
      case 2: {
        return parseFloat(
          state.currencyListSell?.find((c) => c.label === currency)
            ?.currencyValue
        );
      }
      case 3: {
        return parseFloat(
          state.currencyListTransfer?.find((c) => c.label === currency)
            ?.currencyValue
        );
      }
    }
  };

  const findMainCurrRate = (currency, rateType) => {
    switch (rateType) {
      case 1: {
        return parseFloat(
          state.currencyListBuy?.find((c) => c.label === currency)
            ?.buyRateMainBranch
        );
      }
      case 2: {
        return parseFloat(
          state.currencyListSell?.find((c) => c.label === currency)
            ?.buyRateMainBranch
        );
      }
      case 3: {
        return parseFloat(
          state.currencyListTransfer?.find((c) => c.label === currency)
            ?.buyRateMainBranch
        );
      }
    }
  };

  const findRate = () => {
    const discountedRate =
      addTransactionValidation.values.discountedRate !== ""
        ? parseFloat(addTransactionValidation.values.discountedRate)
        : null;
    let rate;

    if (customActiveTab == 1 || customActiveTab === 2) {
      rate = discountedRate
        ? discountedRate
        : parseFloat(
            addTransactionValidation.values.fromCurrType?.currencyValue
          ) ||
          parseFloat(addTransactionValidation.values.toCurrType?.currencyValue);
    } else {
      if (addTransactionValidation.values.fromCurrType?.label === SGD.label) {
        rate = discountedRate
          ? discountedRate
          : findCurrRate(
              addTransactionValidation.values.toCurrType?.label,
              TransactionType.Transfer
            );
      } else if (
        addTransactionValidation.values.toCurrType?.label === SGD.label
      ) {
        rate = discountedRate
          ? discountedRate
          : findMainCurrRate(
              addTransactionValidation.values.fromCurrType?.label,
              TransactionType.Buy
            );
      } else {
        const buyRate = findMainCurrRate(
          addTransactionValidation.values.fromCurrType?.label,
          TransactionType.Buy
        );

        const transferRate = findCurrRate(
          addTransactionValidation.values.toCurrType?.label,
          TransactionType.Transfer
        );
        rate = discountedRate ? discountedRate : buyRate / transferRate;
      }
    }

    return rate ?? 0;
  };

  let currencyRate = findRate();

  const renderBookingForm = () => (
    <BookingForm
      addTransactionValidation={addTransactionValidation}
      state={state}
      setState={setState}
      isLoading={isLoading}
      companyOptions={companyOptions}
      getAgentsByCompany={getAgentsByCompany}
      handleUserType={handleUserType}
      customActiveTab={customActiveTab}
      currencyValue={currencyValue}
      setIsConverting={setIsConverting}
      findRate={findRate}
      currencyRate={currencyRate}
      findCurrRate={findCurrRate}
      findMainCurrRate={findMainCurrRate}
    />
  );

  return (
    <React.Fragment>
      <div id="react-select-portal" />
      <ToastContainer closeButton={false} limit={1} />
      <ReceiptPDF
        show={showModal}
        handleClose={handleClose}
        triggerDownload={triggerDownload}
        transactionID={transactionID}
      />

      <ViewLatestTransactionModel
        isOpen={modal1}
        toggle={toggleViewModal}
        sendTransactionDetails={sendTransactionDetails}
        commentData={commentData?.map((comment) => ({
          ...comment,
          userType: comment.userType,
          status: comment.status,
          createdOn: comment.createdOn,
        }))}
        sendOpTimeTransactionDetails={sendOpTimeTransactionDetails}
        sendBranchDetails={sendBranchDetails}
      />
      {isLoading ? (
        <Loader />
      ) : (
        <Card>
          <CardBody>
            <div className="d-flex mb-4">
              <CardTitle className="h4 mb-3">New Transaction</CardTitle>
              {HelpDocButton(helpDocUrl.addTransaction)}
              <div className="ms-auto">
                <RefreshButton onClick={() => getAllActiveCurrency(branchId)} />
              </div>
            </div>
            <Nav tabs className="nav-tabs-custom nav-justified">
              {navs.map((n) => (
                <NavItem key={n.value}>
                  <NavLink
                    style={{ cursor: "pointer" }}
                    className={classnames({
                      active: customActiveTab === n.value,
                    })}
                    onClick={() => {
                      toggleCustom(n.value);
                    }}
                  >
                    <span>{n.label}</span>
                  </NavLink>
                </NavItem>
              ))}
            </Nav>
            <TabContent activeTab={customActiveTab} className="p-3 text-muted">
              <TabPane tabId={2}>{renderBookingForm()}</TabPane>
              <TabPane tabId={1}>{renderBookingForm()}</TabPane>
              <TabPane tabId={3}>{renderBookingForm()}</TabPane>
            </TabContent>
          </CardBody>
        </Card>
      )}
    </React.Fragment>
  );
};

export default Booking;
