import React, { useState, useEffect, useRef } from "react";
import PropTypes from "prop-types";
import {
  Button,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Table,
  Collapse,
  Label,
  Badge,
} from "reactstrap";
import classnames from "classnames";
import SignaturePad from "react-signature-canvas";
import { getApiData, postApiData } from "../../helpers/axiosHelper";
import { ToastContainer, toast } from "react-toastify";
import VIewTransactionBeneficiaryAccordian from "../Transaction/ViewTransaction/VIewTransactionBeneficiaryAccordian";
import { mapStatus } from "../../common/data/StatusLabels";
import { formatDateToUserTimeZone } from "../../common/data/date";
import { TransactionType } from "../../common/data/transactionType";
import CustomLoader from "../../components/Common/CustomLoader";
import { mapTransactionType } from "../../common/data/StatusLabels";
import {
  roundOff,
  toastSucess,
  toastWarn,
} from "../../common/data/commonfunctions";
import Loader from "../../components/Common/Loader";
import { baseCurrency } from "../../common/data/currency";

const days = [
  "All Days",
  "Sunday",
  "Monday",
  "Tuesday",
  "Wednesday",
  "Thursday",
  "Friday",
  "Saturday",
];
function convertTime(timeString) {
  const [hours, minutes, seconds] = timeString.split(":");
  const hoursIn12HrFormat = hours % 12 || 12; // Handle 00:00 as 12 AM
  const amPm = hours >= 12 ? "PM" : "AM";
  const formattedTime = `${hoursIn12HrFormat}:${minutes.padStart(
    2,
    "0"
  )}:${seconds} ${amPm}`;
  return formattedTime;
}

const ViewLatestTransactionModel = (props) => {
  const [col9, setcol9] = useState(true);
  const [col10, setcol10] = useState(false);
  const [col11, setcol11] = useState(false);
  const [col12, setcol12] = useState(false);
  const [col13, setcol13] = useState(false);
  const [isCommentOpen, setIsCommentOpen] = useState(false);
  const [commentText, setCommentText] = useState("");
  const [selectedStatus, setSelectedStatus] = useState(0);
  const [selectedFile, setSelectedFile] = useState(null);
  const [isSignatureEmpty, setIsSignatureEmpty] = useState(true);
  const [fileData, setFileData] = useState(null);
  const [isSign, setIsSign] = useState(null);
  const [isSignatureDrawn, setIsSignatureDrawn] = useState(false);
  const signaturePad = useRef(null);
  const [isFileUploaded, setIsFileUploaded] = useState(false);
  const [showSignaturePad, setShowSignaturePad] = useState(false);
  const [beneficiaryDetails, setBeneficiaryDetails] = useState(null);
  const clearSignature = () => {
    signaturePad.current?.clear();
    setIsSignatureEmpty(true);
  };

  const [charges, setCharges] = useState({
    agentCharges: 0,
    wireTransferFee: 0,
    commissionCharges: 0,
    totalCharges: 0,
  });

  const {
    isOpen,
    toggle,
    sendTransactionDetails,
    commentData,
    sendOpTimeTransactionDetails,
    sendBranchDetails,
    activeTab,
    getAgentTransaction,
    getTransaction,
    setIsLoading,
    isLoading,
  } = props;

  const {
    fromCurrType,
    toCurrType,
    fromCurrValue,
    toCurrValue,
    transactionType,
    fromRatehistory,
    toRatehistory,
    transactionId,
    exchangeRate,
    discountedRate,
    status,
    transactionDate,
    pickup,
    buyRateHistoryMainBranch,
    fromCustomerId,
    sign,
    approvedDate,
    companyId,
    toCompanyBenId,
    toCustBenId,
  } = sendTransactionDetails || {};

  const { street, city, branchName, state, postalCode, country } =
    sendBranchDetails || {};

  const handleAddSignSubmit = () => {
    debugger;
    const signature =
      fileData || signaturePad.current?.toDataURL().split(",")[1];

    if (!signature) {
      toast.error("No document or signature to submit.", {
        position: "top-right",
        autoClose: 3000,
      });
      return;
    }

    const requestBody = {
      transactionId: transactionId,
      sign: signature,
      fileName: transactionId,
      isSign: isFileUploaded ? 0 : 1,
    };
    postApiData("api/TransactionSign/AddSign", requestBody)
      .then((response) => {
        if (response.success) {
          fetchSignature(transactionId);
        } else {
          toast.error("Submission failed", {
            position: "top-right",
            autoClose: 3000,
          });
        }
      })
      .catch((error) => console.error("Error submitting data:", error));
  };

  const resetSign = () => {
    setSelectedFile(null);
    setIsSign(null);
    setFileData(null);
    setIsSignatureEmpty(true);
  };

  const fetchSignature = (transactionId) => {
    getApiData(
      `api/TransactionSign/GetSignByTransactionId?TransactionId=${transactionId}`
    )
      .then((response) => {
        if (response?.success) {
          const signatureData = response.data;
          if (signatureData) {
            setSelectedFile(signatureData.fileName || "");
            setIsSign(signatureData.isSign);
            setFileData(signatureData.sign || "");
            setIsSignatureEmpty(!signatureData.sign);
          } else {
            resetSign();
          }
        } else {
          resetSign();
        }
      })
      .catch((error) => console.error("Error fetching signature:", error));
  };

  const handleFileUpload = (e) => {
    const file = e.target.files[0];
    if (file) {
      if (file.size / (1024 * 1024) > 4) {
        toast.error("File size exceeds 5 MB.", {
          position: "top-right",
          autoClose: 3000,
        });
        return;
      }

      const reader = new FileReader();
      reader.onloadend = () => {
        setSelectedFile(file.name);
        setIsFileUploaded(true);
        setIsSign(0);
        setFileData(reader.result.split(",")[1]);
        setIsSignatureEmpty(true);
      };
      reader.readAsDataURL(file);
    }
  };

  const handleSignatureEnd = () => {
    setIsSignatureDrawn(true);
    setFileData(signaturePad.current?.toDataURL().split(",")[1]);
    setIsSign(1);
  };

  const openFileInNewTab = () => {
    const newWindow = window.open();

    if (newWindow) {
      newWindow.document.write(`
            <html>
                <head>
                    <title>Document Preview</title>
                </head>
                <body>
                    <embed src="data:application/pdf;base64,${fileData}" type="application/pdf" width="100%" height="100%" />
                </body>
            </html>
        `);
      newWindow.document.close();
    } else {
      toast.error(
        "Failed to open document. Please allow popups for this site.",
        { position: "top-right", autoClose: 3000 }
      );
    }
  };

  const renderFileOrSignature = () => {
    if (!fileData && isSign === null) {
      return (
        <div style={{ textAlign: "center" }}>
          <p>No document and signature</p>
          <i
            className="fas fa-file"
            style={{ fontSize: "8rem", display: "block", margin: "0 auto" }}
          />
        </div>
      );
    } else if (isSign === 0) {
      return (
        <div onClick={openFileInNewTab} style={{ cursor: "pointer" }}>
          <i className="fas fa-file-pdf" style={{ fontSize: "8rem" }} />
        </div>
      );
    } else if (fileData && isSign === 1) {
      return (
        <img
          src={`data:image/png;base64,${fileData}`}
          alt="Signature"
          style={{ width: "150px", height: "50px" }}
        />
      );
    }
  };

  const t_col9 = () => {
    setcol9(!col9);
    setcol10(false);
    setcol11(false);
    setcol12(false);
    setcol13(false);
  };

  const t_col10 = () => {
    setcol10(!col10);
    setcol9(false);
    setcol11(false);
    setcol12(false);
    setcol13(false);
  };

  const t_col11 = () => {
    setcol11(!col11);
    setcol10(false);
    setcol9(false);
    setcol12(false);
    setcol13(false);
  };

  const t_col12 = () => {
    setcol12(!col12);
    setcol10(false);
    setcol9(false);
    setcol11(false);
    setcol13(false);
  };

  const t_col13 = () => {
    setcol13(!col13);
    setcol10(false);
    setcol9(false);
    setcol11(false);
    setcol12(false);
  };

  const transfer1stScenario =
    transactionType === TransactionType.Transfer &&
    fromCurrType === baseCurrency &&
    toCurrType !== baseCurrency;

  const transfer2ndScenario =
    transactionType === TransactionType.Transfer &&
    fromCurrType !== baseCurrency &&
    toCurrType === baseCurrency;

  const transfer3rdScenario =
    transactionType === TransactionType.Transfer &&
    fromCurrType !== baseCurrency &&
    toCurrType !== baseCurrency;

  let fromCurrCharges =
    transactionType === TransactionType.Buy || transfer2ndScenario
      ? fromRatehistory
      : toRatehistory;

  const parseFloatCharges = (value) => {
    return parseFloat(value);
  };

  const displayChargesForTransfer3rdScenario = (charge) => {
    return parseFloat(charge) / parseFloat(buyRateHistoryMainBranch?.value);
  };

  const displayChargesWithCurrency = (charge) => {
    return (
      roundOff(charge) +
      " " +
      (transfer1stScenario || transfer2ndScenario
        ? baseCurrency
        : fromCurrCharges?.currencyCode)
    );
  };

  const calculateCharges = () => {
    if (
      transactionType === TransactionType.Buy ||
      transactionType === TransactionType.Sell
    ) {
      setCharges((prev) => ({
        ...prev,
        agentCharges: parseFloatCharges(fromCurrCharges?.agentCharges),
        totalCharges: parseFloatCharges(fromCurrCharges?.agentCharges),
      }));
    } else if (transfer1stScenario || transfer2ndScenario) {
      const commissionCharges = parseFloatCharges(
        fromCurrCharges?.commissionCharges
      );
      const wireTransferFee = parseFloatCharges(
        fromCurrCharges?.wireTransferFee
      );
      const agentCharges = parseFloatCharges(fromCurrCharges?.agentCharges);

      setCharges((prev) => ({
        ...prev,
        commissionCharges,
        wireTransferFee,
        agentCharges,
        totalCharges: agentCharges + wireTransferFee + commissionCharges,
      }));
    } else if (transfer3rdScenario) {
      const commissionCharges = displayChargesForTransfer3rdScenario(
        fromCurrCharges?.commissionCharges
      );
      const wireTransferFee = displayChargesForTransfer3rdScenario(
        fromCurrCharges?.wireTransferFee
      );
      const agentCharges = displayChargesForTransfer3rdScenario(
        fromCurrCharges?.agentCharges
      );

      setCharges((prev) => ({
        ...prev,
        commissionCharges,
        wireTransferFee,
        agentCharges,
        totalCharges: agentCharges + wireTransferFee + commissionCharges,
      }));
    }
  };

  const conversionRate = (exchangeRate) => {
    return transactionType === TransactionType.Buy || transfer2ndScenario
      ? `1 ${fromCurrType} = ${exchangeRate} ${toCurrType}`
      : transactionType === TransactionType.Sell || TransactionType.Transfer
      ? `1 ${toCurrType} = ${exchangeRate} ${fromCurrType}`
      : null;
  };

  const UserTypes = {
    1: "Admin",
    2: "Customer",
    3: "Agent",
  };

  const openCommentPopup = (status) => {
    setSelectedStatus(status);
    setIsCommentOpen(true);
  };

  const closeCommentPopup = () => {
    setIsCommentOpen(!isCommentOpen);
    setCommentText("");
  };

  const calculateDateDifference = (startDate, endDate) => {
    const start = new Date(startDate);
    const end = new Date(endDate);
    const diffInDays = Math.floor((end - start) / (1000 * 60 * 60 * 24));
    return diffInDays;
  };

  useEffect(() => {
    if (transactionId) {
      fetchSignature(transactionId);
      calculateCharges();
    }
  }, [transactionId]);

  const handleCommentSubmit = async () => {
    setIsLoading(true);
    try {
      const response = await postApiData("api/Transaction/ApproveOrReject", {
        transactionId: transactionId,
        comment: commentText,
        status: selectedStatus,
      });
      if (response?.success) {
        toastSucess(response?.message);
        closeCommentPopup();
        toggle();
        if (activeTab === 1) {
          getTransaction();
        } else if (activeTab === 2) {
          getAgentTransaction();
        }
      } else {
        toastWarn(response?.message);
      }
    } catch {
      toast.error("Error submitting data", {
        position: "top-right",
        autoClose: 3000,
      });
    } finally {
      setIsLoading(false);
    }
  };

  const handleImageClick = (imageSrc) => {
    const imageUrl = `data:image/png;base64,${imageSrc}`;
    const newTab = window.open("", "_blank");
    newTab.document.write(
      `<img src="${imageUrl}" alt="Signature" style="display:block; margin:auto; width:100%; max-width:800px;"/>`
    );
    newTab.document.title = "Signature";
  };

  const getBeneficiary = async () => {
    const benResponse = await getApiData(
      `api/CustomerBenMap/GetAllCustBenMapByCustomerId?CustomerId=${fromCustomerId}`
    );
    const selectedBeneficiary = benResponse?.data?.find(
      (item) => item.custBenId === toCustBenId
    );

    if (selectedBeneficiary) {
      const beneficiary = {
        beneficiaryName: selectedBeneficiary.beneficiaryName,
        currencyName: selectedBeneficiary.currencyName,
      };
      setBeneficiaryDetails(beneficiary);
    }
  };

  const fetchCompanyData = async () => {
    const companyResponse = await getApiData(
      `api/AgentBeneficiary/GetAllCompanyBen?CompanyId=${companyId}`
    );
    const mappedResponse = companyResponse?.data?.map((item) => ({
      companyBenId: item.companyBenId,
      companyId: item.companyId,
      beneficiaryName: item.beneficiaryName,
      currencyCode: item.currencyCode,
      currencyName: item.currencyName,
    }));

    const response = await getApiData(
      `api/AgentBeneficiary/GetBen?CompanyBenId=${toCompanyBenId}`
    );

    if (response) {
      const toCompanyBenId = toCompanyBenId;
      const benId = mappedResponse?.find(
        (item) => item.companyBenId === toCompanyBenId
      );

      if (benId) {
        const beneficiary = {
          beneficiaryName: benId.beneficiaryName,
          currencyName: benId.currencyName,
        };

        setBeneficiaryDetails(beneficiary);
      }
    }
  };

  useEffect(() => {
    if (transactionType === TransactionType.Transfer) {
      if (toCompanyBenId) {
        fetchCompanyData();
      } else if (fromCustomerId) {
        getBeneficiary();
      }
    }
  }, [transactionType, toCompanyBenId, fromCustomerId]);

  return (
    <React.Fragment>
      {isLoading && <Loader />}
      <Modal
        isOpen={isOpen}
        role="dialog"
        autoFocus={true}
        centered={true}
        className="exampleModal"
        tabIndex="-1"
        toggle={toggle}
        size="lg"
        onClosed={resetSign}
      >
        <ToastContainer />
        <div className="modal-content">
          <ModalHeader toggle={toggle}>
            Transaction Details - {transactionId}
          </ModalHeader>
          <ModalBody>
            <div className="mt-3">
              <div
                className="accordion accordion-flush"
                id="accordionFlushExample"
              >
                <div className="accordion-item">
                  <h2 className="accordion-header" id="headingFlushOne">
                    <button
                      className={classnames("accordion-button", "fw-medium", {
                        collapsed: !col9,
                      })}
                      type="button"
                      onClick={t_col9}
                      style={{ cursor: "pointer" }}
                    >
                      Currency Info -&nbsp;
                      <Badge
                        className={
                          "font-size-11 ms-1 me-1 badge-soft-" +
                          mapTransactionType(transactionType)?.color
                        }
                      >
                        {mapTransactionType(transactionType)?.label}
                      </Badge>
                    </button>
                  </h2>

                  <Collapse isOpen={col9} className="accordion-collapse">
                    <div className="accordion-body">
                      <div className="text-muted">
                        <Table className="table-nowrap mb-0">
                          <tbody>
                            {sendTransactionDetails && fromCurrType && (
                              <>
                                <tr>
                                  <th scope="row">From currency :</th>
                                  <td>
                                    {fromCurrType} - {roundOff(fromCurrValue)}
                                  </td>
                                </tr>
                                <tr>
                                  <th scope="row">To currency :</th>
                                  <td>
                                    {toCurrType} - {roundOff(toCurrValue)}
                                  </td>
                                </tr>
                                {charges.wireTransferFee !== 0 &&
                                  !isNaN(charges.wireTransferFee) && (
                                    <tr>
                                      <th scope="row">Wire Transfer Fee :</th>
                                      <td>
                                        {displayChargesWithCurrency(
                                          charges.wireTransferFee
                                        )}
                                      </td>
                                    </tr>
                                  )}
                                {charges.agentCharges !== 0 &&
                                  !isNaN(charges.agentCharges) && (
                                    <tr>
                                      <th scope="row">Agent Charges :</th>
                                      <td>
                                        {displayChargesWithCurrency(
                                          charges.agentCharges
                                        )}
                                      </td>
                                    </tr>
                                  )}
                                {charges.commissionCharges !== 0 &&
                                  !isNaN(charges.commissionCharges) && (
                                    <tr>
                                      <th scope="row">Commission Charges :</th>
                                      <td>
                                        {displayChargesWithCurrency(
                                          charges.commissionCharges
                                        )}
                                      </td>
                                    </tr>
                                  )}
                                {charges.totalCharges !== 0 &&
                                  !isNaN(charges.totalCharges) &&
                                  transactionType ===
                                    TransactionType.Transfer && (
                                    <tr>
                                      <th scope="row">Total Charges :</th>
                                      <td>
                                        {displayChargesWithCurrency(
                                          charges.totalCharges
                                        )}
                                      </td>
                                    </tr>
                                  )}
                                <tr>
                                  <th scope="row">Conversion rate :</th>
                                  <td>
                                    <Label
                                      className="form-check-Label"
                                      htmlFor="formrow-customCheck"
                                    >
                                      {conversionRate(exchangeRate)}
                                      {parseFloat(discountedRate) !== 0 &&
                                      parseFloat(discountedRate) !== null
                                        ? " (Discounted)"
                                        : ""}
                                    </Label>
                                  </td>
                                </tr>
                                <tr>
                                  <th scope="row">Status :</th>
                                  <td>
                                    <Badge
                                      className={
                                        "font-size-11 ms-1 me-1 badge-soft-" +
                                        mapStatus(status)?.color
                                      }
                                    >
                                      {mapStatus(status)?.label}
                                    </Badge>
                                  </td>
                                </tr>
                              </>
                            )}
                          </tbody>
                        </Table>
                      </div>
                    </div>
                  </Collapse>
                </div>

                <div className="accordion-item">
                  <h2 className="accordion-header" id="headingFlushTwo">
                    <button
                      className={classnames("accordion-button", "fw-medium", {
                        collapsed: !col10,
                      })}
                      type="button"
                      onClick={t_col10}
                      style={{ cursor: "pointer" }}
                    >
                      Branch Info
                    </button>
                  </h2>

                  <Collapse isOpen={col10} className="accordion-collapse">
                    <div className="accordion-body">
                      <div className="text-muted">
                        <Table className="table-nowrap mb-0">
                          <tbody>
                            {sendTransactionDetails && (
                              <>
                                <tr>
                                  <th scope="row">Branch Name :</th>
                                  <td>{branchName}</td>
                                </tr>
                                <tr>
                                  <th scope="row">Branch Address :</th>
                                  <td>
                                    {street},<br />
                                    {city},{state},<br />
                                    {country},{postalCode}
                                  </td>
                                </tr>
                                <tr>
                                  <th scope="row">Booked Date :</th>
                                  {/* Madhangi changes for scrum 17 - start*/}
                                  <td>
                                    {formatDateToUserTimeZone(transactionDate)}
                                  </td>
                                  {/* Madhangi changes for scrum 17 - end*/}
                                </tr>
                                <tr>
                                  <th scope="row">Operational Time :</th>
                                  {/* Madhangi changes for scrum 17 - start*/}
                                  <td>
                                    {sendOpTimeTransactionDetails?.map(
                                      (item) => (
                                        <div key={item.day}>
                                          <span className="me-2">
                                            {days[item.day]}
                                          </span>
                                          <span className="me-2">
                                            {convertTime(item.startTime)}-{" "}
                                          </span>
                                          <span className="me-2">
                                            {convertTime(item.endTime)}
                                          </span>
                                        </div>
                                      )
                                    )}
                                  </td>
                                  {/* Madhangi changes for scrum 17 - end*/}
                                </tr>
                                {pickup && (
                                  <tr>
                                    <th scope="row">Pickup :</th>
                                    <td>{formatDateToUserTimeZone(pickup)}</td>
                                  </tr>
                                )}
                              </>
                            )}
                          </tbody>
                        </Table>
                      </div>
                    </div>
                  </Collapse>
                </div>
                {transactionType === 3 && (
                  <div className="accordion-item">
                    <h2 className="accordion-header" id="headingFlushThree">
                      <button
                        className={classnames("accordion-button", "fw-medium", {
                          collapsed: !col12,
                        })}
                        type="button"
                        onClick={t_col12}
                        style={{ cursor: "pointer" }}
                      >
                        Beneficiary Info
                      </button>
                    </h2>

                    <Collapse isOpen={col12} className="accordion-collapse">
                      <div className="accordion-body">
                        <div className="text-muted">
                          <Table className="table-nowrap mb-0">
                            <tbody>
                              {beneficiaryDetails && (
                                <>
                                  <tr>
                                    <th scope="row">Beneficiary Name :</th>
                                    <td>
                                      {beneficiaryDetails.beneficiaryName}
                                    </td>
                                  </tr>
                                  <tr>
                                    <th scope="row">Currency :</th>
                                    <td>{beneficiaryDetails.currencyName}</td>
                                  </tr>
                                </>
                              )}
                            </tbody>
                          </Table>
                        </div>
                      </div>
                    </Collapse>
                  </div>
                )}
                <div className="accordion-item">
                  <h2 className="accordion-header" id="headingFlushThree">
                    <button
                      className={classnames("accordion-button", "fw-medium", {
                        collapsed: !col11,
                      })}
                      type="button"
                      onClick={t_col11}
                      style={{ cursor: "pointer" }}
                    >
                      Review Info
                    </button>
                  </h2>
                  <Collapse isOpen={col11} className="accordion-collapse">
                    <div className="accordion-body">
                      <div className="text-muted">
                        <div
                          className="mt-1 overflow-auto"
                          style={{ maxHeight: "180px", maxWidth: "auto" }}
                        >
                          {commentData?.length > 0 ? (
                            commentData?.map((comment, index) => (
                              <div key={index}>
                                <span>
                                  <div className="d-flex justify-content-between align-items-center">
                                    <h6>{UserTypes[comment.userType]}</h6>
                                    <div>
                                      {formatDateToUserTimeZone(
                                        comment.createdOn
                                      )}
                                    </div>
                                  </div>
                                  <p>
                                    Status:{" "}
                                    <Badge
                                      className={
                                        "font-size-11 badge-soft-" +
                                        mapStatus(comment.transactionStatus)
                                          .color
                                      }
                                    >
                                      {
                                        mapStatus(comment.transactionStatus)
                                          .label
                                      }
                                    </Badge>
                                  </p>
                                </span>
                                <span>
                                  <h6>
                                    <b>Comment: {comment.comments}</b>
                                  </h6>
                                </span>
                                <hr />
                                <span>{""}</span>
                              </div>
                            ))
                          ) : (
                            <div
                              className="text-muted d-flex justify-content-center align-items-center"
                              style={{ minHeight: "100px" }}
                            >
                              <h3>No comments to show</h3>
                            </div>
                          )}
                        </div>
                        {sendOpTimeTransactionDetails !== null &&
                          (status === 1 ||
                            status === 2 ||
                            status === 3 ||
                            status === 4 ||
                            status === 8 ||
                            status === 10 ||
                            status === 11) && (
                            <div className="mt-4">
                              <Button
                                type="button"
                                color="primary"
                                className="btn-primary"
                                onClick={() => openCommentPopup(status)}
                              >
                                Comment
                              </Button>
                            </div>
                          )}
                      </div>
                    </div>
                  </Collapse>
                </div>
                {status === 13 && sign && (
                  <div className="accordion-item">
                    <h2 className="accordion-header" id="headingFlushOne">
                      <button
                        className={classnames("accordion-button", "fw-medium", {
                          collapsed: !col13,
                        })}
                        type="button"
                        onClick={t_col13}
                        style={{ cursor: "pointer" }}
                      >
                        Signature Info
                      </button>
                    </h2>
                    <Collapse isOpen={col13} className="accordion-collapse">
                      <div className="accordion-body">
                        <div className="text-muted">
                          <Table className="table-nowrap mb-0">
                            <tbody>
                              {sendTransactionDetails && sign ? (
                                <tr>
                                  <td>
                                    <div
                                      className="d-flex justify-content-center align-items-center"
                                      style={{ minHeight: "100px" }}
                                    >
                                      <img
                                        src={`data:image/png;base64,${sign?.sign}`}
                                        alt="Signature"
                                        style={{
                                          width: "150px",
                                          height: "50px",
                                          objectFit: "contain",
                                          cursor: "pointer",
                                        }}
                                        onClick={() =>
                                          handleImageClick(sign?.sign)
                                        }
                                      />
                                    </div>
                                  </td>
                                </tr>
                              ) : (
                                <tr>
                                  <td>
                                    <div
                                      className="text-muted d-flex justify-content-center align-items-center"
                                      style={{ minHeight: "100px" }}
                                    >
                                      <h3>No signature available</h3>
                                    </div>
                                  </td>
                                </tr>
                              )}
                            </tbody>
                          </Table>
                        </div>
                      </div>
                    </Collapse>
                  </div>
                )}
              </div>
            </div>
          </ModalBody>
          <ModalFooter>
            {sendOpTimeTransactionDetails !== null &&
              (status === 1 || status === 3 || status === 8) && (
                <Button
                  type="button"
                  color="primary"
                  className="btn-primary"
                  onClick={() => openCommentPopup(4)}
                >
                  Approve
                </Button>
              )}

            {sendOpTimeTransactionDetails !== null &&
              (status === 1 ||
                status === 2 ||
                status === 3 ||
                status === 4 ||
                status === 8 ||
                status === 10) && (
                <Button
                  type="button"
                  color="danger"
                  className="btn-primary"
                  onClick={() => openCommentPopup(5)}
                  disabled={
                    sendOpTimeTransactionDetails !== null &&
                    status === 4 &&
                    calculateDateDifference(approvedDate, new Date()) === 1
                  }
                >
                  Decline
                </Button>
              )}

            {sendOpTimeTransactionDetails !== null && status === 10 && (
              <Button
                type="button"
                color="warning"
                className="btn-primary"
                onClick={() => openCommentPopup(10)}
              >
                Regenerate QR
              </Button>
            )}
            {sendOpTimeTransactionDetails !== null &&
              (status === 1 || status === 8) && (
                <Button
                  type="button"
                  color="warning"
                  className="btn-primary"
                  onClick={() => openCommentPopup(2)}
                >
                  Review
                </Button>
              )}

            {sendOpTimeTransactionDetails !== null && status === 10 && (
              <Button
                type="button"
                color="success"
                className="btn-primary"
                onClick={() =>
                  openCommentPopup(
                    transactionType === TransactionType.Transfer ? 12 : 11
                  )
                }
              >
                Payment Received
              </Button>
            )}

            {sendOpTimeTransactionDetails !== null && status === 11 && (
              <Button
                type="button"
                color="success"
                className="btn-primary"
                onClick={() => openCommentPopup(12)}
              >
                Ready to Pay
              </Button>
            )}

            {sendOpTimeTransactionDetails !== null && status === 12 && (
              <Button
                type="button"
                color="success"
                className="btn-primary"
                onClick={() => openCommentPopup(13)}
              >
                Cash Collected
              </Button>
            )}
          </ModalFooter>
        </div>
        {/* Add comment popup */}
        <Modal
          isOpen={isCommentOpen}
          toggle={() => setIsCommentOpen(false)}
          centered
          size="lg"
          onClosed={() => {
            resetSign();
          }}
        >
          <ModalHeader toggle={() => setIsCommentOpen(false)}>
            Add Comment
          </ModalHeader>
          <ModalBody>
            <textarea
              className="form-control"
              rows="5"
              value={commentText}
              onChange={(e) => setCommentText(e.target.value)}
              placeholder="Write your comment here..."
            />
            {status === 12 && (
              <>
                <h5 className="mt-2 text-center">Signature</h5>
                <div className="text-center mt-2">
                  {renderFileOrSignature()}
                </div>

                <div className="text-center mt-3">
                  {!showSignaturePad && (
                    <>
                      <Button color="primary" className="me-2">
                        <label
                          htmlFor="upload-file"
                          style={{
                            cursor: "pointer",
                            color: "white",
                            margin: 0,
                          }}
                        >
                          <i className="fas fa-file-upload" />
                        </label>
                        <input
                          id="upload-file"
                          type="file"
                          style={{ display: "none" }}
                          accept=".pdf"
                          onChange={handleFileUpload}
                        />
                      </Button>

                      <Button
                        color="primary"
                        onClick={() => setShowSignaturePad(true)}
                      >
                        <i className="fas fa-pencil-alt" />
                      </Button>
                    </>
                  )}

                  {showSignaturePad && (
                    <div
                      style={{
                        border: "1px solid #ced4da",
                        display: "inline-block",
                        marginTop: "10px",
                      }}
                    >
                      <SignaturePad
                        ref={signaturePad}
                        canvasProps={{
                          width: 400,
                          height: 145,
                          style: { cursor: "cell" },
                        }}
                        onEnd={handleSignatureEnd}
                      />
                      <Button
                        color="primary"
                        className="mt-2"
                        onClick={clearSignature}
                      >
                        <i className="fas fa-eraser" />
                      </Button>
                    </div>
                  )}
                </div>
              </>
            )}
          </ModalBody>
          <ModalFooter>
            <Button
              color="primary"
              onClick={() => {
                if (isFileUploaded || isSignatureDrawn) {
                  handleAddSignSubmit();
                }
                handleCommentSubmit();
              }}
              disabled={isLoading}
            >
              Submit
            </Button>

            <Button color="secondary" onClick={() => setIsCommentOpen(false)}>
              Cancel
            </Button>
          </ModalFooter>
          {isLoading && (
            <div className="loader-container">
              <CustomLoader text="Transaction In Progress" />
            </div>
          )}
        </Modal>
      </Modal>
    </React.Fragment>
  );
};

ViewLatestTransactionModel.propTypes = {
  toggle: PropTypes.func,
  isOpen: PropTypes.bool,
  sendTransactionDetails: PropTypes.object,
  commentData: PropTypes.array,
};

export default ViewLatestTransactionModel;
