import React from "react";
import { Navigate } from "react-router-dom";

const AuthProtected = (props) => {
  const user = localStorage.getItem("authUser");
  if (!user) {
    return <Navigate to={{ pathname: "/", state: { from: props.location } }} />;
  }
  return <React.Fragment>{props.children}</React.Fragment>;
};

export default AuthProtected;
