import React, { useEffect, useMemo, useState } from "react";
import {
  Button,
  Card,
  CardBody,
  Modal,
  ModalHeader,
  ModalBody,
  Container,
  Badge,
} from "reactstrap";
import TableContainer from "../../components/Common/TableContainer";
import Loader from "../../components/Common/Loader";
import DeleteModal from "../../components/Common/DeleteModal";
import {
  getApiData,
  deleteApiData,
  putApiData,
} from "../../helpers/axiosHelper";
import AddAgentCompany from "./AddAgentCompany";
import ManageAgent from "./ManageAgent";
import { toast, ToastContainer } from "react-toastify";
import ViewDetailsToggle from "./ViewDetailsToggle";
import ConfirmationSwal from "../../components/Common/ConfirmationSwal";
import { activate, deactivate, edit } from "../../common/data/icons";
import useScrollPosition from "../../common/data/useScroll";
import { HelpDocButton } from "../HelpDocs/HelpDocItems";
import { helpDocUrl } from "../HelpDocs/HelpDocUrl";
import RefreshButton from "../../common/data/RefreshButton";

const ManageCompanyTab = () => {
  document.title = "Manage Company | Crescent Exchange";
  const [companyData, setCompanyData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);
  const [deleteCompanyId, setDeleteCompanyId] = useState(null);
  const [addModal, setAddModal] = useState(false);
  const [selectedCompany, setSelectedCompany] = useState(null);
  const [companyId, setCompanyId] = useState("");
  const [viewModal, setViewModal] = useState(false);
  const [pageSize, setPageSize] = useState(10);
  const [currentPage, setCurrentPage] = useState(0);
  const { saveScrollPosition, restoreScrollPosition } = useScrollPosition();

  const toggleViewDetails = () => setViewModal(!viewModal);

  const toggleAddModal = () => setAddModal(!addModal);

  const fetchData = async () => {
    saveScrollPosition();
    setIsLoading(true);
    try {
      const response = await getApiData("api/AgentUser/GetAllCompany");
      const mappedResponse = response?.data?.map((item, index) => ({
        index: index + 1,
        companyId: item.companyId,
        companyName: item.companyName,
        companyAddress: item.companyAddress,
        companyRegistration: item.companyRegistration,
        phone: item.mobile,
        email: item.email,
        active: item.active,
        companyCode: item.companyCode,
      }));
      setCompanyData(mappedResponse);
    } catch (error) {
      console.error("Error fetching data: ", error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  const onClickDelete = (companyId) => {
    setDeleteCompanyId(companyId);
    setDeleteModal(true);
  };

  const viewCompanyDetails = (companyId) => {
    setCompanyId(companyId);
    toggleViewDetails();
  };

  const toggleDeleteModal = () => {
    setDeleteModal(!deleteModal);
  };

  const updateCompanyStatus = async (company, isActive) => {
    const result = await ConfirmationSwal("Are you sure?", "Yes", "Cancel");

    if (result.isConfirmed) {
      const updatedValues = { ...company, active: isActive };
      try {
        const response = await putApiData(
          `api/AgentUser/UpdateCompany`,
          updatedValues
        );
        if (response?.success) {
          toast.success(
            `Company ${isActive ? "Activated" : "Deactivated"} Successfully`,
            {
              position: "top-right",
              autoClose: 3000,
            }
          );
          fetchData();
        } else {
          toast.error(
            `Failed to ${isActive ? "activate" : "deactivate"} company`,
            {
              position: "top-right",
              autoClose: 3000,
            }
          );
        }
      } catch (error) {
        toast.error(
          `Error ${isActive ? "activating" : "deactivating"} company`,
          {
            position: "top-right",
            autoClose: 3000,
          }
        );
      }
    }
  };

  const DeleteData = async () => {
    const result = await ConfirmationSwal("Are you sure?", "Yes", "Cancel");

    if (result.isConfirmed) {
      try {
        const response = await deleteApiData(
          `api/AgentUser/DeleteCompany?companyId=${deleteCompanyId}`
        );
        if (response?.success) {
          toast.success("Company Deleted Successfully", {
            position: "top-right",
            autoClose: 3000,
          });
          fetchData();
          toggleDeleteModal();
        } else {
          toast.error("Failed to delete company", {
            position: "top-right",
            autoClose: 3000,
          });
        }
      } catch (error) {
        toast.error("Error deleting company", {
          position: "top-right",
          autoClose: 3000,
        });
      }
    }
  };

  const columns = useMemo(
    () => [
      {
        Header: "Name",
        accessor: "companyName",
        filterable: false,
        disableFilters: true,
        Cell: (cellProps) => (
          <Button
            color="link"
            onClick={() => setSelectedCompany(cellProps.row.original.companyId)}
          >
            {cellProps.row.original.companyName}
          </Button>
        ),
      },
      {
        Header: "Company Code",
        accessor: "companyCode",
        filterable: false,
        disableFilters: true,
      },
      {
        Header: "Email",
        accessor: "email",
        filterable: false,
        disableFilters: true,
      },
      {
        Header: "Registration",
        accessor: "companyRegistration",
        filterable: false,
        disableFilters: true,
      },
      {
        Header: "Status",
        accessor: "active",
        disableFilters: true,
        filterable: false,
        Cell: (cellProps) => (
          <Badge
            className={`font-size-11 badge-soft-${
              cellProps.row.original.active ? "success" : "danger"
            }`}
          >
            {cellProps.row.original.active ? "Active" : "Deactivated"}
          </Badge>
        ),
      },
      {
        Header: "Actions",
        disableFilters: true,
        accessor: "actions",
        Cell: (cellProps) => (
          <React.Fragment>
            {cellProps.row.original.active ? (
              <>
                <Button
                  color="primary"
                  className="btn-sm btn-rounded me-1"
                  title="Edit"
                  onClick={() =>
                    viewCompanyDetails(cellProps.row.original.companyId)
                  }
                >
                  {edit()}
                </Button>{" "}
                <Button
                  type="button"
                  color="danger"
                  className="btn-sm btn-rounded me-1"
                  title="Deactivate"
                  onClick={() =>
                    updateCompanyStatus(cellProps.row.original, false)
                  }
                >
                  {deactivate()}
                </Button>
              </>
            ) : (
              <Button
                type="button"
                color="success"
                className="btn-sm btn-rounded me-1"
                title="Activate"
                onClick={() =>
                  updateCompanyStatus(cellProps.row.original, true)
                }
              >
                {activate()}
              </Button>
            )}
          </React.Fragment>
        ),
      },
    ],
    []
  );

  const handleTableChange = (newPageSize, newPageIndex) => {
    setPageSize(newPageSize);
    setCurrentPage(newPageIndex);
  };

  useEffect(() => {
    if (companyData?.length > 0) {
      restoreScrollPosition();
    }
  }, [companyData]);

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          {isLoading ? (
            <Loader />
          ) : (
            <div>
              <ViewDetailsToggle
                isOpen={viewModal}
                toggle={toggleViewDetails}
                companyId={companyId}
                getAllCompany={fetchData}
              />
              <DeleteModal
                show={deleteModal}
                onDeleteClick={DeleteData}
                onCloseClick={toggleDeleteModal}
                deactivateTitle={true}
              />
              <AddAgentCompany
                isOpen={addModal}
                toggle={toggleAddModal}
                getAllCompany={fetchData}
              />
              <Card>
                <CardBody>
                  <div className="d-flex flex-wrap justify-content-between align-items-center">
                    <div className="d-flex mb-4">
                      <div className=" h3 card-title">MANAGE COMPANY</div>
                      {HelpDocButton(helpDocUrl.manageCompany)}
                    </div>
                    <div className="text-sm-end">
                      <Button
                        type="button"
                        color="primary"
                        onClick={() => setAddModal(true)}
                        className="btn mb-2 me-2"
                      >
                        <i className="mdi mdi-plus-circle-outline me-1" />
                        Create New Company
                      </Button>
                    </div>
                  </div>
                  <TableContainer
                    columns={columns}
                    data={companyData}
                    isGlobalFilter={true}
                    isAddOptions={false}
                    //customPageSize={10}
                    isPageSelect={false}
                    customPageSize={pageSize}
                    pageSize={pageSize}
                    pageIndex={currentPage}
                    onPageChange={handleTableChange}
                    refresh={true}
                    onRefreshClick={fetchData}
                  />
                </CardBody>
              </Card>
              <AgentDetailsModal
                isOpen={selectedCompany !== null}
                toggle={() => setSelectedCompany(null)}
                companyId={selectedCompany}
                companyData={companyData}
              />
            </div>
          )}
        </Container>
        <ToastContainer />
      </div>
    </React.Fragment>
  );
};

const AgentDetailsModal = ({ isOpen, toggle, companyId, companyData }) => {
  const company = companyData?.find(
    (company) => company.companyId === companyId
  );
  return (
    <Modal isOpen={isOpen} toggle={toggle} size="xl">
      <ModalHeader toggle={toggle}>
        Company - {company?.companyName}
      </ModalHeader>
      <ModalBody>
        <ManageAgent companyId={companyId} />
      </ModalBody>
    </Modal>
  );
};

export default ManageCompanyTab;
