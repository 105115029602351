import React, { useEffect, useMemo, useState } from "react";
import classnames from "classnames";
import { Button, Collapse, Badge } from "reactstrap";
import TableContainer from "./../../components/Common/TableContainer";
import { activate, deactivate, edit, view } from "../../common/data/icons";
import { deleteApiData, getApiData } from "../../helpers/axiosHelper";
import {
  getCustomerBeneficiaryByCustomerId,
  toastError,
  toastSucess,
  toggleMode,
} from "../../common/data/commonfunctions";
import CustomerBeneficiaryModal from "./CustomerBeneficiary/CustomerBeneficiaryModal";
import Swal from "sweetalert2";

const BeneficiaryAccordian = (props) => {
  const { col13, t_col13, customerId, UId, editMode } = props;
  const [beneficiaryState, setBeneficiaryState] = useState({
    beneficiaries: [],
    modal: false,
    custData: null,
    addressData: null,
    bankData: null,
    docData: [],
    mode: null,
  });

  const toggleModal = () => {
    setBeneficiaryState((prev) => ({
      ...prev,
      modal: !beneficiaryState.modal,
    }));
  };

  const handleAddBeneficiary = () => {
    setBeneficiaryState((prev) => ({
      ...prev,
      bankData: null,
      custData: null,
      docData: [],
      addressData: null,
      mode: toggleMode.add,
    }));
    toggleModal();
  };

  const getBeneficiaryByCustomerId = async () => {
    const response = await getCustomerBeneficiaryByCustomerId(customerId);
    setBeneficiaryState((prev) => ({
      ...prev,
      beneficiaries: response,
    }));
  };

  const getBeneficiaryDetails = async (props) => {
    setBeneficiaryState((prev) => ({
      ...prev,
      custData: props,
      bankData: props,
      addressData: props,
    }));
    toggleModal();
  };

  const softDeleteBeneficiary = async (custId, custBenId, status, UId) => {
    Swal.fire({
      title: `${status ? "Activate" : "Deactivate"} Beneficiary`,
      text: `Are you sure you want to ${
        status ? "activate" : "deactivate"
      } this beneficiary?`,
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: `Yes`,
      confirmButtonColor: "#d33",
    }).then(async (result) => {
      if (result.isConfirmed) {
        const response = await deleteApiData(
          `api/CustomerBenMap/DeleteBen?CustomerId=${custId}&CustBenId=${custBenId}&active=${status}&UId=${UId}`
        );
        if (response?.success) {
          toastSucess(response?.message);
          getBeneficiaryByCustomerId();
        } else {
          toastError(response?.message);
        }
      }
    });
  };

  const columns = useMemo(
    () => [
      {
        Header: "Benificiary Name",
        accessor: "beneficiaryName",
        filterable: false,
        disableFilters: true,
        Cell: (cellProps) => {
          const { value, row } = cellProps;
          const { isMyAccount } = row.original;

          return (
            <>
              {value ? value : ""}
              {isMyAccount === true && (
                <Badge
                  className="font-size-11 badge-soft-success"
                  style={{ marginLeft: "0.5rem" }}
                >
                  Own
                </Badge>
              )}
            </>
          );
        },
      },
      {
        Header: "Currency",
        accessor: "currency",
        disableFilters: true,
        filterable: false,
        Cell: (cellProps) => {
          return cellProps.value ? cellProps.value : "";
        },
      },
      {
        Header: "Status",
        accessor: "status",
        disableFilters: true,
        filterable: false,
        Cell: (cellProps) => {
          let badgeColor = "danger";
          if (cellProps.value === "Draft") {
            badgeColor = "warning";
          } else if (cellProps.value === "Incomplete") {
            badgeColor = "danger";
          } else if (cellProps.value === "Completed") {
            badgeColor = "success";
          }
          return (
            <Badge className={`font-size-11 badge-soft-${badgeColor}`}>
              {cellProps.value}
            </Badge>
          );
        },
      },
      {
        Header: "Actions",
        disableFilters: true,
        accessor: "view",
        Cell: (cellProps) => {
          const { active } = cellProps.row.original;
          const custId = cellProps.row.original.customerId;
          const custBenId = cellProps.row.original.custBenId;
          return (
            <>
              <Button
                type="button"
                color="primary"
                title="View"
                className="btn-sm btn-rounded ms-2"
                onClick={() => {
                  getBeneficiaryDetails(cellProps.row.original);
                  setBeneficiaryState((prev) => ({
                    ...prev,
                    mode: toggleMode.view,
                  }));
                }}
                aria-label="view"
              >
                {view()}
              </Button>
              {!editMode && (
                <>
                  {active === "Active" && (
                    <Button
                      type="button"
                      color="success"
                      title="Edit"
                      className="btn-sm btn-rounded ms-2"
                      onClick={() => {
                        getBeneficiaryDetails(cellProps.row.original);
                        setBeneficiaryState((prev) => ({
                          ...prev,
                          mode: toggleMode.edit,
                        }));
                      }}
                      aria-label="edit"
                    >
                      {edit()}
                    </Button>
                  )}
                  <Button
                    type="button"
                    color={active === "Inactive" ? "success" : "danger"}
                    title={active === "Inactive" ? "Activate" : "Deactivate"}
                    className="btn-sm btn-rounded ms-2"
                    onClick={() => {
                      const newStatus = active === "Inactive" ? true : false;
                      softDeleteBeneficiary(custId, custBenId, newStatus, UId);
                    }}
                    aria-label="deactivate"
                  >
                    {active === "Inactive" ? activate() : deactivate()}
                  </Button>
                </>
              )}
            </>
          );
        },
      },
    ],
    []
  );

  useEffect(() => {
    getBeneficiaryByCustomerId();
  }, []);

  return (
    <React.Fragment>
      <div className="accordion-item">
        <h2 className="accordion-header" id="headingFlushThree">
          <button
            className={classnames("accordion-button", "fw-medium", {
              collapsed: !col13,
            })}
            type="button"
            onClick={t_col13}
            style={{ cursor: "pointer" }}
          >
            Beneficiary Details
          </button>
        </h2>
        <Collapse isOpen={col13} className="accordion-collapse">
          <div className="accordion-body">
            <div className="text-muted">
              {!editMode ? (
                <Button
                  color="success"
                  className="mb-1"
                  title="Add"
                  aria-label="add-beneficiary"
                  onClick={handleAddBeneficiary}
                >
                  + Add Beneficiary
                </Button>
              ) : null}
              <TableContainer
                data={beneficiaryState.beneficiaries}
                columns={columns}
                customPageSize={10}
              />
            </div>
          </div>
        </Collapse>
      </div>
      <CustomerBeneficiaryModal
        isOpen={beneficiaryState.modal}
        toggle={toggleModal}
        mode={beneficiaryState.mode}
        bankData={beneficiaryState.bankData}
        custData={beneficiaryState.custData}
        addressData={beneficiaryState.addressData}
        customerId={customerId}
        UId={UId}
        getBeneficiaryByCustomerId={getBeneficiaryByCustomerId}
        setBeneficiaryState={setBeneficiaryState}
      />
    </React.Fragment>
  );
};

export default React.memo(BeneficiaryAccordian);
