import React from "react";
import { Modal, ModalBody, ModalHeader } from "reactstrap";
import CustomerBeneficiaryForm from "./CustomerBeneficiaryForm";

const CustomerBeneficiaryModal = (props) => {
  const {
    isOpen,
    toggle,
    bankData,
    addressData,
    custData,
    mode,
    customerId,
    UId,
    setBeneficiaryState,
    getBeneficiaryByCustomerId,
  } = props;

  return (
    <Modal
      isOpen={isOpen}
      toggle={toggle}
      size="lg"
      onClosed={() => {
        setBeneficiaryState((prev) => ({
          ...prev,
          bankData: null,
          custData: null,
          docData: [],
          addressData: null,
        }));
      }}
    >
      <ModalHeader toggle={toggle}>Beneficiary</ModalHeader>
      <ModalBody>
        <CustomerBeneficiaryForm
          bankData={bankData}
          mode={mode}
          addressData={addressData}
          custData={custData}
          customerId={customerId}
          UId={UId}
          getBeneficiaryByCustomerId={getBeneficiaryByCustomerId}
        />
      </ModalBody>
    </Modal>
  );
};

export default React.memo(CustomerBeneficiaryModal);
