import React, { useEffect, useState } from "react";

import {
  Row,
  Col,
  Card,
  CardBody,
  FormGroup,
  Button,
  CardTitle,
  CardSubtitle,
  Label,
  Input,
  Container,
  FormFeedback,
  Form,
  Table,
  Nav,
  NavItem,
  NavLink,
} from "reactstrap";
import RateSheetTable from "./RateSheetTable";
import RateTable from "./RateTable";

const ManageRatesFeature = (props) => {
  document.title = "Crescent Exchange | Manage Rate";
  const [activeTab, setActiveTab] = useState(1);

  const toggle = (tab) => {
    if (activeTab !== tab) setActiveTab(tab);
  };

  const navTabs = [
    { label: "Rate Sheet", value: 1 },
    { label: "Rate Master", value: 2 },
  ];
  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Nav
            fill
            tabs
            style={{
              marginBottom: "10px",
              width: "50%",
            }}
          >
            {navTabs.map((x) => (
              <NavItem key={x.value}>
                <NavLink
                  className={activeTab === x.value ? "active" : ""}
                  style={
                    activeTab === x.value
                      ? { backgroundColor: "#556ee6", color: "white" }
                      : {}
                  }
                  onClick={() => {
                    toggle(x.value);
                  }}
                >
                  {x.label}
                </NavLink>
              </NavItem>
            ))}
          </Nav>
          <Row>
            <Col lg="12">
              {activeTab === 1 ? <RateSheetTable /> : null}

              {activeTab === 2 ? <RateTable /> : null}
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default ManageRatesFeature;
