const config = {
  // apiUrl: "https://cexadminapi.jaffar.au/",
  apiUrl: "https://sitapi.crescentexchange.com/system/",
  // apiUrl: "https://localhost:7298/",
  amlUrl: "https://www.opensanctions.org/advancedsearch/?algorithm=logic-v1&",
  livefxUrl:
    "https://sitapi.crescentexchange.com/liverates/liveRatesHub?access_key=51727204cd&c=EURUSD",
  // Add other configuration options as needed
};

export default config;
