import React, { useEffect, useState, useMemo } from "react";
import {
  Badge,
  Button,
  Card,
  CardBody,
  Col,
  Container,
  Input,
  Label,
  Modal,
  Row,
  FormGroup,
} from "reactstrap";
import ReactSelect from "react-select";
import { ToastContainer, toast } from "react-toastify";
import Loader from "../../../js/components/Common/Loader";
import { faEye } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import TableContainer from "../../../js/components/Common/TableContainer";
import { getApiData, postApiData } from "../../helpers/axiosHelper";
import {
  rateToFixed,
  toastError,
  useBranch,
  useCurrency,
  useCustomer,
} from "../../common/data/commonfunctions";
import { mapStatus, TransactionStatus } from "../../common/data/StatusLabels";
import ViewComment from "../Transaction/ViewComment";
import { edit, view } from "../../common/data/icons";
import DownloadReport from "./DownloadReport";
import SelectStyle from "../../common/data/SelectStyle";
import { statusOptions } from "../../common/data/StatusLabels";
import { mapTransactionType } from "../../common/data/StatusLabels";
import { transactionOptions } from "../../common/data/StatusLabels";
import RequiredAsterisk from "../../components/Common/RequiredAsterisk";
import { filter } from "lodash";
import { jsPDF } from "jspdf";
import autoTable from "jspdf-autotable";
import * as XLSX from "xlsx";
import {
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";
import { FaFileExcel, FaFilePdf, FaDownload, FaPrint } from "react-icons/fa";
import logo from "../../../images/logo-dark.png";
import { download, excel, pdf, print } from "./../../common/data/icons";
import { useCompany } from "../../common/data/commonfunctions";
import ViewLatestTransactionModel from "../Dashboard_Admin/ViewTransactionDetailsDashboard";
import { formatDateToUserTimeZone } from "../../common/data/date";
import { useCurrentPage } from "../../common/data/CustomPagination";
import { TransactionType } from "../../common/data/transactionType";
import { displayFlag } from "../../common/data/currency";
import { HelpDocButton } from "../HelpDocs/HelpDocItems";
import { helpDocUrl } from "../HelpDocs/HelpDocUrl";
import { useLatestTransactions } from "./../../common/data/latest-transaction-context";
import RefreshButton from "../../common/data/RefreshButton";

const TransactionReport = (props) => {
  document.title = "Transaction Report | Crescent Exchange";
  const { allCurrencies } = useLatestTransactions();
  const [modal1, setModal1] = useState(false);
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const { companyOptions, getAllCompany } = useCompany();
  const toggleDropdown = () => {
    setDropdownOpen(!dropdownOpen);
  };

  const [commentData, setCommentData] = useState([]);
  const [editMode, setEditMode] = useState(false);
  const toggleViewModal = () => setModal1(!modal1);
  const [sendTransactionDetails, setSendTransactiondetails] = useState(null);
  const [sendOpTimeTransactionDetails, setSendOpTimeTransactiondetails] =
    useState(null);
  const [sendBranchDetails, setSendBranchDetails] = useState(null);
  const { branches, branchId } = useLatestTransactions();
  const [isLoading, setIsLoading] = useState(false);
  const [modalFilter, setModalFilter] = useState(false);
  const [transactionData, setTransactionData] = useState([]);
  const [selectedOptions, setSelectedOptions] = useState(null);
  const { delayGetCustomer, customerList } = useCustomer();
  const { currency, getCurrency } = useCurrency();
  const [selectedFilterTypes, setSelectedFilterTypes] = useState([]);
  const {
    saveCurrentPage,
    restoreCurrentPage,
    onPageChange,
    pageSize,
    currentPage,
  } = useCurrentPage();
  const [state, setState] = useState({
    agentOptions: [],
  });

  const [filters, setFilters] = useState({
    fromCustomerId: "",
    fromBranchId: "",
    fromDate: "",
    toDate: "",
    fromCurrType: "",
    transactionDate: "",
    transactionType: "",
    status: "",
    companyId: "",
    agentId: "",
  });

  const fetchTransactionHistoryData = async (filters) => {
    setIsLoading(true);
    try {
      const response = await postApiData(
        "api/Report/GetTransactionReport",
        filters
      );
      if (response?.data?.length > 0) {
        const mappedResponse = response.data.map((item, key) => ({
          index: key + 1,
          transactionId: item.transactionId,
          agentId: item.agentId,
          customerName: item.customerName || item.agentName,
          branchName: item.branchName,
          companyName: item.companyName,
          agentName: item.agentName,
          fromCustomerId: item.fromCustomerId,
          toCustomerId: item.toCustomerId,
          currencyType:
            item.transactionType === TransactionType.Buy
              ? item.fromCurrType
              : item.transactionType === TransactionType.Sell
              ? item.toCurrType
              : item.fromCurrType,
          rate: item.exchangeRate,
          movingAverage: item.movingAverage,
          fromBranchId: item.fromBranchId,
          toBranchId: item.toBranchId,
          fromCurrType: item.fromCurrType,
          toCurrType: item.toCurrType,
          fromCurrValue:
            item.transactionType === TransactionType.Buy
              ? `${item.fromCurrType} - ${item.fromCurrValue}`
              : item.transactionType === TransactionType.Sell
              ? `${item.toCurrType} - ${item.toCurrValue}`
              : `${item.toCurrType} - ${item.toCurrValue}`,
          toCurrValue:
            item.transactionType === TransactionType.Buy
              ? `${item.toCurrType} - ${item.toCurrValue}`
              : item.transactionType === TransactionType.Sell
              ? `${item.fromCurrType} - ${item.fromCurrValue}`
              : `${item.toCurrType} - ${item.toCurrValue}`,
          approvedDate: item.approvedDate,
          fromRateHistoryId: item.fromRateHistoryId,
          toRateHistoryId: item.toRateHistoryId,
          createdBy: item.createdBy,
          createdAt: item.createdAt,
          modifiedBy: item.modifiedBy,
          modifiedAt: item.modifiedAt,
          agentBranchId: item.agentBranchId,
          profitLossSelling: item.profitLossSelling,
          discountedRate: item.discountedRate,
          fromDate: item.fromDate,
          toDate: item.toDate,
          transactionDate: formatDateToUserTimeZone(item.transactionDate),
          status: mapStatus(item.status),
          transactionType: mapTransactionType(item.transactionType),
        }));
        setTransactionData(mappedResponse);
      }
    } catch (error) {
      console.error(error);
      toast.error("Error fetching data");
    }
    setIsLoading(false);
  };

  const getAgentsByCompany = async (companyId) => {
    const response = await getApiData(
      `api/AgentUser/GetAgentsViewByCompany?companyId=${companyId}`
    );

    if (response?.data?.length === 0) {
      toast.error("No Agents Found for the Selected Company", {
        position: "top-right",
        autoClose: 2000,
        closeButton: false,
      });
      setState((prevState) => ({ ...prevState, agentOptions: [] }));
      return;
    }

    const mappedResponse = response?.data?.map((e) => ({
      label: `${e.firstName} ${e.middleName !== null ? e.middleName : ""} ${
        e.lastName
      }`,
      value: e.agentUid,
      userStatus: e.userStatus,
      companyId: e.companyId,
    }));

    setState((prevState) => ({ ...prevState, agentOptions: mappedResponse }));
  };

  const columnList = [
    {
      label: "Customer",
      value: "name",
    },
    {
      label: "Company",
      value: "companyName",
    },
    { label: "Branch", value: "fromBranchId" },
    { label: "Currency", value: "fromCurrType" },
    { label: "Transaction Date", value: "transactionDate" },
    { label: "Transaction Type", value: "transactionType" },
    { label: "Status", value: "status" },
  ];

  const handleFilterChange = (selected) => {
    setSelectedOptions(selected);
    const filterLabels = selected.map((item) => item.label);
    setSelectedFilterTypes(filterLabels);
    // setColumnName(selected.map((item) => item.value));
  };
  const isFilterApplied = Object.values(filters).some(
    (value) => value !== "" || selectedFilterTypes.length > 0
  );
  const clearFilters = () => {
    setFilters({
      fromCustomerId: "",
      fromBranchId: "",
      fromCurrType: "",
      fromDate: "",
      toDate: "",
      companyId: "",
      agentId: "",
      transactionDate: "",
      transactionType: "",
      status: "",
    });
    setSelectedOptions([]);
    setSelectedFilterTypes([]);
  };

  const handleSubmit = () => {
    fetchTransactionHistoryData(filters);
    setModalFilter(false);
  };

  useEffect(() => {
    getCurrency();
  }, []);

  useEffect(() => {
    if (branchId) {
      fetchTransactionHistoryData({ fromBranchId: branchId });
    }
  }, [branchId]);

  const ViewTransactionDetails = (cellProps) => {
    const { transactionId, fromBranchId } = cellProps;

    const getTransactionbyId = async () => {
      setIsLoading(true);
      try {
        const response = await getApiData(
          `api/Transaction/GetTransaction?TransactionID=${transactionId}`
        );
        const opdataResponse = await getApiData(
          `api/BranchMaster/GetBranchWithOperation?branchId=${fromBranchId}`
        );

        const commentData = response?.comments;
        setSendTransactiondetails(response);
        setCommentData(commentData);
        setSendOpTimeTransactiondetails(opdataResponse?.data?.operationalTime);
        setSendBranchDetails(opdataResponse?.data?.branch);
      } catch (error) {
        toast.error(error, {
          position: "top-right",
          autoClose: 3000,
        });
        setIsLoading(false);
      }
      setIsLoading(false);
    };
    getTransactionbyId();
    toggleViewModal();
  };

  const generatePDF = () => {
    const doc = new jsPDF("landscape");
    const pageWidth = doc.internal.pageSize.getWidth();
    const pageHeight = doc.internal.pageSize.getHeight();
    const margin = 10;

    doc.rect(margin, margin, pageWidth - 2 * margin, pageHeight - 2 * margin);

    const logoWidth = 35;
    const logoHeight = 5;
    const logoX = (pageWidth - logoWidth) / 2;
    const logoY = margin + 5;
    doc.addImage(logo, "PNG", logoX, logoY, logoWidth, logoHeight);

    const titleFontSize = 15;
    doc.setFontSize(titleFontSize);
    doc.setFont("helvetica", "bold");
    const titleX = pageWidth / 2;
    const titleY = logoY + logoHeight + 10;
    doc.text("Transaction Report", titleX, titleY, { align: "center" });

    doc.setFontSize(10);
    // doc.text("Applied Filters:", 14, titleY + 20);
    let filterY = titleY + 15;

    selectedFilterTypes.forEach((filter) => {
      switch (filter) {
        case "Transaction Date":
          doc.text(`From Date: ${filters.fromDate || "N/A"}`, 14, filterY);
          filterY += 6;
          doc.text(`To Date: ${filters.toDate || "N/A"}`, 14, filterY);
          filterY += 6;
          break;
        case "Branch":
          const branchName =
            branches.find((b) => b.value === filters.fromBranchId)?.label ||
            "N/A";
          doc.text(`Branch: ${branchName}`, 14, filterY);
          filterY += 6;
          break;
        case "Customer":
          const customerName =
            customerList.find((c) => c.customerId === filters.fromCustomerId)
              ?.customerName || "N/A";
          doc.text(`Customer: ${customerName}`, 14, filterY);
          filterY += 6;
          break;
        case "Currency":
          doc.text(`Currency: ${filters.fromCurrType || "N/A"}`, 14, filterY);
          filterY += 6;
          break;
        case "Transaction Type":
          const transactionTypeLabel =
            transactionOptions.find((t) => t.value === filters.transactionType)
              ?.label || "N/A";
          doc.text(`Transaction Type: ${transactionTypeLabel}`, 14, filterY);
          filterY += 6;
          break;
        case "Status":
          const statusLabel =
            statusOptions.find((s) => s.value === filters.status)?.label ||
            "N/A";
          doc.text(`Status: ${statusLabel}`, 14, filterY);
          filterY += 6;
          break;
        default:
          break;
      }
    });

    filterY += 4;

    const filteredColumns = columns.filter((col) => {
      if (col.accessor === "view") return false;
      switch (col.accessor) {
        case "fromCurrType":
          return !selectedFilterTypes.includes("Currency");
        case "customerName":
          return !selectedFilterTypes.includes("Customer");
        case "fromBranchId":
          return !selectedFilterTypes.includes("Branch");
        case "customerName":
          return !selectedFilterTypes.includes("Customer");
        case "transactionType":
          return !selectedFilterTypes.includes("Transaction Type");
        case "status":
          return !selectedFilterTypes.includes("Status");
        default:
          return true;
      }
    });

    const filteredTransactionData = transactionData.filter((row) => {
      let isMatch = true;

      // if (filters.fromDate && filters.toDate) {
      //   isMatch =
      //     isMatch &&
      //     new Date(row.transactionDate) >= new Date(filters.fromDate) &&
      //     new Date(row.transactionDate) <= new Date(filters.toDate);
      // }
      if (filters.fromBranchId) {
        isMatch =
          isMatch && row.fromBranchId?.value === filters.fromBranchId?.label;
      }
      if (filters.fromCustomerId) {
        isMatch = isMatch && row.fromCustomerId === filters.fromCustomerId;
      }
      if (filters.fromCurrType) {
        isMatch = isMatch && row.fromCurrType === filters.fromCurrType;
      }
      if (filters.transactionType) {
        isMatch =
          isMatch &&
          row.transactionType?.value === filters.transactionType?.label;
      }
      if (filters.status) {
        isMatch = isMatch && row.status?.value === filters.status?.label;
      }

      return isMatch;
    });

    let totalRate = 0;
    let totalAmount = 0;
    let totalConvertedAmount = 0;
    const extractAmount = (value) => {
      const match = value.match(/[-+]?\d*\.?\d+/);
      return match ? parseFloat(match[0]) : 0;
    };
    // filteredTransactionData.forEach((row) => {
    //  totalRate += parseFloat(row.rate || 0);
    // totalAmount += extractAmount(row.fromCurrValue || 0);
    //totalConvertedAmount += extractAmount(row.toCurrValue || 0);
    //});

    if (filteredTransactionData.length === 0) {
      doc.text("No data to display for the applied filters.", 14, filterY);
    } else {
      autoTable(doc, {
        head: [filteredColumns.map((col) => col.Header)],
        body: [
          ...filteredTransactionData.map((row) =>
            filteredColumns.map((col) => {
              if (col.accessor === "transactionType") {
                return row.transactionType?.label || "N/A";
              }
              if (col.accessor === "currencyType") {
                const { currencyType, transactionType } = row;
                return `${transactionType?.label || "N/A"} - ${
                  currencyType || "N/A"
                }`;
              }
              if (col.accessor === "status") {
                return row.status?.label || "N/A";
              }
              if (col.accessor === "pickup") {
                return formatDateToUserTimeZone(row.pickup) || "Not Mentioned";
              }
              if (col.accessor === "movingAverage") {
                return row.movingAverage !== undefined
                  ? row.movingAverage.toString()
                  : "0";
              }

              return row[col.accessor] || "N/A";
            })
          ),
          filteredColumns.map((col) => {
            if (col.accessor === "rate")
              return `Total: ${totalRate.toFixed(2)}`;
            if (col.accessor === "fromCurrValue")
              return `Total: ${totalAmount.toFixed(2)}`;
            if (col.accessor === "toCurrValue")
              return `Total: ${totalConvertedAmount.toFixed(2)}`;
            return "";
          }),
        ],
        startY: filterY,
        theme: "grid",
        headStyles: {
          fillColor: [255, 255, 255],
          textColor: [10, 10, 10],
        },
        styles: {
          lineColor: [0, 0, 0],
          lineWidth: 0.1,
        },
        didDrawCell: (data) => {
          const { rowIndex, columnIndex, section } = data;

          if (
            section === "body" &&
            rowIndex === filteredTransactionData.length
          ) {
            doc.setLineWidth(0);
          }
        },
      });
    }
    doc.save("Transaction_Report.pdf");
  };

  const downloadExcel = () => {
    const filteredData = transactionData.map((row) => ({
      "Transaction Id": row.transactionId,
      Name: row.customerName,
      " Currency": row.fromCurrType,
      " Branch": row.branchName,
      Amount: row.fromCurrValue,
      Rate: row.rate,
      "Converted Amount": row.toCurrValue,
      "Booked Date": row.transactionDate,
      "Transaction Type": row.transactionType?.label,
      " Status": row.status?.label,
    }));

    const ws = XLSX.utils.json_to_sheet(filteredData);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "Transactions");
    XLSX.writeFile(wb, "Transaction_Report.xlsx");
  };

  const handlePrint = () => {
    const printButton = document.querySelector(".print-button");
    if (printButton) {
      printButton.style.display = "none";
    }
    window.print();
    setTimeout(() => {
      if (printButton) {
        printButton.style.display = "block";
      }
    }, 0);
  };

  const downloadMenu = [
    { label: "Excel", onClick: downloadExcel, icon: excel(), value: 1 },
    { label: "PDF", onClick: generatePDF, icon: pdf(), value: 2 },
    { label: "Print", onClick: handlePrint, icon: print(), value: 3 },
  ];

  const columns = useMemo(
    () => [
      {
        Header: "ID",
        accessor: "transactionId",
        filterable: false,
        disableFilters: true,
        Cell: (cellProps) => {
          return cellProps.value ? cellProps.value : "";
        },
      },
      {
        Header: "Name",
        accessor: "customerName",
        filterable: false,
        disableFilters: true,
        Cell: (cellProps) => {
          return cellProps.value ? cellProps.value : "On the Counter";
        },
      },
      {
        Header: "Currency",
        accessor: "currencyType",
        disableFilters: true,
        filterable: false,
        Cell: (cellProps) => {
          const { currencyType, transactionType } = cellProps.row.original;
          return (
            <div>
              <Badge
                className={
                  "font-size-11 me-1 badge-soft-" + transactionType?.color
                }
              >
                {transactionType?.label}
              </Badge>
              <span>{currencyType}</span>
            </div>
          );
        },
      },
      {
        Header: "Branch",
        accessor: "branchName",
        disableFilters: true,
        filterable: false,
        Cell: (cellProps) => {
          return cellProps.value ? cellProps.value : "";
        },
      },
      {
        Header: "Amount",
        accessor: "fromCurrValue",
        disableFilters: true,
        filterable: false,
        Cell: (cellProps) => {
          return cellProps.value ? cellProps.value : "";
        },
      },
      {
        Header: "Rate",
        accessor: "rate",
        disableFilters: true,
        filterable: false,
        Cell: (cellProps) => {
          return cellProps.value ? cellProps.value : "";
        },
      },
      {
        Header: "Converted Amount",
        accessor: "toCurrValue",
        disableFilters: true,
        filterable: false,
        Cell: (cellProps) => {
          return cellProps.value ? cellProps.value : "";
        },
      },
      {
        Header: "Booked Date",
        accessor: "transactionDate",
        disableFilters: true,
        filterable: false,
        Cell: (cellProps) => {
          return cellProps.value ? cellProps.value : "";
        },
      },
      // {
      //   Header: "Pickup",
      //   accessor: "pickup",
      //   disableFilters: true,
      //   filterable: false,
      //   Cell: (cellProps) => {
      //     return cellProps.value ? cellProps.value : "Not Mentioned";
      //   },
      // },
      {
        Header: "Moving Average",
        accessor: "movingAverage",
        disableFilters: true,
        filterable: false,
        Cell: (cellProps) => {
          return cellProps.value ? cellProps.value : "0";
        },
      },
      {
        Header: "Status",
        accessor: "status",
        disableFilters: true,
        filterable: false,
        show: false,
        Cell: (cellProps) => {
          return (
            <Badge
              className={
                "font-size-11 badge-soft-" + cellProps.row.original.status.color
              }
            >
              {cellProps.row.original.status.label}
            </Badge>
          );
        },
      },
      {
        Header: "Actions",
        disableFilters: true,
        accessor: "view",
        Cell: (cellProps) => {
          const { status } = cellProps.row.original;
          return (
            <Button
              type="button"
              color={
                status === TransactionStatus.CashCollected
                  ? "primary"
                  : "success"
              }
              className="btn-sm btn-rounded"
              onClick={(e) => {
                setEditMode(true);
                ViewTransactionDetails(cellProps.row.original);
              }}
              style={{
                marginRight: "5px",
                marginBottom: "5px",
              }}
              aria-label="view"
            >
              {status === TransactionStatus.CashCollected ? view() : edit()}
            </Button>
          );
        },
      },
    ],
    []
  );

  useEffect(() => {
    getAllCompany();
  }, []);

  return (
    <React.Fragment>
      <ToastContainer closeButton={false} limit={1} />
      <ViewComment
        isOpen={modal1}
        commentData={commentData}
        toggle={() => setModal1(false)}
      />

      <ViewLatestTransactionModel
        isOpen={modal1}
        editMode={editMode}
        toggle={toggleViewModal}
        sendTransactionDetails={sendTransactionDetails}
        commentData={commentData.map((comment) => ({
          ...comment,
          userType: comment.userType,
          status: comment.status,
          createdOn: comment.createdOn,
        }))}
        sendOpTimeTransactionDetails={sendOpTimeTransactionDetails}
        sendBranchDetails={sendBranchDetails}
      />

      <div className="page-content">
        <Container fluid>
          <Card>
            <CardBody>
              <div className="d-flex justify-content-between align-items-center">
                <div className="d-flex mb-1">
                  <div className="mb-4 h4 card-title">Transaction Report</div>
                  {HelpDocButton(helpDocUrl.transactionReport)}
                </div>
                <div>
                  <Dropdown isOpen={dropdownOpen} toggle={toggleDropdown}>
                    <DropdownToggle className="bg-primary text-white print-button">
                      {download()}
                    </DropdownToggle>
                    <DropdownMenu>
                      {downloadMenu.map((e) => (
                        <DropdownItem
                          key={e.value}
                          onClick={e.onClick}
                          className="d-flex gap-1 align-items-center"
                        >
                          {e.icon}
                          <span>{e.label}</span>
                        </DropdownItem>
                      ))}
                    </DropdownMenu>
                  </Dropdown>
                </div>
              </div>
              <div>
                <Button
                  type="button"
                  onClick={() => setModalFilter(true)}
                  color="primary"
                  data-toggle="modal"
                  data-target="#myModal"
                >
                  Add Filters
                </Button>
                {isFilterApplied && (
                  <Button
                    type="button"
                    onClick={clearFilters}
                    color="danger"
                    className="ms-2"
                    style={{ marginLeft: "10px" }}
                  >
                    Clear Filters
                  </Button>
                )}
              </div>

              <Modal
                size="md"
                isOpen={modalFilter}
                toggle={() => setModalFilter(!modalFilter)}
              >
                <div className="modal-header">
                  <h5 className="modal-title mt-0">Select Filters</h5>
                  <button
                    onClick={() => setModalFilter(false)}
                    type="button"
                    className="close"
                    aria-label="Close"
                  >
                    <span aria-hidden="true">&times;</span>
                  </button>
                </div>
                <div className="modal-body">
                  <Row>
                    <Col lg="12">
                      <div className="mb-2">
                        <Col lg="12">
                          <Label for="filterBy">Filter By</Label>
                        </Col>
                        <ReactSelect
                          options={columnList.map((column) => ({
                            value: column.value,
                            label: column.label,
                          }))}
                          value={selectedOptions}
                          isMulti
                          styles={SelectStyle}
                          onChange={handleFilterChange}
                          placeholder="Select Filter"
                        />
                      </div>
                    </Col>

                    {selectedFilterTypes.includes("Transaction Date") && (
                      <>
                        <Col lg="12">
                          <div className="mb-3">
                            <Label for="filterValue">Select From Date</Label>
                            <Input
                              type="date"
                              className="form-control"
                              id="filterValue"
                              onChange={(e) => {
                                setFilters((prevState) => ({
                                  ...prevState,
                                  fromDate: e.target.value,
                                }));
                              }}
                              styles={SelectStyle}
                              value={filters.fromDate}
                            />
                          </div>
                        </Col>
                        <Col lg="12">
                          <div className="mb-3">
                            <Label for="toDate">Select To Date</Label>
                            <Input
                              type="date"
                              className="form-control"
                              id="toDate"
                              onChange={(e) => {
                                setFilters((prevState) => ({
                                  ...prevState,
                                  toDate: e.target.value,
                                }));
                              }}
                              value={filters.toDate}
                            />
                          </div>
                        </Col>
                      </>
                    )}
                    {selectedFilterTypes.includes("Branch") && (
                      <Col lg="12">
                        <div className="mb-3">
                          <Label for="filterValue">Select Branch</Label>
                          <ReactSelect
                            options={branches}
                            value={
                              branches.find(
                                (b) => b.value === filters.fromBranchId
                              ) || null
                            }
                            onChange={(selectedOption) => {
                              setFilters((prevState) => ({
                                ...prevState,
                                // fromBranchId: selected || [],
                                fromBranchId: selectedOption
                                  ? selectedOption.value
                                  : null,
                              }));
                            }}
                            styles={SelectStyle}
                            placeholder="Select Branch..."
                            isClearable
                          />
                        </div>
                      </Col>
                    )}

                    {selectedFilterTypes.includes("Customer") && (
                      <Col lg="12">
                        <div className="mb-3">
                          <Label for="filterValue">Enter Customer Name</Label>
                          <ReactSelect
                            placeholder="Enter at least 3 Letters to get Customer Name..."
                            id="fromCustomerId"
                            options={customerList.map((customer) => ({
                              value: customer.customerId,
                              label: customer.customerName,
                            }))}
                            onChange={(selectedOption) => {
                              setFilters((prev) => ({
                                ...prev,

                                fromCustomerId: selectedOption
                                  ? selectedOption.value
                                  : null,
                              }));
                            }}
                            styles={SelectStyle}
                            onInputChange={(inputValue, { action }) => {
                              if (
                                action === "input-change" &&
                                inputValue.length >= 3
                              ) {
                                delayGetCustomer(inputValue);
                              }
                            }}
                            menuPortalTarget={document.getElementById(
                              "react-select-portal"
                            )}
                            menuPlacement="auto"
                            isClearable
                          />
                        </div>
                      </Col>
                    )}
                    {selectedFilterTypes.includes("Currency") && (
                      <Col lg="12">
                        <div className="mb-3">
                          <Label for="filterValue">Select Currency</Label>
                          <ReactSelect
                            options={currency}
                            value={
                              currency.find(
                                (b) => b.label === filters.fromCurrType
                              ) || null
                            }
                            onChange={(selected) => {
                              setFilters((prevState) => ({
                                ...prevState,
                                fromCurrType: selected ? selected.label : null,
                              }));
                            }}
                            getOptionLabel={(option) => (
                              <div
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                }}
                              >
                                {displayFlag(option.currencyFlag)}
                                {option.label}
                              </div>
                            )}
                            getOptionValue={(option) => option.value}
                            styles={SelectStyle}
                            placeholder="Select Currency..."
                          />
                        </div>
                      </Col>
                    )}
                    {selectedFilterTypes.includes("Company") && (
                      <Col lg="12">
                        <div className="mb-3">
                          <Label for="filterValue">Select Company</Label>
                          <ReactSelect
                            options={companyOptions.map((company) => ({
                              value: company.value,
                              label: company.label,
                            }))}
                            value={
                              companyOptions.find(
                                (b) => b.value === filters.companyId
                              ) || null
                            }
                            onChange={(selected) => {
                              setFilters((prevState) => ({
                                ...prevState,
                                companyId: selected ? selected.value : null,
                              }));
                              if (selected) {
                                getAgentsByCompany(selected.value);
                              } else {
                                setState((prevState) => ({
                                  ...prevState,
                                  agentOptions: [],
                                }));
                              }
                            }}
                            styles={SelectStyle}
                            placeholder="Select Company..."
                          />
                        </div>
                      </Col>
                    )}
                    {filters.companyId && (
                      <Col md={12}>
                        <FormGroup>
                          <Label htmlFor="agentId">Select Agent</Label>
                          <ReactSelect
                            id="agentId"
                            aria-label="agentId"
                            placeholder="Select Agent..."
                            styles={SelectStyle}
                            value={state.agentOptions?.find(
                              (agent) => agent.value === filters.agentId
                            )}
                            onChange={(selected) => {
                              setFilters((prevState) => ({
                                ...prevState,
                                agentId: selected ? selected.value : "",
                              }));
                            }}
                            options={state.agentOptions}
                          />
                        </FormGroup>
                      </Col>
                    )}
                    {selectedFilterTypes.includes("Transaction Type") && (
                      <Col lg="12">
                        <div className="mb-3">
                          <Label for="filterValue">
                            Select Transaction Type
                          </Label>
                          <ReactSelect
                            options={transactionOptions}
                            value={
                              transactionOptions.find(
                                (b) => b.value === filters.transactionType
                              ) || null
                            }
                            onChange={(selected) => {
                              setFilters((prevState) => ({
                                ...prevState,
                                transactionType: selected
                                  ? selected.value
                                  : null,
                              }));
                            }}
                            styles={SelectStyle}
                            placeholder="Select Transaction Type..."
                          />
                        </div>
                      </Col>
                    )}
                    {selectedFilterTypes.includes("Status") && (
                      <Col lg="12">
                        <div className="mb-3">
                          <Label for="filterValue">Select Status</Label>
                          <ReactSelect
                            options={statusOptions}
                            value={
                              statusOptions.find(
                                (b) => b.value === filters.status
                              ) || null
                            }
                            onChange={(selected) => {
                              setFilters((prevState) => ({
                                ...prevState,
                                status: selected ? selected.value : null,
                              }));
                            }}
                            styles={SelectStyle}
                            placeholder="Select Status..."
                          />
                        </div>
                      </Col>
                    )}
                  </Row>
                </div>
                <div className="modal-footer">
                  <Button
                    type="button"
                    color="primary"
                    className="btn btn-primary"
                    onClick={handleSubmit}
                  >
                    Apply Filters
                  </Button>
                </div>
              </Modal>
              <div style={{ marginTop: "20px" }}>
                {isLoading ? (
                  <Loader />
                ) : (
                  <TableContainer
                    columns={columns}
                    data={transactionData}
                    isPageSelect={false}
                    customId={"transactionDate"}
                    desc={true}
                    customPageSize={pageSize}
                    pageSize={pageSize}
                    pageIndex={currentPage}
                    onPageChange={onPageChange}
                    refresh={true}
                    onRefreshClick={() => fetchTransactionHistoryData(filters)}
                  />
                )}
              </div>
            </CardBody>
          </Card>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default TransactionReport;
