import React, { useEffect, useState } from "react";
import { Modal, Button } from "react-bootstrap";
import jsPDF from "jspdf";
import "jspdf-autotable";
import logo from "../../../images/logo-dark.png";
import { FaDownload } from "react-icons/fa";
import { getApiData } from "../../helpers/axiosHelper";
import { toast } from "react-toastify";
import Footer from "../../components/SuperAdminLayout/Footer";
import { mapTransactionType } from "../../common/data/StatusLabels";
import { formatDateToUserTimeZone } from "../../common/data/date";
import { rateToFixed } from "../../common/data/commonfunctions";
import Loader from "../../components/Common/Loader";
import { TransactionType } from "../../common/data/transactionType";

const ReceiptModal = ({ show, handleClose, transactionID }) => {
  const [receiptData, setReceiptData] = useState({});
  const [isLoading, setIsLoading] = useState(false);

  const loadLogoAsBase64 = (url) => {
    return new Promise((resolve, reject) => {
      const img = new Image();
      img.crossOrigin = "Anonymous";
      img.src = url;
      img.onload = () => {
        const canvas = document.createElement("canvas");
        canvas.width = img.width;
        canvas.height = img.height;
        const ctx = canvas.getContext("2d");
        ctx.drawImage(img, 0, 0);
        resolve(canvas.toDataURL("image/png"));
      };
      img.onerror = (err) => reject(err);
    });
  };

  const getTransaction = async () => {
    setIsLoading(true);
    try {
      const response = await getApiData(
        `api/Transaction/GetTransaction?TransactionID=${transactionID}`
      );
      const mappedResponse = response?.data?.map((item) => ({
        transactionId: item.transactionId,
        fromCustomerId: item.fromCustomerId,
        agentId: item.agentId,
        customerName: item.customerName,
        agentName: item.agentName,
        branchName: item.branchName,
        fromBranchId: item.fromBranchId,
        transactionDate: item.transactionDate,
        fromCurrType: item.fromCurrType,
        fromCurrValue: item.fromCurrValue,
        toCurrType: item.toCurrType,
        toCurrValue: item.toCurrValue,
        transactionType: mapTransactionType(item.transactionType),
        discountedRate: item.discountedRate,
        exchangeRate: item.exchangeRate,
      }));
      setReceiptData(mappedResponse);
      setReceiptData(response);
    } catch (error) {
      toast.error(error.message, {
        position: "top-right",
        autoClose: 3000,
      });
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (transactionID) {
      getTransaction();
    }
  }, [transactionID]);

  const generatePDF = async () => {
    const {
      transactionId,
      transactionDate,
      customerName,
      fromCurrType,
      fromCurrValue,
      transactionType,
      toCurrType,
      toCurrValue,
      agentBranch,
      discountedRate,
      branchName,
      exchangeRate,
    } = receiptData;
    const toCurrency = toCurrType;

    const doc = new jsPDF();
    const pageWidth = doc.internal.pageSize.getWidth();
    const margin = 3;

    const logoWidth = 35;
    const logoHeight = 5;
    const logoX = (pageWidth - logoWidth) / 2; // Center horizontally
    const logoY = margin + 5; // Adjust y position from top
    try {
      const logoBase64 = await loadLogoAsBase64(logo); // Load the logo as base64
      doc.addImage(logoBase64, "PNG", logoX, logoY, logoWidth, logoHeight);
    } catch (error) {
      console.error("Error loading logo:", error);
    }

    const titleFontSize = 15;
    doc.setFontSize(titleFontSize);
    doc.setFont("helvetica", "bold");
    const titleY = logoY + logoHeight + 10; // Position below the logo
    doc.text("Transaction Receipt", pageWidth / 2, titleY, { align: "center" });

    const detailsMarginBottom = 5; // Space between title and details
    const leftDetailsY = titleY + 10 + detailsMarginBottom; // Position details below the title
    const rightDetailsY = leftDetailsY;

    doc.setFontSize(11);
    doc.setFont("helvetica", "normal");
    doc.text(`Transaction ID: ${transactionId}`, 20, leftDetailsY);
    doc.text(
      `Transaction Date: ${formatDateToUserTimeZone(transactionDate)}`,
      20,
      leftDetailsY + 10
    );
    doc.text(
      `Branch Name: ${branchName || "N/A"}`,
      pageWidth - 20,
      rightDetailsY,
      { align: "right" }
    );
    doc.text(
      `Customer Name: ${
        receiptData?.fromCustomerId
          ? receiptData.customerName
          : receiptData?.agentId
          ? receiptData.agentName
          : "Counter Customer"
      }`,
      pageWidth - 20,
      rightDetailsY + 10,
      { align: "right" }
    );

    doc.setFontSize(12);
    doc.setFont("helvetica", "bold");
    doc.text("Currency Exchange", pageWidth / 2, 60, { align: "center" }); // Centered title before the table
    doc.autoTable({
      startY: 65,
      head: [
        [
          "Type",
          "Currency",
          "F/C Amount",
          "Rate",
          `${toCurrency} Amount`,
          //  "Payout Currency",
        ],
      ],
      body: [
        [
          //transactionType,
          `${receiptData?.agentId ? "Agent" : "Customer"} - ${
            mapTransactionType(transactionType)?.label
          }`,
          fromCurrType,
          fromCurrValue,
          exchangeRate,
          toCurrValue,
          // `SGD : ${toCurrValue}` || "N/A",
        ],
      ],
      theme: "grid",
      headStyles: {
        fillColor: [255, 255, 255],
        textColor: [10, 10, 10],
      },
      styles: { lineColor: [0, 0, 0], lineWidth: 0.1 },
      margin: { horizontal: 20 },
      // styles: { fontSize: 10 },
    });
    const signatureStartY = doc.lastAutoTable.finalY + 15;
    doc.setFontSize(11);
    doc.setFont("helvetica", "normal");
    doc.text("Admin Signature", 30, signatureStartY);
    doc.text(
      "Receiver Signature",
      doc.internal.pageSize.getWidth() - 60,
      signatureStartY
    );
    if (receiptData?.sign?.sign) {
      const signatureImage = `data:image/png;base64,${receiptData.sign.sign}`;
      const imageX = pageWidth - 55;
      const imageY = signatureStartY + 3;
      const imageWidth = 30;
      const imageHeight = 10;
      doc.addImage(
        signatureImage,
        "PNG",
        imageX,
        imageY,
        imageWidth,
        imageHeight
      );
    }

    doc.setFont("helvetica", "normal");
    doc.setFontSize(10);
    doc.text(
      "Thank you for using Crescent Exchange",
      doc.internal.pageSize.getWidth() / 2,
      doc.lastAutoTable.finalY + 40,
      { align: "center" }
    );
    doc.text(
      `${new Date().getFullYear()} © Crescent Exchange`,
      doc.internal.pageSize.getWidth() / 2,
      doc.lastAutoTable.finalY + 45,
      { align: "center" }
    );
    doc.text(
      "11 Collyer Quay, The Arcade #03-07B",
      doc.internal.pageSize.getWidth() / 2,
      doc.lastAutoTable.finalY + 50,
      { align: "center" }
    );
    doc.text(
      "Singapore 049317 Tel: +65 8590 0090 | +65 9055 5575",
      doc.internal.pageSize.getWidth() / 2,
      doc.lastAutoTable.finalY + 55,
      { align: "center" }
    );
    doc.save("receipt.pdf");
  };

  const handlePrint = () => {
    const modalContent = document.getElementById("modal-print-content");

    // Clone the modal content
    const printWindow = window.open("", "", "width=800,height=600");
    printWindow.document.write(`
        <html>
            <head>
                <style>
                    body {
                        font-family: Arial, sans-serif;
                        padding: 20px;
                    }
                    .table {
                        width: 100%;
                        border-collapse: collapse;
                    }
                    .table th, .table td {
                        border: 1px solid #ddd;
                        padding: 8px;
                    }
                    .text-center {
                        text-align: center;
                    }
                    .d-flex {
                        display: flex;
                    }
                    .justify-content-between {
                        justify-content: space-between;
                    }
                </style>
            </head>
            <body>
                ${modalContent.innerHTML}
            </body>
        </html>
    `);

    // Wait for the content to load and then print
    printWindow.document.close();
    printWindow.focus();

    printWindow.onload = function () {
      printWindow.print();
      printWindow.close();
    };
  };

  const handleImageClick = (imageSrc) => {
    const imageUrl = `data:image/png;base64,${imageSrc}`;
    const newTab = window.open("", "_blank");
    newTab.document.write(
      `<img src="${imageUrl}" alt="Signature" style="display:block; margin:auto; width:100%; max-width:800px;"/>`
    );
    newTab.document.title = "Signature";
  };

  return (
    <>
      {isLoading ? (
        <Loader />
      ) : (
        <Modal show={show} onHide={handleClose} centered size="lg">
          <Modal.Header closeButton>
            <Modal.Title className="text-center w-100"></Modal.Title>
          </Modal.Header>
          <Modal.Body id="modal-print-content">
            <div className="text-center mb-4">
              <img
                src={logo}
                alt="Logo"
                className="img-fluid"
                style={{ maxHeight: "60px", maxWidth: "200px" }}
              />
            </div>
            <h3 className="text-center">Transaction Receipt</h3>
            <br />
            <br />
            <div className="d-flex justify-content-between flex-wrap">
              <div>
                <p>
                  <strong>Transaction ID : </strong>
                  {receiptData?.transactionId || "N/A"}
                </p>
                <p>
                  <strong>Transaction Date : </strong>
                  {formatDateToUserTimeZone(receiptData?.transactionDate) ||
                    "N/A"}
                </p>
              </div>
              <div>
                <p>
                  <strong>Branch Name : </strong>
                  {receiptData?.branchName || "N/A"}
                </p>

                <p>
                  <strong>Customer Name : </strong>
                  {receiptData?.fromCustomerId
                    ? receiptData.customerName
                    : receiptData?.agentId
                    ? receiptData.agentName
                    : "Counter Customer"}
                </p>
              </div>
            </div>
            <hr />
            <h4 className="text-center">Currency Exchanged</h4>
            <div className="table-responsive">
              <table className="table table-bordered mt-3">
                <thead>
                  <tr>
                    <th style={{ backgroundColor: "#f8f9fa" }}>Type</th>
                    <th style={{ backgroundColor: "#f8f9fa" }}>Currency</th>
                    <th style={{ backgroundColor: "#f8f9fa" }}>F/C Amount</th>
                    <th style={{ backgroundColor: "#f8f9fa" }}>Rate</th>
                    <th style={{ backgroundColor: "#f8f9fa" }}>
                      {receiptData?.toCurrType} Amount
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>
                      {receiptData?.transactionType === TransactionType.Buy
                        ? "Sell"
                        : receiptData?.transactionType === TransactionType.Sell
                        ? "Buy"
                        : "Transfer"}
                    </td>

                    <td>{receiptData?.fromCurrType}</td>

                    <td>{receiptData?.fromCurrValue}</td>
                    <td>{receiptData?.exchangeRate}</td>
                    <td>{receiptData?.toCurrValue}</td>
                  </tr>
                </tbody>
              </table>
            </div>
            <hr />
            <div
              className="mt-3 d-flex justify-content-between"
              style={{ marginTop: "50px" }}
            >
              <div>Admin Signature</div>
              <div>
                Receiver Signature
                {receiptData?.sign && (
                  <div className="d-flex justify-content-center align-items-center">
                    <img
                      src={`data:image/png;base64,${receiptData?.sign?.sign}`}
                      alt="Signature"
                      style={{
                        width: "150px",
                        height: "50px",
                        objectFit: "contain",
                        cursor: "pointer",
                      }}
                      onClick={() => handleImageClick(receiptData?.sign?.sign)}
                    />
                  </div>
                )}
              </div>
            </div>
            <br />
            <p className="text-center mt-5">
              Thank you for using Crescent Exchange
              {/* </p> */}
              <br />
              {/* <p className="text-center"> */}
              {new Date().getFullYear()} © Crescent Exchange
              <br />
              11 Collyer Quay, The Arcade #03-07B <br />
              Singapore 049317 Tel: +65 8590 0090 | +65 9055 5575
            </p>
            {/* <div className="mt-5"><Footer /></div> */}
          </Modal.Body>
          <Modal.Footer>
            <Button variant="primary" onClick={generatePDF}>
              <FaDownload /> PDF
            </Button>
            <Button variant="primary" onClick={handlePrint}>
              Print
            </Button>
          </Modal.Footer>
        </Modal>
      )}
    </>
  );
};

export default ReceiptModal;
