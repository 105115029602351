import React, { useEffect, useState } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import {
  Button,
  Card,
  CardBody,
  Container,
  Form,
  Row,
  Col,
  FormGroup,
  Input,
  Label,
  FormFeedback,
} from "reactstrap";
import { ToastContainer, toast } from "react-toastify";
import { getApiData, putApiData } from "../../helpers/axiosHelper";
import RequiredAsterisk from "../../components/Common/RequiredAsterisk";
import Loader from "../../components/Common/Loader";
import {
  mobileNumberValidator,
  toastError,
} from "../../common/data/commonfunctions";

const Profile = (props) => {
  document.title = "Profile | Crescent Exchange";
  const [loading, setLoading] = useState(true);
  const [profileData, setProfileData] = useState({});
  const [submitting, setSubmitting] = useState(false);

  const profileValidation = useFormik({
    enableReinitialize: true,

    initialValues: {
      firstName: profileData?.firstName || "",
      middleName: profileData?.middleName || "",
      lastName: profileData?.lastName || "",
      email: profileData?.email || "",
      mobile: profileData?.mobile || "",
      // userType: profileData.userType || "",
      role: profileData?.role || "",
      branch: profileData?.branch || "",
    },

    validationSchema: Yup.object({
      firstName: Yup.string()
        .matches(/^[A-Za-z]+$/, "First Name should contain only letters")
        .required("First Name is required"),
      middleName: Yup.string().matches(
        /^[A-Za-z]+$/,
        "Middle Name should contain only letters"
      ),
      lastName: Yup.string()
        .matches(/^[A-Za-z]+$/, "Last Name should contain only letters")
        .required("Last Name is required"),
      email: Yup.string()
        .email("Invalid email address")
        .required("Email is required"),
      mobile: Yup.string()
        .required("Please Enter Mobile Number")
        .matches(/^[0-9]*$/, "Please enter only numbers")
        .matches(
          new RegExp(
            `^\\+?\\d{${mobileNumberValidator.min},${mobileNumberValidator.max}}$`
          ),
          `Invalid Mobile Number`
        ),
      // userType: Yup.string().required("User Type is required"),
      // role: Yup.string().required("Role is required"),
      // branch: Yup.string().required("Branch is required"),
    }),

    onSubmit: async (values) => {
      delete values.role;
      delete values.branch;

      if (values.mobile === profileValidation.initialValues.mobile) {
        delete values.mobile;
      }
      if (values.email === profileValidation.initialValues.email) {
        delete values.email;
      }

      const hasChanges = Object.keys(values).some(
        (key) => values[key] !== profileValidation.initialValues[key]
      );
      if (hasChanges) {
        try {
          setSubmitting(true);
          const response = await putApiData(
            "api/Profile/UpdateSystemUserProfile",
            values
          );
          if (response?.success) {
            toast.success("Profile Updated Successfully", {
              position: "top-right",
              autoClose: 2000,
              closeButton: false,
            });
            fetchData();
          } else {
            toastError(response?.message);
          }
        } catch (error) {
          toast.error("Error Updating Details!", {
            position: "top-right",
            autoClose: 3000,
            closeButton: false,
          });
        } finally {
          setSubmitting(false);
        }
      } else if (!hasChanges) {
        toast.error("No Changes to Update", {
          position: "top-right",
          autoClose: 3000,
          closeButton: false,
        });
      }
    },
  });

  const fetchData = async () => {
    try {
      setLoading(true);
      const response = await getApiData(
        "api/Profile/GetSystemUserProfileByUid"
      );
      setProfileData(response);
      setLoading(false);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <div className="page-content">
      <Container fluid>
        <ToastContainer closeButton={false} limit={1} />
        {loading ? (
          <Loader />
        ) : (
          <>
            <div className="page-title-box ">
              <h4 className="mb-sm-0 font-size-18">Profile</h4>
            </div>
            <Card>
              <CardBody>
                <Form
                  className="needs-validation"
                  onSubmit={(e) => {
                    e.preventDefault();
                    profileValidation.handleSubmit();
                    return false;
                  }}
                >
                  <Row>
                    <Col md="6">
                      <FormGroup className="mb-3">
                        <Label htmlFor="firstName">First Name</Label>
                        <RequiredAsterisk />
                        <Input
                          type="text"
                          name="firstName"
                          id="firstName"
                          onChange={profileValidation.handleChange}
                          onBlur={profileValidation.handleBlur}
                          value={profileValidation.values.firstName || ""}
                          invalid={
                            profileValidation.touched.firstName &&
                            profileValidation.errors.firstName
                          }
                        />
                        {profileValidation.touched.firstName &&
                          profileValidation.errors.firstName && (
                            <FormFeedback type="invalid">
                              {profileValidation.errors.firstName}
                            </FormFeedback>
                          )}
                      </FormGroup>
                    </Col>
                    <Col md="6">
                      <FormGroup className="mb-3">
                        <Label htmlFor="middleName">Middle Name</Label>
                        <Input
                          type="text"
                          name="middleName"
                          id="middleName"
                          onChange={profileValidation.handleChange}
                          onBlur={profileValidation.handleBlur}
                          value={profileValidation.values.middleName || ""}
                          invalid={
                            profileValidation.touched.middleName &&
                            profileValidation.errors.middleName
                          }
                        />
                        {profileValidation.touched.middleName &&
                          profileValidation.errors.middleName && (
                            <FormFeedback type="invalid">
                              {profileValidation.errors.middleName}
                            </FormFeedback>
                          )}
                      </FormGroup>
                    </Col>
                    <Col md="6">
                      <FormGroup className="mb-3">
                        <Label htmlFor="lastName">Last Name</Label>
                        <RequiredAsterisk />
                        <Input
                          type="text"
                          name="lastName"
                          id="lastName"
                          onChange={profileValidation.handleChange}
                          onBlur={profileValidation.handleBlur}
                          value={profileValidation.values.lastName || ""}
                          invalid={
                            profileValidation.touched.lastName &&
                            profileValidation.errors.lastName
                          }
                        />
                        {profileValidation.touched.lastName &&
                          profileValidation.errors.lastName && (
                            <FormFeedback type="invalid">
                              {profileValidation.errors.lastName}
                            </FormFeedback>
                          )}
                      </FormGroup>
                    </Col>
                    <Col md="6">
                      <FormGroup className="mb-3">
                        <Label htmlFor="email">Email</Label>
                        <RequiredAsterisk />
                        <Input
                          type="email"
                          name="email"
                          id="email"
                          onChange={profileValidation.handleChange}
                          onBlur={profileValidation.handleBlur}
                          value={profileValidation.values.email || ""}
                          invalid={
                            profileValidation.touched.email &&
                            profileValidation.errors.email
                          }
                        />
                        {profileValidation.touched.email &&
                          profileValidation.errors.email && (
                            <FormFeedback type="invalid">
                              {profileValidation.errors.email}
                            </FormFeedback>
                          )}
                      </FormGroup>
                    </Col>
                    <Col md="6">
                      <FormGroup className="mb-3">
                        <Label htmlFor="mobile">Mobile</Label>
                        <RequiredAsterisk />
                        <Input
                          type="text"
                          name="mobile"
                          id="mobile"
                          onChange={profileValidation.handleChange}
                          onBlur={profileValidation.handleBlur}
                          value={profileValidation.values.mobile || ""}
                          invalid={
                            profileValidation.touched.mobile &&
                            profileValidation.errors.mobile
                          }
                        />
                        {profileValidation.touched.mobile &&
                          profileValidation.errors.mobile && (
                            <FormFeedback type="invalid">
                              {profileValidation.errors.mobile}
                            </FormFeedback>
                          )}
                      </FormGroup>
                    </Col>
                    {/* <Col md="6">
                                <FormGroup className="mb-3">
                                    <Label htmlFor="userType">User Type</Label>
                                    <RequiredAsterisk />
                                    <Input
                                        type="text"
                                        name="userType"
                                        id="userType"
                                        onChange={profileValidation.handleChange}
                                        onBlur={profileValidation.handleBlur}
                                        value={profileValidation.values.userType || ""}
                                        invalid={profileValidation.touched.userType && profileValidation.errors.userType}
                                    />
                                    {profileValidation.touched.userType && profileValidation.errors.userType && (
                                        <FormFeedback type="invalid">{profileValidation.errors.userType}</FormFeedback>
                                    )}
                                </FormGroup>
                            </Col> */}
                    <Col md="6">
                      <FormGroup className="mb-3">
                        <Label htmlFor="role">Role</Label>
                        <RequiredAsterisk />
                        <Input
                          type="text"
                          name="role"
                          id="role"
                          disabled={true}
                          onChange={profileValidation.handleChange}
                          onBlur={profileValidation.handleBlur}
                          value={profileValidation.values.role || ""}
                          invalid={
                            profileValidation.touched.role &&
                            profileValidation.errors.role
                          }
                        />
                        {profileValidation.touched.role &&
                          profileValidation.errors.role && (
                            <FormFeedback type="invalid">
                              {profileValidation.errors.role}
                            </FormFeedback>
                          )}
                      </FormGroup>
                    </Col>
                    <Col md="6">
                      <FormGroup className="mb-3">
                        <Label htmlFor="branch">Branch</Label>
                        <RequiredAsterisk />
                        <Input
                          type="text"
                          name="branch"
                          id="branch"
                          disabled={true}
                          onChange={profileValidation.handleChange}
                          onBlur={profileValidation.handleBlur}
                          value={profileValidation.values.branch || ""}
                          invalid={
                            profileValidation.touched.branch &&
                            profileValidation.errors.branch
                          }
                        />
                        {profileValidation.touched.branch &&
                          profileValidation.errors.branch && (
                            <FormFeedback type="invalid">
                              {profileValidation.errors.branch}
                            </FormFeedback>
                          )}
                      </FormGroup>
                    </Col>
                  </Row>
                  <div className="text-sm-end">
                    <Button
                      color="primary"
                      onClick={profileValidation.handleSubmit}
                      disabled={submitting}
                    >
                      Update
                    </Button>
                  </div>
                </Form>
              </CardBody>
            </Card>
          </>
        )}
      </Container>
    </div>
  );
};

export default Profile;
