import React from "react";
import PropTypes from "prop-types";
import { Modal, Badge, ModalBody, ModalHeader } from "reactstrap";
import { mapStatus } from "../../common/data/StatusLabels";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUser } from "@fortawesome/free-solid-svg-icons";

const ViewComment = (props) => {
  const { isOpen, toggle, commentData } = props;

  const UserTypes = {
    1: "System User",
    2: "You",
    3: "Agent",
  };

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const day = String(date.getDate()).padStart(2, "0");
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const year = date.getFullYear();
    return `${day}-${month}-${year}`;
  };

  return (
    <Modal isOpen={isOpen} toggle={toggle} centered={true}>
      <ModalHeader toggle={toggle}>Transaction Comment</ModalHeader>
      <ModalBody style={{ maxHeight: "440px" }}>
        <div>
          <div
            className="mt-1"
            style={{ maxHeight: "325px", overflowY: "auto" }}
          >
            {commentData.length > 0 ? (
              commentData.map((comment, index) => (
                <div key={index}>
                  <div>
                    <div className="d-flex justify-content-between align-items-center mb-2">
                      <div className="d-flex align-items-center">
                        <FontAwesomeIcon icon={faUser} className="me-2" />
                        <span>{UserTypes[comment.userType]}</span>
                      </div>
                      <span className="me-2" style={{ fontSize: "0.6rem" }}>
                        {formatDate(comment.createdOn)}
                      </span>
                    </div>
                    <p>
                      Status:{" "}
                      <Badge
                        className={
                          "font-size-11 badge-soft-" +
                          mapStatus(comment.transactionStatus).color
                        }
                      >
                        {mapStatus(comment.transactionStatus).label}
                      </Badge>
                    </p>
                  </div>
                  <div>
                    <h6>
                      Comment: <b>{comment.comments}</b>
                    </h6>
                  </div>
                  <hr />
                </div>
              ))
            ) : (
              <div
                className="text-muted d-flex justify-content-center align-items-center"
                style={{ minHeight: "100px" }}
              >
                <h3>No comments to show</h3>
              </div>
            )}
          </div>
        </div>
      </ModalBody>
    </Modal>
  );
};

ViewComment.propTypes = {
  isOpen: PropTypes.bool,
  toggle: PropTypes.func,
  commentData: PropTypes.array,
};

export default ViewComment;
