import React, { useEffect, useState } from "react";
import { Card, CardBody, CardTitle } from "reactstrap";
import TableContainer from "../../components/Common/TableContainer";
import Loader from "../../components/Common/Loader";

const LiveFxRates = ({ rates, avg }) => {
  const [liveRateState, setLiveRateState] = useState({
    rateMaster: [],
    isLoading: true,
  });

  useEffect(() => {
    if (rates.length > 0) {
      setLiveRateState({ rateMaster: rates, isLoading: false });
    }
  }, [rates]);

  const columns = [
    {
      Header: "Currency",
      accessor: "currency",
      filterable: false,
      disableFilters: true,
    },
    {
      Header: "Bid",
      accessor: "bid",
      filterable: false,
      disableFilters: true,
      Cell: ({ value, row }) => (
        <div
          style={{
            color: `${
              parseFloat(value) > avg ? "rgb(5, 196, 62)" : "rgb(250, 77, 24)"
            }`,
          }}
        >
          {value}
          <span className="arrow">{parseFloat(value) > avg ? " ▲" : " ▼"}</span>
        </div>
      ),
    },
    {
      Header: "Ask",
      accessor: "ask",
      filterable: false,
      disableFilters: true,
    },
    {
      Header: "High",
      accessor: "high",
      filterable: false,
      disableFilters: true,
    },
    {
      Header: "Low",
      accessor: "low",
      filterable: false,
      disableFilters: true,
    },
    {
      Header: "Open",
      accessor: "open",
      filterable: false,
      disableFilters: true,
    },
    {
      Header: "Close",
      accessor: "close",
      filterable: false,
      disableFilters: true,
    },
    {
      Header: "Time",
      accessor: "timestamp",
      filterable: false,
      disableFilters: true,
      Cell: ({ value }) => {
        const date = new Date(value);
        return value ? date.toLocaleString() : "";
      },
    },
  ];

  return (
    <React.Fragment>
      {liveRateState.isLoading ? (
        <Loader />
      ) : (
        <Card>
          <CardBody>
            <CardTitle>Live Rates</CardTitle>
            <TableContainer
              columns={columns}
              data={liveRateState.rateMaster}
              isGlobalFilter={true}
              isAddOptions={false}
              customPageSize={10}
              isPageSelect={false}
            />
          </CardBody>
        </Card>
      )}
    </React.Fragment>
  );
};

export default LiveFxRates;
