import React, { useState, useEffect } from "react";
import {
  Button,
  Row,
  Col,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  FormGroup,
  Label,
  Input,
  FormFeedback,
} from "reactstrap";
import Loader from "../../components/Common/Loader";
import { getApiData, putApiData } from "../../helpers/axiosHelper";
import * as Yup from "yup";
import { ToastContainer, toast } from "react-toastify";
import { useFormik } from "formik";
import RequiredAsterisk from "../../components/Common/RequiredAsterisk";
import ConfirmationSwal from "../../components/Common/ConfirmationSwal";
import { mobileNumberValidator } from "../../common/data/commonfunctions";

const ViewDetailsToggle = ({ isOpen, toggle, companyId, getAllCompany }) => {
  const [companyData, setCompanyData] = useState({});
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (isOpen && companyId) {
      fetchCompanyData();
    }
  }, [isOpen, companyId]);

  const fetchCompanyData = async () => {
    try {
      setLoading(true);
      const response = await getApiData(
        `api/AgentUser/GetCompany?companyId=${companyId}`
      );
      setCompanyData(response?.data);
    } catch (error) {
      console.error("Error fetching company data:", error);
    } finally {
      setLoading(false);
    }
  };

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      active: companyData?.active ?? true,
      companyCode: companyData?.companyCode || "",
      companyName: companyData?.companyName || "",
      street: companyData?.street || "",
      city: companyData?.city || "",
      state: companyData?.state || "",
      postalCode: companyData?.postalCode || "",
      country: companyData?.country || "",
      companyRegistration: companyData?.companyRegistration || "",
      email: companyData?.email || "",
      mobile: companyData?.mobile || "",
    },
    validationSchema: Yup.object({
      companyName: Yup.string()
        .matches(/^[A-Za-z\s]+$/, "Please enter only letters ")
        .required("Please Enter Company Name"),
      street: Yup.string().required("Please Enter Street"),
      city: Yup.string()
        .matches(/^[A-Za-z\s]+$/, "City should contain only letters")
        .required("Please Enter City"),
      state: Yup.string()
        .matches(/^[A-Za-z\s]+$/, "State should contain only letters")
        .required("Please Enter State"),
      country: Yup.string()
        .matches(/^[A-Za-z\s]+$/, "Country should contain only letters")
        .required("Please Enter Country"),
      postalCode: Yup.string()
        .matches(/^[0-9]*$/, "Please enter only numbers")
        .required("Please Enter PostalCode"),
      companyRegistration: Yup.string()
        .matches(/^[0-9]*$/, "Please enter only numbers")
        .required("Please Enter Company Registration Number"),
      email: Yup.string()
        .email("Invalid Email Address")
        .required("Please Enter Company Email"),
      mobile: Yup.string()
        .matches(/^[0-9]*$/, "Please enter only numbers")
        .matches(
          new RegExp(
            `^\\+?\\d{${mobileNumberValidator.min},${mobileNumberValidator.max}}$`
          ),
          `Invalid Mobile Number`
        )
        .required("Please Enter Company Mobile Number"),
    }),
    onSubmit: async (values) => {
      const result = await ConfirmationSwal("Are you sure?", "Yes", "Cancel");

      if (result.isConfirmed) {
        try {
          setLoading(true);
          const combinedValues = {
            ...values,
            companyCode: companyData?.companyCode,
            companyId: companyData.companyId,
          };
          if (combinedValues.email === formik.initialValues.email) {
            delete values.email;
          }
          if (combinedValues.mobile === formik.initialValues.mobile) {
            delete values.mobile;
          }
          const updatedResponse = await putApiData(
            `api/AgentUser/UpdateCompany`,
            combinedValues
          );
          if (updatedResponse?.success === true) {
            if (
              JSON.stringify(formik.values) ===
              JSON.stringify(formik.initialValues)
            ) {
              toast.info("No changes to save", {
                position: "top-right",
                autoClose: 3000,
              });
            } else {
              toast.success("Updated Successfully", {
                position: "top-right",
                autoClose: 3000,
              });
              toggle();
              getAllCompany();
            }
          } else {
            toast.error(updatedResponse?.message || "Update Failed", {
              position: "top-right",
              autoClose: 2000,
            });
          }
        } catch (error) {
          console.error("Error updating company data:", error);
        } finally {
          setLoading(false);
        }
      }
    },
  });

  return (
    <Modal
      isOpen={isOpen}
      toggle={toggle}
      autoFocus={true}
      centered={true}
      className="exampleModal"
      tabIndex="-1"
    >
      <ModalHeader toggle={toggle}>Company Details</ModalHeader>
      <ModalBody>
        {loading ? (
          <Loader />
        ) : (
          <form onSubmit={formik.handleSubmit}>
            <Row>
              <Col md={6}>
                <FormGroup>
                  <Label for="companyName"> Name</Label>
                  <RequiredAsterisk />
                  <Input
                    type="text"
                    name="companyName"
                    id="companyName"
                    placeholder="Enter company name"
                    value={formik.values.companyName}
                    onChange={formik.handleChange}
                    invalid={
                      formik.touched.companyName && formik.errors.companyName
                    }
                  />
                  {formik.touched.companyName && formik.errors.companyName && (
                    <FormFeedback>{formik.errors.companyName}</FormFeedback>
                  )}
                </FormGroup>
              </Col>
              {/* <Col md={6}>
                <FormGroup>
                  <Label for="companyAddress"> Address</Label>
                  <Input
                    type="text"
                    name="companyAddress"
                    id="companyAddress"
                    placeholder="Enter company address"
                    value={formik.values.companyAddress}
                    onChange={formik.handleChange}
                    invalid={
                      formik.touched.companyAddress &&
                      formik.errors.companyAddress
                    }
                  />
                  {formik.touched.companyAddress &&
                    formik.errors.companyAddress && (
                      <FormFeedback>
                        {formik.errors.companyAddress}
                      </FormFeedback>
                    )}
                </FormGroup>
              </Col> */}

              <Col md={6}>
                <FormGroup>
                  <Label for="email">Email</Label>
                  <RequiredAsterisk />
                  <Input
                    type="email"
                    name="email"
                    id="email"
                    placeholder="Enter company email"
                    value={formik.values.email}
                    onChange={formik.handleChange}
                    invalid={formik.touched.email && formik.errors.email}
                  />
                  {formik.touched.email && formik.errors.email && (
                    <FormFeedback>{formik.errors.email}</FormFeedback>
                  )}
                </FormGroup>
              </Col>
              <Col md={6}>
                <FormGroup>
                  <Label for="companyRegistration">Registration</Label>
                  <RequiredAsterisk />

                  <Input
                    type="text"
                    name="companyRegistration"
                    id="companyRegistration"
                    placeholder="Enter company registration"
                    value={formik.values.companyRegistration}
                    onChange={formik.handleChange}
                    invalid={
                      formik.touched.companyRegistration &&
                      formik.errors.companyRegistration
                    }
                  />
                  {formik.touched.companyRegistration &&
                    formik.errors.companyRegistration && (
                      <FormFeedback>
                        {formik.errors.companyRegistration}
                      </FormFeedback>
                    )}
                </FormGroup>
              </Col>
              <Col md={6}>
                <FormGroup>
                  <Label for="mobile">Mobile</Label>
                  <RequiredAsterisk />
                  <Input
                    type="text"
                    name="mobile"
                    id="mobile"
                    placeholder="Enter Company Mobile Number"
                    value={formik.values.mobile}
                    onChange={formik.handleChange}
                    invalid={formik.touched.mobile && formik.errors.mobile}
                  />
                  {formik.touched.mobile && formik.errors.mobile && (
                    <FormFeedback>{formik.errors.mobile}</FormFeedback>
                  )}
                </FormGroup>
              </Col>
              <Col md={6}>
                <FormGroup>
                  <Label for="street">Street</Label>
                  <RequiredAsterisk />
                  <Input
                    type="text"
                    name="street"
                    id="street"
                    placeholder="Enter company street"
                    value={formik.values.street}
                    onChange={formik.handleChange}
                    invalid={formik.touched.street && formik.errors.street}
                  />
                  {formik.touched.street && formik.errors.street && (
                    <FormFeedback>{formik.errors.street}</FormFeedback>
                  )}
                </FormGroup>
              </Col>
              <Col md={6}>
                <FormGroup>
                  <Label for="city">City</Label>
                  <RequiredAsterisk />
                  <Input
                    type="text"
                    name="city"
                    id="city"
                    placeholder="Enter company city"
                    value={formik.values.city}
                    onChange={formik.handleChange}
                    invalid={formik.touched.city && formik.errors.city}
                  />
                  {formik.touched.city && formik.errors.city && (
                    <FormFeedback>{formik.errors.city}</FormFeedback>
                  )}
                </FormGroup>
              </Col>
              <Col md={6}>
                <FormGroup>
                  <Label for="state">State</Label>
                  <RequiredAsterisk />
                  <Input
                    type="text"
                    name="state"
                    id="state"
                    placeholder="Enter company state"
                    value={formik.values.state}
                    onChange={formik.handleChange}
                    invalid={formik.touched.state && formik.errors.state}
                  />
                  {formik.touched.state && formik.errors.state && (
                    <FormFeedback>{formik.errors.state}</FormFeedback>
                  )}
                </FormGroup>
              </Col>
              <Col md={6}>
                <FormGroup>
                  <Label for="postalCode">Postal Code</Label>
                  <RequiredAsterisk />
                  <Input
                    type="text"
                    name="postalCode"
                    id="postalCode"
                    placeholder="Enter company PostalCode"
                    value={formik.values.postalCode}
                    onChange={formik.handleChange}
                    invalid={
                      formik.touched.postalCode && formik.errors.postalCode
                    }
                  />
                  {formik.touched.postalCode && formik.errors.postalCode && (
                    <FormFeedback>{formik.errors.postalCode}</FormFeedback>
                  )}
                </FormGroup>
              </Col>
              <Col md={6}>
                <FormGroup>
                  <Label for="country">Country</Label>
                  <RequiredAsterisk />
                  <Input
                    type="text"
                    name="country"
                    id="country"
                    placeholder="Enter country"
                    value={formik.values.country}
                    onChange={formik.handleChange}
                    invalid={formik.touched.country && formik.errors.country}
                  />
                  {formik.touched.country && formik.errors.country && (
                    <FormFeedback>{formik.errors.country}</FormFeedback>
                  )}
                </FormGroup>
              </Col>
            </Row>
          </form>
        )}
      </ModalBody>
      <ModalFooter>
        <Button color="primary" type="submit" onClick={formik.handleSubmit}>
          Update
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export default ViewDetailsToggle;
