import {
  faArrowLeft,
  faArrowRight,
  faCheck,
  faCopy,
  faDownload,
  faEye,
  faFileExcel,
  faFilePdf,
  faPenToSquare,
  faPlus,
  faTrash,
  faUserCheck,
  faX,
  faPrint,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export const activate = () => {
  return <FontAwesomeIcon icon={faCheck} />;
};
export const deactivate = () => {
  return <FontAwesomeIcon icon={faX} />;
};
export const edit = () => {
  return <FontAwesomeIcon icon={faPenToSquare} />;
};
export const approve = () => {
  return <FontAwesomeIcon icon={faUserCheck} />;
};
export const trash = () => {
  return <FontAwesomeIcon icon={faTrash} />;
};
export const copy = () => {
  return <FontAwesomeIcon icon={faCopy} />;
};
export const download = () => {
  return <FontAwesomeIcon icon={faDownload} />;
};
export const view = () => {
  return <FontAwesomeIcon icon={faEye} />;
};
export const plus = () => {
  return <FontAwesomeIcon icon={faPlus} />;
};
export const left = () => {
  return <FontAwesomeIcon icon={faArrowLeft} className="fa-lg" />;
};
export const right = () => {
  return <FontAwesomeIcon icon={faArrowRight} className="fa-lg" />;
};
export const pdf = () => {
  return <FontAwesomeIcon icon={faFilePdf} color="red" />;
};
export const excel = () => {
  return <FontAwesomeIcon icon={faFileExcel} color="green" />;
};
export const print = () => {
  return <FontAwesomeIcon icon={faPrint} color="green" />;
};
