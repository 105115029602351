import React, { useState } from "react";
import { Button } from "reactstrap";
import jsPDF from "jspdf";
import autoTable from "jspdf-autotable";
import * as XLSX from "xlsx";
import {
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";
import { toast } from "react-toastify";
import { FaFileExcel, FaFilePdf, FaDownload, FaPrint } from "react-icons/fa";
import logo from "../../../images/logo-dark.png";
import { mapStatus } from "../../common/data/StatusLabels";
import { download, excel, pdf, print } from "./../../common/data/icons";
import { formatDateToUserTimeZone } from "../../common/data/date";

const DownloadReport = ({
  data,
  columns,
  reportTitle,
  branchName,
  showStatus,
  filters,
  currencyName,
  fromDate,
  toDate,
  branchId,
  includeCurrency = false,
}) => {
  const [dropdownOpen, setDropdownOpen] = useState(false);

  const toggleDropdown = () => {
    setDropdownOpen(!dropdownOpen);
  };

  const downloadPDF = () => {
    if (!data || data.length === 0) {
      alert("No data available to download.");
      return;
    }
    const doc = new jsPDF();
    const pageWidth = doc.internal.pageSize.getWidth();
    const pageHeight = doc.internal.pageSize.getHeight();
    const margin = 10;
    const bottomMargin = 0;

    // Draw page border
    doc.rect(margin, margin, pageWidth - 2 * margin, pageHeight - 2 * margin);

    // Add logo at the center
    const logoWidth = 35;
    const logoHeight = 5;
    const logoX = (pageWidth - logoWidth) / 2; // Center horizontally
    const logoY = margin + 5; // Adjust y position from top
    doc.addImage(logo, "PNG", logoX, logoY, logoWidth, logoHeight);

    // Add report title at the top center
    const titleFontSize = 15;
    doc.setFontSize(titleFontSize);
    doc.setFont("helvetica", "bold");
    const titleX = pageWidth / 2; // Center alignment
    const titleY = logoY + logoHeight + 10; // Position below the logo
    doc.text(reportTitle, titleX, titleY, { align: "center" });

    // If fromDate and toDate are provided, format and display them
    if (includeCurrency && currencyName && fromDate && toDate) {
      const formattedFromDate = fromDate
        ? fromDate.split("-").reverse().join("-")
        : "N/A";
      const formattedToDate = toDate
        ? toDate.split("-").reverse().join("-")
        : "N/A";

      // Add selected currency and date range
      const currencyText = `Currency: ${currencyName || "N/A"}`;
      const dateRangeText = `From Date: ${formattedFromDate}   To Date: ${formattedToDate}`;
      //   const fromDateRangeText = `From Date: ${formattedFromDate}`;
      //   const toDateRangeText = ` To Date: ${formattedToDate}`;

      doc.setFontSize(10);
      doc.text(currencyText, margin + 5, margin + 35);
      doc.text(dateRangeText, margin + 5, margin + 43);
      // doc.text(fromDateRangeText, margin + 5, margin + 40);
      //doc.text(toDateRangeText, margin + 4, margin + 45);
    } else if (fromDate && toDate) {
      const formattedFromDate = fromDate
        ? fromDate.split("-").reverse().join("-")
        : "N/A";
      const formattedToDate = toDate
        ? toDate.split("-").reverse().join("-")
        : "N/A";

      // Add date range only
      // const dateRangeText = `From Date: ${formattedFromDate}   To Date: ${formattedToDate}`;
      const fromDateRangeText = `From Date: ${formattedFromDate}`;
      const toDateRangeText = `To Date: ${formattedToDate}`;

      doc.setFontSize(10);
      doc.text(fromDateRangeText, margin + 5, margin + 35);
      doc.text(toDateRangeText, margin + 5, margin + 40);
      // doc.text(dateRangeText, margin + 5, margin + 35);
    }

    // Add branch name if available
    let branchNameY = titleY + 10; // Default position for branch name
    if (branchName) {
      doc.setFontSize(10);
      const branchNameText = `Branch: ${branchName}`;
      const branchNameX = margin + 5;
      doc.text(branchNameText, branchNameX, branchNameY, { align: "left" });
    }

    // Add 5px bottom margin for the branch name
    branchNameY += 0;

    // Prepare columns and data for the table
    const tableColumns = columns
      .filter(
        (col) => col.includeInPDF && (col.accessor !== "status" || showStatus)
      )
      .map((col) => col.Header);
    const tableData = data.map((row) =>
      columns
        .filter(
          (col) => col.includeInPDF && (col.accessor !== "status" || showStatus)
        )
        .map((col) => {
          if (col.accessor === "status") {
            return row[col.accessor].label; // Extract status label
          } else if (col.accessor === "transactionType") {
            return row[col.accessor].label;
          }
          if (col.accessor === "pickup") {
            return formatDateToUserTimeZone(row.pickup) || "Not Mentioned";
          }
          if (col.accessor === "movingAverage") {
            return row.movingAverage !== undefined
              ? row.movingAverage.toString()
              : "0";
          }
          return row[col.accessor] || "N/A";
        })
    );
    // Adjust table start position
    const tableStartY = branchName ? branchNameY + 10 : titleY + 20; // Position below the branch name or title

    autoTable(doc, {
      startY: fromDate && toDate ? margin + 55 : margin + 35,
      margin: {
        top: tableStartY + 10,
        left: 15,
        right: 15,
        bottom: bottomMargin,
      },
      head: [tableColumns],
      body: tableData,
      theme: "grid",
      headStyles: {
        fillColor: [255, 255, 255],
        textColor: [10, 10, 10],
      },
      styles: { lineColor: [0, 0, 0], lineWidth: 0.1 },
    });

    // Save the PDF
    const fileName = branchName
      ? `${reportTitle} - ${branchName}.pdf`
      : `${reportTitle}.pdf`;
    doc.save(fileName);
  };

  const downloadExcel = () => {
    const ws = XLSX.utils.json_to_sheet(data);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, reportTitle);
    XLSX.writeFile(wb, `${reportTitle}.xlsx`);
  };

  const handlePrint = () => {
    const printButton = document.querySelector(".print-button");
    if (printButton) {
      printButton.style.display = "none"; // Hide the print button
    }
    window.print(); // Opens the browser's print dialog
    setTimeout(() => {
      if (printButton) {
        printButton.style.display = "block"; // Show the print button after printing
      }
    }, 0);
  };

  const downloadMenu = [
    { label: "Excel", onClick: downloadExcel, icon: excel(), value: 1 },
    { label: "PDF", onClick: downloadPDF, icon: pdf(), value: 2 },
    { label: "Print", onClick: handlePrint, icon: print(), value: 3 },
  ];

  return (
    <div className="d-flex justify-content-end">
      <Dropdown isOpen={dropdownOpen} toggle={toggleDropdown}>
        <DropdownToggle className="bg-primary text-white print-button">
          {download()}
        </DropdownToggle>
        <DropdownMenu>
          {downloadMenu.map((e) => (
            <DropdownItem
              key={e.value}
              onClick={e.onClick}
              className="d-flex gap-1 align-items-center"
            >
              {e.icon}
              <span>{e.label}</span>
            </DropdownItem>
          ))}
        </DropdownMenu>
      </Dropdown>
    </div>
  );
};

export default DownloadReport;
