import React, { useEffect, useState } from "react";
import withRouter from "../Common/withRouter";
import { withTranslation } from "react-i18next";
import SelectStyle from "../../common/data/SelectStyle";
import { useLatestTransactions } from "../../common/data/latest-transaction-context";
import ReactSelect from "react-select";
import { useAuthUser } from "../../common/data/commonfunctions";

const SelectBranch = () => {
  const { setBranchId, branches } = useLatestTransactions();
  const [selectedBranch, setSelectedBranch] = useState(
    sessionStorage.getItem("selectedBranchId")
      ? branches.find(
          (branch) =>
            branch.value === sessionStorage.getItem("selectedBranchId")
        )
      : null
  );
  const { isSystemAdmin } = useAuthUser();

  const handleBranchChange = (selectedOption) => {
    setSelectedBranch(selectedOption);
    setBranchId(
      selectedOption && selectedOption.value ? selectedOption.value : ""
    );
  };

  useEffect(() => {
    if (isSystemAdmin && branches.length > 0) {
      const storedBranchId = sessionStorage.getItem("selectedBranchId");
      const storedBranch = branches.find(
        (branch) => branch.value === parseInt(storedBranchId, 10)
      );

      const initialBranch = storedBranch || branches[0];
      setSelectedBranch(initialBranch);
      setBranchId(initialBranch?.value);
    }
  }, [branches, isSystemAdmin]);

  return (
    <React.Fragment>
      {isSystemAdmin ? (
        <ReactSelect
          id="branchId"
          placeholder="Select Branch"
          styles={{
            ...SelectStyle,
            control: (provided) => ({
              ...provided,
              width: "180px",
            }),
          }}
          onChange={(selectedOption) => {
            handleBranchChange(selectedOption);
          }}
          value={selectedBranch}
          options={branches.filter((x) => x.active === 1) || []}
          aria-label="context"
        />
      ) : null}
    </React.Fragment>
  );
};

export default withRouter(withTranslation()(SelectBranch));
