import PropTypes from "prop-types";
import React, { useCallback, useState, useEffect } from "react";
import { Modal, ModalBody, Button, Input, Label } from "reactstrap";

const RiskSelectionModal = ({
  show,
  onCloseClick,
  handleRiskChange,
  userState,
  onConfirmApproval,
  customerID,
}) => {
  const riskTypes = [
    { label: "Low", value: "1" },
    { label: "Normal", value: "2" },
    { label: "High", value: "3" },
  ];

  const [selectedRisk, setSelectedRisk] = useState(userState.riskId);

  useEffect(() => {
    if (show) {
      setSelectedRisk("");
    }
  }, [show]);

  const handleRisk = useCallback(
    (e) => {
      setSelectedRisk(e.target.value);
      handleRiskChange(e);
    },
    [handleRiskChange]
  );

  return (
    <Modal size="sm" isOpen={show} toggle={onCloseClick} centered={true}>
      <div className="modal-content">
        <ModalBody className="px-4 py-5">
          <button
            type="button"
            onClick={onCloseClick}
            className="btn-close position-absolute end-0 top-0 m-3"
          ></button>
          <h5 className="text-center mb-4">Select Risk Level</h5>

          <div className="d-flex flex-column align-items-center">
            {riskTypes.map((r) => (
              <div
                key={r.value}
                className="d-flex align-items-center justify-content-center mb-2"
                style={{ width: "300px" }} // Equal width container
              >
                <Input
                  type="radio"
                  id={r.value}
                  name="risk"
                  className="me-2"
                  value={r.value}
                  checked={selectedRisk === r.value}
                  onChange={handleRisk}
                />
                <Label
                  htmlFor={r.value}
                  className="mb-0"
                  style={{ width: "50px", textAlign: "left" }}
                >
                  {r.label}
                </Label>
              </div>
            ))}
            {userState.riskError && (
              <div className="text-danger mt-2">Please select a risk level</div>
            )}
          </div>

          <div className="text-center mt-4">
            <Button
              color="success"
              onClick={() => {
                if (selectedRisk) {
                  onConfirmApproval();
                } else {
                  handleRiskChange({ target: { value: "" } }); // Trigger validation error
                }
              }}
            >
              Approve
            </Button>
            <Button color="primary" className="ms-2" onClick={onCloseClick}>
              Cancel
            </Button>
          </div>
        </ModalBody>
      </div>
    </Modal>
  );
};

RiskSelectionModal.propTypes = {
  show: PropTypes.bool.isRequired,
  onCloseClick: PropTypes.func.isRequired,
  handleRiskChange: PropTypes.func.isRequired,
  userState: PropTypes.shape({
    riskId: PropTypes.string,
    riskError: PropTypes.bool,
  }).isRequired,
  onConfirmApproval: PropTypes.func.isRequired,
  customerID: PropTypes.string.isRequired, // New prop type
};

export default RiskSelectionModal;
