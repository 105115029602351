import React, { useEffect, useMemo, useState } from "react";
import { Badge, Card, CardBody, Col, Container, Row } from "reactstrap";
import TableContainer from "../../components/Common/TableContainer";
import { getApiData } from "../../helpers/axiosHelper";
import { useLatestTransactions } from "./../../common/data/latest-transaction-context";
import ReactSelect from "react-select";
import Loader from "../../components/Common/Loader";
import { formatDateToUserTimeZone } from "../../common/data/date";
import { useCurrentPage } from "../../common/data/CustomPagination";
import { displayFlag, getCurrencyFlag } from "../../common/data/currency";
import { helpDocUrl } from "../HelpDocs/HelpDocUrl";
import { HelpDocButton } from "../HelpDocs/HelpDocItems";
import RefreshButton from "../../common/data/RefreshButton";

const StockLog = () => {
  document.title = "Stock Log | Crescent Exchange";
  const { allCurrencies } = useLatestTransactions();
  const { branchId } = useLatestTransactions();
  const [state, setState] = useState({
    stockLogDetails: [],
    isLoading: false,
    days: 7,
  });

  const {
    saveCurrentPage,
    restoreCurrentPage,
    onPageChange,
    pageSize,
    currentPage,
  } = useCurrentPage();

  const daysOption = [
    { label: "7 Days", value: 7 },
    { label: "14 Days", value: 14 },
    { label: "30 Days", value: 30 },
    { label: "45 Days", value: 45 },
    { label: "60 Days", value: 60 },
  ];

  const getStockLogByBranch = async () => {
    try {
      setState((prevState) => ({ ...prevState, isLoading: true }));
      const response = await getApiData(
        `api/Stock/GetAccountTransaction?branchId=${branchId}&days=${state.days}`
      );
      setState((prevState) => ({ ...prevState, isLoading: false }));

      const mappedResponse = response?.data?.map((item, key) => ({
        index: key + 1,
        accountTransactionId: item.accountTransactionId,
        transactionId: item.transactionId,
        paymentType: item.paymentType,
        typeDescription: item.typeDescription,
        currencyCode: item.currencyCode,
        amount: item.amount,
        transactionDate: formatDateToUserTimeZone(item.transactionDate),
        branchId: item.branchId,
        branchName: item.branchName,
        comment: "",
        currencyFlag: getCurrencyFlag(allCurrencies, item.currencyCode),
      }));
      setState((prevState) => ({
        ...prevState,
        stockLogDetails: mappedResponse,
      }));
    } catch (error) {
      console.error(error);
    }
  };

  const columns = useMemo(() => [
    {
      Header: "Currency",
      accessor: "currencyCode",
      filterable: false,
      disableFilters: true,
      Cell: (cellProps) => {
        const { currencyFlag } = cellProps.row.original;

        return (
          <>
            {displayFlag(currencyFlag)}
            {cellProps.value ?? ""}
          </>
        );
      },
    },
    {
      Header: "Amount",
      accessor: "amount",
      filterable: false,
      disableFilters: true,
      Cell: (cellProps) => {
        return cellProps.value ? cellProps.value : "";
      },
    },
    {
      Header: "Transaction Date",
      accessor: "transactionDate",
      filterable: false,
      disableFilters: true,
      Cell: (cellProps) => {
        return cellProps.value ? cellProps.value : "";
      },
    },
    {
      Header: "Type",
      accessor: "typeDescription",
      filterable: false,
      disableFilters: true,
      Cell: (cellProps) => {
        return (
          <Badge
            className={
              "font-size-11 badge-soft-" +
              (cellProps.value === "Credit" ? "success" : "danger")
            }
          >
            {cellProps.value}
          </Badge>
        );
      },
    },
    // {
    //   Header: "Comment",
    //   accessor: "comment",
    //   filterable: false,
    //   disableFilters: true,
    //   Cell: (cellProps) => {
    //     return cellProps.value ? cellProps.value : '' ;
    //   }
    // },
  ]);

  useEffect(() => {
    if (branchId !== null) {
      getStockLogByBranch();
    }
  }, [branchId, state.days]);

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Card>
            <CardBody>
              <div className="d-flex justify-content-between align-items-center mb-4">
                <div className="d-flex mb-4">
                  <div className="h4 card-title">Stock Log</div>
                  {HelpDocButton(helpDocUrl.stockLog)}
                </div>
                <ReactSelect
                  placeholder="Select any One..."
                  id="days"
                  value={daysOption.find(
                    (option) => option.value === state.days
                  )}
                  onChange={(selectedOption) => {
                    setState((prevState) => ({
                      ...prevState,
                      days: selectedOption && selectedOption?.value,
                    }));
                  }}
                  options={daysOption}
                />
              </div>
              {state.isLoading ? (
                <Loader />
              ) : (
                <>
                  {state.stockLogDetails?.length !== 0 ? (
                    <TableContainer
                      data={state.stockLogDetails || []}
                      isGlobalFilter={true}
                      columns={columns}
                      //customPageSize={10}
                      isAddOptions={false}
                      isPageSelect={false}
                      customPageSize={pageSize}
                      pageSize={pageSize}
                      pageIndex={currentPage}
                      onPageChange={onPageChange}
                      refresh={true}
                      onRefreshClick={() => getStockLogByBranch(branchId)}
                    />
                  ) : (
                    <div className="text-sm-center fs-5">No Stocks Found</div>
                  )}
                </>
              )}
            </CardBody>
          </Card>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default StockLog;
