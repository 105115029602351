import React, { useEffect, useState, useRef } from "react";
import { Button, Container, TabPane } from "reactstrap";
import { toast } from "react-toastify";
import { getApiData, postApiData } from "../../helpers/axiosHelper";
import ReactSelect from "react-select";
import { useFormik } from "formik";
import * as Yup from "yup";
import Webcam from "react-webcam";
import ConfirmationSwal from "../../components/Common/ConfirmationSwal";
import { toastError } from "../../common/data/commonfunctions";

const LiveCapture = (props) => {
  const { customerID, editMode } = props;
  const [capturedImage, setCapturedImage] = useState(null);
  const [showCaptureWindow, setShowCaptureWindow] = useState(false);
  const [profileImage, setProfileImage] = useState(null);
  const [deviceId, setDeviceId] = useState("");
  const [devices, setDevices] = useState([]);
  const videoRef = useRef(null);
  const streamRef = useRef(null);
  const fileInputRef = useRef(null);

  const handleCapture = async () => {
    const video = videoRef.current.getScreenshot();
    setCapturedImage(video);
    const base64String = video?.split(",")[1];
    profilevalidation.setFieldValue("normalImage", base64String);
    setShowCaptureWindow(false);

    const videoStream = streamRef.current;
    if (videoStream) {
      const tracks = videoStream.getTracks();
      tracks.forEach((track) => track.stop());
    }
    profilevalidation.handleSubmit();
  };

  const handleDevices = (mediaDevices) => {
    const videoDevices = mediaDevices.filter(
      ({ kind }) => kind === "videoinput"
    );
    setDevices(videoDevices);
    if (videoDevices.length > 0) {
      setDeviceId(videoDevices[0].deviceId);
    }
  };

  const handleChange = (event) => {
    setDeviceId(event.deviceId);
  };

  const handleLiveCapture = () => {
    setShowCaptureWindow(true);
    navigator.mediaDevices
      .getUserMedia({ video: true })
      .then((stream) => {
        streamRef.current = stream;
        if (videoRef.current) {
          videoRef.current.srcObject = stream;
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const handleImageUpload = async (event) => {
    if (!event.target.files || event.target.files.length === 0) {
      return;
    }
    const file = event.target.files[0];
    const allowedExtensions = /(\.jpg|\.png|\.jpeg|\.bmp)$/i;
    if (!allowedExtensions.exec(file.name)) {
      toastError("Invalid Format");
      event.target.value = null;
      return;
    }

    const reader = new FileReader();
    reader.onload = () => {
      const base64String = reader.result.split(",")[1];
      profilevalidation.setFieldValue("normalImage", base64String);
      setCapturedImage(reader.result);
    };
    reader.readAsDataURL(file);
  };

  const handleCaptureCancel = () => {
    setShowCaptureWindow(false);

    const videoStream = streamRef.current;
    if (videoStream) {
      const tracks = videoStream.getTracks();
      tracks.forEach((track) => track.stop());
    }
  };

  const createImage = async (data) => {
    return await postApiData(
      `api/ProfileImage/CreateImage?CustomerId=${customerID}`,
      data
    );
  };

  const profilevalidation = useFormik({
    enableReinitialize: true,
    initialValues: {
      normalImage: "",
    },
    validationSchema: Yup.object({
      normalImage: Yup.mixed().required("Please select an image file"),
    }),
    onSubmit: async (values) => {
      const result = await ConfirmationSwal("Are you sure?", "Yes", "Cancel");

      if (result.isConfirmed) {
        const imageData = `data:image/png;base64,${values.normalImage}`;
        //const payload = { profileImage: values.normalImage };

        const response = await postApiData(
          `api/ProfileImage/CreateImage?CustomerId=${customerID}`,
          values
        );
        if (response?.success === true) {
          setProfileImage(imageData);
          toast.success("Profile Image Added Successfully", {
            position: "top-right",
            autoClose: 2000,
          });
        } else {
          toast.error("Failed to Add Profile Image", {
            position: "top-right",
            autoClose: 2000,
          });
        }
      }
    },
  });

  const getProfileImage = async () => {
    const imageResponse = await getApiData(
      `api/ProfileImage/GetImage?CustomerId=${customerID}`
    );
    setProfileImage(imageResponse?.data);
  };

  useEffect(() => {
    getProfileImage();
    navigator.mediaDevices.enumerateDevices().then(handleDevices);
  }, []);

  return (
    <TabPane tabId={5}>
      <div className="d-flex flex-column align-items-center">
        {capturedImage || profileImage?.normalImage ? (
          <div className="mb-3">
            <div className="d-flex justify-content-center mb-3">
              <div
                className="rounded-circle overflow-hidden"
                style={{ width: "200px", height: "200px" }}
              >
                <img
                  src={
                    capturedImage ||
                    `data:image/png;base64,${profileImage.normalImage}`
                  }
                  alt="Profile"
                  className="w-100 h-100"
                />
              </div>
            </div>
          </div>
        ) : (
          <div className="mb-3">
            <Container
              className="bg-light rounded-circle d-flex justify-content-center align-items-center"
              style={{ width: "200px", height: "200px" }}
            >
              <i className="fas fa-user-circle" style={{ fontSize: "12rem" }} />
            </Container>
          </div>
        )}
        {!editMode ? (
          <div className="mb-3">
            <Button
              color="success"
              className="me-2"
              onClick={() => handleLiveCapture()}
            >
              <i className="fa fa-camera me-2" />
              Live Capture
            </Button>
            <Button
              color="primary"
              onClick={() => fileInputRef.current.click()}
            >
              <i className="fa fa-upload me-2" />
              Upload Image
            </Button>
            <input
              type="file"
              accept="image/png, image/jpeg"
              onChange={handleImageUpload}
              ref={fileInputRef}
              className="d-none"
            />
          </div>
        ) : null}
        {showCaptureWindow && (
          <div
            className=" position-fixed top-0 start-0 w-100 h-100 d-flex justify-content-center align-items-center"
            style={{
              backgroundColor: "rgba(0, 0, 0, 0.5)",
              zIndex: 9999,
            }}
          >
            <div className=" bg-white p-3">
              <Webcam
                style={{
                  borderRadius: "8px",
                }}
                ref={videoRef}
                videoConstraints={{ deviceId: deviceId }}
              />
              {devices.length > 0 && (
                <ReactSelect
                  className="mt-2 mb-2"
                  onChange={handleChange}
                  value={
                    devices.find((device) => device.deviceId === deviceId) ||
                    null
                  }
                  options={devices}
                />
              )}
              <div>
                <Button
                  className="btn me-2"
                  color="success"
                  onClick={handleCapture}
                >
                  Capture
                </Button>
                <Button
                  className="btn btn-danger"
                  onClick={handleCaptureCancel}
                >
                  Cancel
                </Button>
              </div>
            </div>
          </div>
        )}
      </div>
      {editMode ? null : (
        <Button
          type="button"
          color="primary"
          className="btn-primary"
          onClick={profilevalidation.handleSubmit}
          disabled={profilevalidation.isSubmitting}
        >
          Update
        </Button>
      )}
    </TabPane>
  );
};

export default LiveCapture;
