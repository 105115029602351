import React, { useState } from "react";
import { Card, Container } from "reactstrap";
import Booking from "./Booking";

const AddTransaction = () => {
  const [isLoading, setIsLoading] = useState(false);

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid style={{ minHeight: "710px" }}>
          <Booking
            title="Book Transaction | Crescent Exchange"
            isLoading={isLoading}
            setIsLoading={setIsLoading}
          />
        </Container>
      </div>
    </React.Fragment>
  );
};

export default AddTransaction;
