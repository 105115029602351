import React, { useEffect, useState } from "react";
import {
  Button,
  Col,
  Form,
  FormGroup,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
} from "reactstrap";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import * as Yup from "yup";
import { useFormik } from "formik";
import { postApiData } from "../../helpers/axiosHelper";
import ReactSelect from "react-select";
import RequiredAsterisk from "../../components/Common/RequiredAsterisk";
import SelectStyle from "../../common/data/SelectStyle";
import { useLatestTransactions } from "../../common/data/latest-transaction-context";
import { displayFlag } from "../../common/data/currency";
import ButtonSpinner from "../../components/Common/ButtonSpinner";

const AddRatesToggle = (props) => {
  const { isOpen, toggle, getRates } = props;
  const [submitting, setSubmitting] = useState(false);
  const { allCurrencies } = useLatestTransactions();

  const validation = useFormik({
    enableReinitialize: true,
    initialValues: {
      currency: null,
      buying: false,
      selling: false,
      transfer: false,
    },
    validationSchema: Yup.object({
      currency: Yup.object().shape({
        label: Yup.string().required("Please Select a Currency"),
        value: Yup.string().required("Please Select a Currency"),
      }),
    }),

    onSubmit: async (values) => {
      const selectedCheckboxes = ["buying", "selling", "transfer"].filter(
        (checkbox) => values[checkbox]
      );

      if (values.currency !== null && selectedCheckboxes.length > 0) {
        setSubmitting(true);
        try {
          const responses = await Promise.all(
            selectedCheckboxes.map(async (checkbox) => {
              const rateType =
                checkbox === "buying" ? 1 : checkbox === "selling" ? 2 : 3;
              const requestBody = {
                RateType: rateType,
                CurrencyCode: values.currency.currencyCode,
                CurrencyName: values.currency.currencyName.split(" (")[0],
                Value: 0,
              };

              return postApiData(
                `api/RateMaster/InsertRateMaster`,
                JSON.stringify(requestBody)
              );
            })
          );

          const allSuccess = responses.every((response) => response?.success);
          const errorMessages = new Set();

          if (allSuccess) {
            toast.success("Rates Added Successfully", {
              position: "top-right",
              autoClose: 3000,
              closeButton: false,
            });
            toggle();
            getRates();
          } else {
            responses.forEach((response) => {
              if (!response.success) {
                errorMessages.add(response?.message);
              }
            });

            errorMessages.forEach((message) => {
              toast.error(message, {
                position: "top-right",
                autoClose: 3000,
                closeButton: false,
              });
            });
          }
        } catch (error) {
          toast.error(error, {
            position: "top-right",
            autoClose: 3000,
            closeButton: false,
          });
        } finally {
          setSubmitting(false);
        }
      } else {
        toast.error("Please Select a Currency Type", {
          position: "top-right",
          autoClose: 3000,
          closeButton: false,
        });
        setSubmitting(false);
      }
    },
  });

  return (
    <Modal
      isOpen={isOpen}
      role="dialog"
      autoFocus={true}
      centered={true}
      className="exampleModal"
      tabIndex="-1"
      toggle={toggle}
      onClosed={() => {
        validation.resetForm();
      }}
    >
      <div className="modal-content">
        <Form
          className="add-rate"
          id="addRateForm"
          onSubmit={(e) => {
            e.preventDefault();
          }}
        >
          <ModalHeader
            toggle={() => {
              toggle();
              validation.resetForm();
            }}
          >
            Add Currency
          </ModalHeader>
          <ModalBody>
            <Row>
              <Col md="12">
                <FormGroup className="mb-3">
                  <Label htmlFor="currency">Currency</Label>
                  <RequiredAsterisk />
                  <ReactSelect
                    name="currency"
                    id="currency"
                    placeholder="Select Currency..."
                    options={allCurrencies}
                    onChange={(selectedOption) => {
                      validation.setFieldValue("currency", selectedOption);
                    }}
                    getOptionLabel={(option) => (
                      <div style={{ display: "flex", alignItems: "center" }}>
                        {displayFlag(option.currencyFlag)}
                        {option.label}
                      </div>
                    )}
                    getOptionValue={(option) => option.label}
                    styles={SelectStyle}
                    value={validation.values.currency}
                    invalid={
                      validation.touched.currency && validation.errors.currency
                        ? true
                        : false
                    }
                  />
                  {validation.values.currency === null &&
                    validation.touched.currency &&
                    validation.errors.currency && (
                      <span className="text-danger" style={{ fontSize: "80%" }}>
                        Please Select a Currency
                      </span>
                    )}
                </FormGroup>
              </Col>
            </Row>
            <Col md="4">
              <Label>Select Currency Type</Label>
              <RequiredAsterisk />
              <FormGroup check>
                <Label check>
                  <Input
                    type="checkbox"
                    id="currencyType"
                    name="buying"
                    checked={validation.values.buying}
                    onChange={validation.handleChange}
                  />{" "}
                  Buying
                </Label>
              </FormGroup>
            </Col>
            <Col md="4">
              <FormGroup check>
                <Label check>
                  <Input
                    type="checkbox"
                    id="currencyType"
                    name="selling"
                    checked={validation.values.selling}
                    onChange={validation.handleChange}
                  />{" "}
                  Selling
                </Label>
              </FormGroup>
            </Col>
            <Col md="4">
              <FormGroup check>
                <Label check>
                  <Input
                    type="checkbox"
                    name="transfer"
                    checked={validation.values.transfer}
                    onChange={validation.handleChange}
                  />{" "}
                  Transfer
                </Label>
              </FormGroup>
            </Col>
            {validation.errors.validateCheckbox && (
              <span className="text-danger">
                {validation.errors.validateCheckbox}
              </span>
            )}
          </ModalBody>
          <ModalFooter>
            <Button
              color="success"
              type="submit"
              onClick={validation.handleSubmit}
              disabled={submitting}
            >
              {submitting ? <ButtonSpinner /> : "Submit"}
            </Button>
          </ModalFooter>
        </Form>
      </div>
    </Modal>
  );
};

export default AddRatesToggle;
