export const beneficiaryAccountType = {
  1: "Personal",
  2: "Business",
};
export const beneficiaryActive = {
  1: "Active",
  0: "Inactive",
};
export const beneficiaryStatus = {
  0: "Draft",
  1: "Incomplete",
  2: "Completed",
};
