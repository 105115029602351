import React, { useState, useEffect } from "react";
import { Card, CardBody, Container, Row, Col, CardTitle } from "reactstrap";
import TableContainer from "../../components/Common/TableContainer";
import Loader from "../../components/Common/Loader";
import { getApiData } from "../../helpers/axiosHelper";
import DownloadReport from "./DownloadReport";
import { useLatestTransactions } from "./../../common/data/latest-transaction-context";
import { useCurrentPage } from "../../common/data/CustomPagination";
import { displayFlag, getCurrencyFlag } from "./../../common/data/currency";
import { HelpDocButton } from "../HelpDocs/HelpDocItems";
import { helpDocUrl } from "../HelpDocs/HelpDocUrl";
import RefreshButton from "../../common/data/RefreshButton";

const BranchReport = () => {
  document.title = "Branch Report | Crescent Exchange";
  const [branchData, setBranchData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [branchName, setBranchName] = useState("All Branches");
  const { allCurrencies } = useLatestTransactions();
  const { branchId } = useLatestTransactions();
  const {
    saveCurrentPage,
    restoreCurrentPage,
    onPageChange,
    pageSize,
    currentPage,
  } = useCurrentPage();
  const fetchBranchData = async () => {
    setIsLoading(true);
    try {
      const response = await getApiData(
        `api/Report/GetStockBranchReport?branchId=${branchId}`
      );
      const mappedResponse = response?.data?.map((item, index) => ({
        index: index + 1,
        branchName: item.branchName,
        currencyCode: item.currencyCode,
        totalStock: item.totalStock,
        availableStock: item.availableStock,
        stockForDisposal: item.stockForDisposal,
        movingAverage: item.movingAverage,
        convertedSGDAmount: item.convertedStockAmount,
        currencyFlag: getCurrencyFlag(allCurrencies, item.currencyCode),
      }));

      setBranchName(mappedResponse[0]?.branchName || "All Branches");
      setBranchData(mappedResponse);
    } catch (error) {
      console.error("Error fetching branch data: ", error);
    } finally {
      setIsLoading(false);
    }
  };

  const columns = [
    {
      Header: "Currency Code",
      accessor: "currencyCode",
      filterable: false,
      disableFilters: true,
      includeInPDF: true,
      Cell: (cellProps) => {
        const { currencyFlag } = cellProps.row.original;

        return (
          <>
            {displayFlag(currencyFlag)}
            {cellProps.value ?? ""}
          </>
        );
      },
    },
    {
      Header: "Total Stock",
      accessor: "totalStock",
      filterable: false,
      disableFilters: true,
      includeInPDF: true,
    },
    {
      Header: "Available Stock",
      accessor: "availableStock",
      filterable: false,
      disableFilters: true,
      includeInPDF: true,
    },
    {
      Header: "Stock for Disposal",
      accessor: "stockForDisposal",
      filterable: false,
      disableFilters: true,
      includeInPDF: true,
    },
    {
      Header: "Moving Average",
      accessor: "movingAverage",
      filterable: false,
      disableFilters: true,
      includeInPDF: true,
    },
    {
      Header: "Converted SGD Amount",
      accessor: "convertedSGDAmount",
      filterable: false,
      disableFilters: true,
      includeInPDF: true,
    },
  ];

  useEffect(() => {
    if (branchId) {
      const timeoutId = setTimeout(() => {
        fetchBranchData();
      }, 1000);
      return () => clearTimeout(timeoutId);
    }
  }, [branchId]);

  return (
    <div className="page-content">
      {isLoading ? (
        <Loader />
      ) : (
        <Container fluid>
          <Card>
            <CardBody>
              <div className="d-flex justify-content-between flex-wrap">
                <div className="d-flex mb-4">
                  <CardTitle>Branch Report</CardTitle>
                  <div>{HelpDocButton(helpDocUrl.branchReport)}</div>
                </div>
                <Row className="align-items-center">
                  <Col md={4} className="text-end">
                    <DownloadReport
                      data={branchData}
                      columns={columns}
                      reportTitle={"Branch Report"}
                      branchName={branchName}
                    />
                  </Col>
                </Row>
              </div>
              <TableContainer
                columns={columns}
                data={branchData}
                isGlobalFilter={false}
                isAddOptions={false}
                // customPageSize={10}
                isPageSelect={false}
                customPageSize={pageSize}
                pageSize={pageSize}
                pageIndex={currentPage}
                onPageChange={onPageChange}
                refresh={true}
                onRefreshClick={fetchBranchData}
              />
            </CardBody>
          </Card>
        </Container>
      )}
    </div>
  );
};

export default BranchReport;
