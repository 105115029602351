import React, { useEffect, useMemo, useState } from "react";
import { Button, Badge, Card, CardBody } from "reactstrap";
import { ToastContainer, toast } from "react-toastify";
import AddSystemUser from "./AddSystemUser";
import TableContainer from "../../../../components/Common/TableContainer";
import {
  deleteApiData,
  getApiData,
  putApiData,
} from "../../../../helpers/axiosHelper";
import DeleteModal from "./../../../../components/Common/DeleteModal";
import ViewEditSystemUser from "./ViewEditSystemUser";
import Loader from "../../../../components/Common/Loader";
import { useLatestTransactions } from "../../../../common/data/latest-transaction-context";
import { approve, deactivate, edit, view } from "../../../../common/data/icons";
import ConfirmationSwal from "../../../../components/Common/ConfirmationSwal";
import RefreshButton from "../../../../common/data/RefreshButton";

const SystemUserTab = (props) => {
  document.title = "Manage System Users | Crescent Exchange";

  const [viewModal, setViewModal] = useState(false);
  const [addModal, setAddModal] = useState(false);
  const [adminData, setAdminData] = useState([]);
  const [systemUserData, setSystemUserData] = useState([]);
  const [editModeSystemUser, setEditModeSystemUser] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [branch, setBranch] = useState(null);
  const [UId, setUId] = useState(null);
  const [deactivateTitle, setDeactivateTitle] = useState(false);
  const { branchId } = useLatestTransactions();

  const toggleViewDetails = () => setViewModal(!viewModal);
  const toggleAddUser = () => setAddModal(!addModal);

  const onClickDelete = () => {
    setDeleteModal(true);
    setDeactivateTitle(true);
  };

  const fetchBranch = async () => {
    const response = await getApiData("api/BranchMaster/GetBranches");
    setBranch(mapBranchOptions(response));
  };

  const mapBranchOptions = (branches) => {
    if (!branches) return [];
    return branches?.map((branch) => ({
      label: branch.branchName,
      value: branch.branchId,
    }));
  };

  const getAdmin = async () => {
    setIsLoading(true);
    const response = await getApiData(
      `api/User/GetUserBySystemUser?branchId=${branchId}`
    );
    setIsLoading(false);

    const status = {
      1: "Active",
      2: "Deactivated",
    };

    const mappedResponse = response?.map((item, index) => ({
      index: index + 1,
      Uid: item.uid,
      SystemUid: item.sysUid,
      Name: item.name,
      Role: item.role,
      branch: item.branch,
      email: item.email,
      UserStatus: item.userStatus,
      UserType: item.userType,
      Active: item.active,
    }));

    setAdminData(mappedResponse || []);
  };

  useEffect(() => {
    fetchBranch();
  }, []);

  useEffect(() => {
    getAdmin();
  }, [branchId]);

  const viewSystemUserDetails = (cellProps) => {
    const { Uid } = cellProps;

    const getSystemUserbyId = async () => {
      const response = await getApiData(
        `api/User/GetUserBySystemUserByUid?Uid=${Uid}`
      );

      const mappedResponse = {
        Uid: response?.uid,
        SystemUid: response?.sysUid,
        Name: response?.name,
        firstName: response?.firstName,
        middleName: response?.middleName,
        lastName: response?.lastName,
        mobile: response?.mobile,
        email: response?.email,
        Role: response?.role,
        roleId: response?.roleId,
        branchId: response?.branchId,
        branch: response?.branch,
        UserStatus: response?.userStatus,
        UserType: response?.userType,
        Active: response?.active,
      };

      setSystemUserData(mappedResponse);
    };

    getSystemUserbyId();
    toggleViewDetails();
  };

  const softDeleteUser = async () => {
    const response = await deleteApiData(
      `api/User/DeactivateSystemUser?uid=${UId}`
    );
    if (response?.success === true) {
      toast.success("User Deleted Successfully", {
        position: "top-right",
        autoClose: 3000,
      });
    }
    setDeleteModal(!deleteModal);
    getAdmin();
  };

  const activateSystemUser = async (id) => {
    const result = await ConfirmationSwal("Are you sure?", "Yes", "Cancel");

    if (result.isConfirmed) {
      const response = await putApiData(
        `api/User/ActivateSystemUser?uid=${id}`
      );
      if (response?.success === true) {
        toast.success("User Activated Successfully", {
          position: "top-right",
          autoClose: 2000,
          closeButton: false,
        });
        getAdmin();
      } else {
        toast.error("Error, Contact Admin", {
          position: "top-right",
          autoClose: 2000,
          closeButton: false,
        });
      }
    }
  };

  const columns = useMemo(
    () => [
      {
        Header: "Name",
        accessor: "Name",
        filterable: false,
        disableFilters: true,
        Cell: (cellProps) => {
          return cellProps.value ? cellProps.value : "";
        },
      },
      {
        Header: "Email",
        accessor: "email",
        filterable: false,
        disableFilters: true,
        Cell: (cellProps) => {
          return cellProps.value ? cellProps.value : "";
        },
      },
      {
        Header: "Role",
        accessor: "Role",
        filterable: false,
        disableFilters: true,
        Cell: (cellProps) => {
          return cellProps.value ? cellProps.value : "";
        },
      },
      {
        Header: "Status",
        accessor: "UserStatus",
        disableFilters: true,
        filterable: false,
        Cell: (cellProps) => {
          return (
            <Badge
              className={
                "font-size-11 badge-soft-" +
                (cellProps.value === "Approved"
                  ? "success"
                  : "danger" && cellProps.value === "Verification"
                  ? "warning"
                  : "danger" && cellProps.value === "Document Pending"
                  ? "primary"
                  : "danger" && cellProps.value === "Approval Pending"
                  ? "secondary"
                  : "danger")
              }
            >
              {cellProps.value}
            </Badge>
          );
        },
      },
      {
        Header: "Actions",
        disableFilters: true,
        accessor: "actions",
        Cell: (cellProps) => {
          return (
            <>
              {cellProps.row.original.Active === 0 ? (
                <Button
                  type="button"
                  color="success"
                  title="Activate User"
                  className="btn-sm btn-rounded"
                  onClick={() => {
                    activateSystemUser(cellProps.row.original.Uid);
                  }}
                  style={{
                    marginRight: "5px",
                  }}
                >
                  {approve()}
                </Button>
              ) : (
                <>
                  <Button
                    type="button"
                    color="primary"
                    title="View User"
                    className="btn-sm btn-rounded"
                    onClick={(e) => {
                      setEditModeSystemUser(true);
                      viewSystemUserDetails(cellProps.row.original);
                    }}
                    style={{
                      marginRight: "5px",
                    }}
                    aria-label="view"
                  >
                    {view()}
                  </Button>
                  {cellProps.row.original.Active === 1 && (
                    <Button
                      type="button"
                      color="success"
                      title="Edit User"
                      className="btn-sm btn-rounded"
                      onClick={() => {
                        setEditModeSystemUser(false);
                        viewSystemUserDetails(cellProps.row.original);
                        setUId(cellProps.row.original.Uid);
                      }}
                      style={{
                        marginRight: "5px",
                      }}
                    >
                      {edit()}
                    </Button>
                  )}
                  {cellProps.row.original.Active !== 0 ? (
                    <Button
                      type="button"
                      color="danger"
                      title="Deactivate User"
                      className="btn-sm btn-rounded"
                      onClick={() => {
                        onClickDelete();
                        setUId(cellProps.row.original.Uid);
                      }}
                      style={{
                        marginRight: "5px",
                      }}
                    >
                      {deactivate()}
                    </Button>
                  ) : (
                    <Button
                      type="button"
                      color="success"
                      title="Activate User"
                      className="btn-sm btn-rounded"
                      onClick={() => {
                        activateSystemUser(cellProps.row.original.Uid);
                      }}
                      style={{
                        marginRight: "5px",
                      }}
                    >
                      {approve()}
                    </Button>
                  )}
                </>
              )}
            </>
          );
        },
      },
    ],
    []
  );

  return (
    <React.Fragment>
      <ToastContainer closeButton={false} limit={1} />
      {isLoading ? (
        <Loader />
      ) : (
        <>
          <ViewEditSystemUser
            isOpen={viewModal}
            toggle={toggleViewDetails}
            editModeSystemUser={editModeSystemUser}
            systemUserData={systemUserData}
            branch={branch}
            UId={UId}
            getAllSystemUser={getAdmin}
          />
          <AddSystemUser
            isOpen={addModal}
            toggle={toggleAddUser}
            branch={branch}
            getAllSystemUser={getAdmin}
          />
          <DeleteModal
            onCloseClick={() => setDeleteModal(false)}
            onDeleteClick={softDeleteUser}
            show={deleteModal}
            deactivateTitle={deactivateTitle}
          />

          <Card>
            <CardBody>
              <div className="text-sm-end">
                <Button
                  type="button"
                  color="primary"
                  onClick={toggleAddUser}
                  className="btn mb-2 me-2"
                >
                  <i className="mdi mdi-plus-circle-outline me-1" />
                  Create New System User
                </Button>
              </div>
              <TableContainer
                columns={columns}
                data={adminData}
                isGlobalFilter={true}
                isAddOptions={false}
                customPageSize={10}
                isPageSelect={false}
                refresh={true}
                onRefreshClick={getAdmin}
              />
            </CardBody>
          </Card>
        </>
      )}
    </React.Fragment>
  );
};

export default SystemUserTab;
