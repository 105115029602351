import React from "react";
import { TbRefresh } from "react-icons/tb";

const RefreshButton = ({ onClick }) => {
  return (
    <TbRefresh
      size={16}
      style={{ cursor: "pointer", color: "black" }}
      onClick={onClick}
      title="Refresh"
      className="ms-2"
    />
  );
};

export default RefreshButton;
